import { authAxios } from "../../../axios/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { setUserAsAuthenticated } from "./authSlice";
import { emptyShortlist } from "../../../redux/slices/shortlistSlice";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const registerUser = createAsyncThunk(
  "/users-with-role",
  async (
    { name, email, password, surname, birthday },
    { rejectedWithValue }
  ) => {
    try {
      const formattedBirthday = dayjs(birthday).format("YYYY-MM-DD");

      await authAxios.post(
        "/users-with-role",
        {
          name,
          email,
          password,
          surname,
          birthday: formattedBirthday,
        },
        config
      );
    } catch (error) {
      return rejectedWithValue(error.response.data);
    }
  }
);

export const userLogin = createAsyncThunk(
  "/login",
  async ({ email, password }, { dispatch, rejectedWithValue }) => {
    try {
      const { data } = await authAxios.post(
        "/login",
        { email, password },
        config
      );
      localStorage.setItem("userToken", data.token);
      dispatch(handleTokenExpiration(data.token));
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        return rejectedWithValue("Wrong credentials, failed to log in");
      } else if (error.response && error.response.data.message) {
        return rejectedWithValue(error.response.data.message);
      } else {
        return rejectedWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "/logout",
  async (_, { rejectWithValue, dispatch }) => {
    console.log("userLogout redux action");
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      await authAxios.post("/logout", null, config);
      localStorage.removeItem("userToken");
      dispatch(emptyShortlist());
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const handleTokenExpiration = (token) => (dispatch) => {
  try {
    const tokenExpirationTimeInSeconds = jwt_decode(token).exp;
    const tokenExpirationTimeMilliseconds = tokenExpirationTimeInSeconds * 1000;
    const currentTime = Date.now();
    const timeUntilTokenExpires = tokenExpirationTimeMilliseconds - currentTime;

    setTimeout(() => {
      dispatch(userLogout());
    }, timeUntilTokenExpires);
  } catch (error) {
    console.error("Error decoding token:", error);
    dispatch(userLogout());
  }
};

export const fetchUserInfoAndSetAuthenticated = createAsyncThunk(
  "auth/fetchUserInfoAndSetAuthenticated",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${userToken}`,
      //   },
      // };
      const decoded = jwt_decode(userToken);
      const { data } = decoded;
      dispatch(setUserAsAuthenticated(userToken));
      console.log(data);
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "auth/showUser",
  async (_, { rejectWithValue }) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const decoded = jwt_decode(userToken);
      const id = decoded.user_id;
      const response = await authAxios.get(`showUser/${id}`, config);
      console.log("USER DATA from the thunk: ", response.data);
      console.log("USER DATA, ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// export const secureRequest = () => async (dispatch, getState) => {
//     const token =
// }
