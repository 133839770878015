import * as React from "react";
import Switch, { switchClasses } from "@mui/joy/Switch";

export default function ExampleIosSwitch({ checked, onChange }) {
  const trackBackground = checked ? "#49559d" : "#c1c8d2";
  const thumbBackground = checked ? "#fff" : "white";
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      sx={(theme) => ({
        "--Switch-thumbShadow": "0 3px 7px 0 rgba(0 0 0 / 0.12)",
        "--Switch-thumbSize": "27px",
        "--Switch-thumbBackground": thumbBackground,
        "--Switch-thumbWidth": "27px",
        "--Switch-thumbPadding": "15px",
        "--Switch-trackWidth": "64px",
        "--Switch-trackHeight": "31px",
        "--Switch-trackBackground": trackBackground,
        [`& .${switchClasses.thumb}`]: {
          transition: "width 0.2s, left 0.2s",
        },
        "&:hover": {
          "--Switch-trackBackground": trackBackground,
        },
        "&:active": {
          "--Switch-thumbWidth": "32px",
        },
        "&:not(.Mui-checked)": {
          "--Switch-thumbBackground": "white",
        },
        [`&.${switchClasses.checked}`]: {
          "--Switch-trackBackground": "#49559d",
          "--Switch-thumbBackground": "#fff",
          "&:hover": {
            "--Switch-trackBackground": "#49559d",
          },
        },
      })}
    />
  );
}
