import React from "react";
import styles from "./RoundImage.module.css";

const RoundImage = ({ url, alt }) => {
  return (
    <div className={styles.smallerImageContainer}>
      <img src={url} alt={alt} />
    </div>
  );
};

export default RoundImage;
