import {
  startSearch,
  saveSearchResults,
  searchError,
  updateSearchParameters,
} from "./workspace";
import axios from "axios";

export const fetchWorkspaces = (params) => async (dispatch) => {
  dispatch(startSearch());
  try {
    console.log(params);
    const response = await axios.get(
      `http://localhost:8000/coworking/search?city=${params.location}&total_number_of_people=${params.numOfPersons}`
    );
    dispatch(saveSearchResults(response.data));
  } catch (error) {
    dispatch(searchError(error));
  }
};
