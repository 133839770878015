import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { miceAxios } from "../../axios/axios";

const initialState = {
  loading: false,
  venueTypes: [],
  error: null,
  success: false, // for monitoring the registration process.
};

export const fetchVenueTypes = createAsyncThunk(
  "venueTypes/fetchVenueTypes",
  async () => {
    const response = await miceAxios.get("/venue_categories");
    return response.data;
  }
);

const venueTypeSlice = createSlice({
  name: "venueTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVenueTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVenueTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.venueTypes = action.payload;
        state.success = true;
      })
      .addCase(fetchVenueTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default venueTypeSlice.reducer;
