import VenueGallerySection from "./sections/VenueGallerySection/VenueGallerySection";
import VenueOverviewSection from "./sections/VenueOverviewSection/VenueOverviewSection";
import PhotosAndVideosSection from "./sections/PhotosAndVideosSection/PhotosAndVideosSection";
import FloorPlanMeetingRoomGridSection from "./sections/FloorPlanMeetingRoomGridSection/FloorPlanMeetingRoomGridSection";
import GuestRoomsPhotosSection from "./sections/GuestRoomsPhotosSection/GuestRoomsPhotosSection";
import EighthSection from "./sections/EighthSection/EighthSection";
import VenuesTableSection from "./sections/VenuesTableSection/VenuesTableSection";
import Footer from "../../../../common/components/Footer/Footer";
import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import MapSection from "./sections/MapSection/MapSection";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import Hr from "../../../../common/components/Hr/Hr";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import StickyNavbar from "./components/StickyNavbar/StickyNavbar";
import VenueTitle from "./components/VenueTitle/VenueTitle";
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "../../../../routeImports";
import { useSelector } from "react-redux";
import {
  fetchDistance,
  fetchLeftVenue,
  fetchRightVenue,
  fetchTypeOfGuestRoom,
  fetchVenueById,
  fetchVenueBySlug,
  fetchVenueUnitById,
} from "../../../../redux/FinalVenues/venueSlice";
import EventForm from "../../../../pages/EventForm/EventForm";
import BackToTop from "../../../../common/components/BackToTop/BackToTop";
import { Helmet } from "react-helmet-async";
import CircularIndeterminate from "../../../../common/components/MUIComponents/MUIProgress";

export default function PreviewPage() {
  useScrollToTop();
  const { slug } = useParams();

  //console.log(slug);
  const dispatch = useDispatch();
  const { venue, typeOfGuestRoom, leftVenue, rightVenue, status, error } =
    useSelector((state) => state.venue);
  //console.log(venue);
  const [open, setOpen] = useState(false);
  const [unitId, setUnitId] = useState();
  const events = venue?.events?.map((event) => event.title);
  const mapSectionRef = useRef(null);
  const overviewSectionRef = useRef(null);
  const mediaGalleryRef = useRef(null);
  const conferenceRoomsRef = useRef(null);
  const meetingRoomsRef = useRef(null);
  const guestRoomsRef = useRef(null);

  const [filteredGuestRoomUnits, setFilteredGuestRoomUnits] = useState([]);

  const [filteredConferenceUnits, setFilteredConferenceUnits] = useState([]);

  const [filteredMeetingUnits, setFilteredMeetingRoomUnits] = useState([]);
  let matchVenues = [];
  if (leftVenue) {
    matchVenues.push(leftVenue);
  }
  if (rightVenue) {
    matchVenues.push(rightVenue);
  }
  if (venue) {
    matchVenues.push(venue);
  }
  function containsObjectWithId(obj, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }
    return false;
  }

  const filteredMatchedVenues = matchVenues.reduce((acc, current) => {
    if (!containsObjectWithId(current, acc)) {
      acc.push(current);
    }
    return acc;
  }, []);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(fetchVenueById(id));
  //   }
  // }, [id]);
  useEffect(() => {
    if (slug) {
      dispatch(fetchVenueBySlug(slug));
    }
  }, [slug]);

  useEffect(() => {
    if (venue?.id) {
      venue.guest_room?.map((guestRoom) =>
        dispatch(fetchTypeOfGuestRoom(guestRoom.pivot.room_id))
      );
      venue.units.forEach((unit) => {
        dispatch(fetchVenueUnitById(unit.id));
      });
      if (
        venue?.distance_sections &&
        venue?.distance_sections[0]?.pivot?.venue_distance_section_id
      ) {
        dispatch(
          fetchDistance(
            venue.distance_sections[0].pivot.venue_distance_section_id
          )
        );
      }
      if (venue?.compare_left_venue_id) {
        dispatch(fetchLeftVenue(venue.compare_left_venue_id));
      }
      if (venue?.compare_right_venue_id) {
        dispatch(fetchRightVenue(venue.compare_right_venue_id));
      }
      //console.log("VENUE UNITS: ", venue.units);
      let tempGuest =
        venue?.units?.filter((unit) =>
          unit.unit_types.some((type) => type.id === 6)
        ) || [];
      setFilteredGuestRoomUnits(tempGuest);

      let tempConference =
        venue?.units?.filter((unit) =>
          unit.unit_types.some((type) => type.id === 7)
        ) || [];

      setFilteredConferenceUnits(tempConference);

      let tempMeeting =
        venue?.units?.filter((unit) =>
          unit.unit_types.some((type) => type.id === 8)
        ) || [];

      setFilteredMeetingRoomUnits(tempMeeting);
    }
  }, [venue?.id]);

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (status !== "succeeded") {
    return <CircularIndeterminate />;
  }

  const handleOpen = (unitId) => {
    setOpen(!open);
    setUnitId(unitId);
  };

  //console.log(venue);
  return (
    <>
      <Helmet>
        <title>{venue.meta_title}</title>
        <meta name="description" content={venue.meta_description} />
        <meta name="keywords" content={venue.meta_keyword} />
      </Helmet>
      <Hr />
      <PageWrapper variant={"previewPage"}>
        <VenueTitle
          name={venue.name}
          address={venue.address}
          handleOpen={handleOpen}
        />
        <VenueGallerySection venue={venue} />
        <StickyNavbar
          mapSectionRef={mapSectionRef}
          overviewSectionRef={overviewSectionRef}
          mediaGalleryRef={mediaGalleryRef}
          conferenceRoomsRef={
            filteredConferenceUnits.length !== 0
              ? conferenceRoomsRef
              : undefined
          }
          guestRoomsRef={
            filteredGuestRoomUnits.length !== 0 ? guestRoomsRef : undefined
          }
          meetingRoomsRef={
            filteredMeetingUnits.length !== 0 ? meetingRoomsRef : undefined
          }
        />

        <VenueOverviewSection ref={overviewSectionRef} venue={venue} />
        <PhotosAndVideosSection
          ref={mediaGalleryRef}
          images={venue.photo_galleries}
          videos={venue.video_galleries}
        />
        {filteredConferenceUnits.length !== 0 && (
          <FloorPlanMeetingRoomGridSection
            ref={conferenceRoomsRef}
            conferenceRoomSpace={venue.total_conference_room_space}
            largestRoomSpace={venue.largest_rooms_space}
            numberOfConferenceRooms={venue.total_number_of_conference_rooms}
            units={filteredConferenceUnits}
            handleOpen={handleOpen}
          />
        )}
        {filteredMeetingUnits.length !== 0 && (
          <FloorPlanMeetingRoomGridSection
            ref={meetingRoomsRef}
            units={filteredMeetingUnits}
            title={"Meeting Rooms"}
            handleOpen={handleOpen}
          />
        )}
        {filteredGuestRoomUnits.length !== 0 && (
          <FloorPlanMeetingRoomGridSection
            ref={guestRoomsRef}
            units={filteredGuestRoomUnits}
            title={"Guest Rooms"}
            handleOpen={handleOpen}
          />
        )}
        <GuestRoomsPhotosSection
          ref={guestRoomsRef}
          totalGuestRooms={venue.total_guest_rooms}
          typeOfGuestRoom={typeOfGuestRoom}
        />

        {venue.altitude && venue.longitude && (
          <MapSection
            ref={mapSectionRef}
            name={venue.name}
            venue={venue}
            altitude={venue.altitude}
            longitude={venue.longitude}
            socialMedia={venue.social_media}
          />
        )}
        <EighthSection />
        <Hr />
        <VenuesTableSection matchVenues={filteredMatchedVenues} />
      </PageWrapper>
      <PopularLocations />
      <Footer />
      <EventForm
        open={open}
        handleOpen={handleOpen}
        venueId={venue.id}
        unitId={unitId}
        events={events}
      />
      <BackToTop />
    </>
  );
}
