import Blog from "../../../blog/common/components/BlogListView/BlogListView";
import Help from "../../../../common/components/Help/Help";
import Footer from "../../../../common/components/Footer/Footer";
import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { saveVenuesParams } from "../../../../redux/venues/updateSearchParamsVenues";
import { fetchVenues } from "../../../../redux/venues/venuesSearch";
import MainSection from "./components/MainSection/MainSection";
export default function Home() {
  useScrollToTop();
  // const locationHook = useLocation();
  // const queryParams = new URLSearchParams(locationHook.search);
  // const params = {};
  //
  // for (const [key, value] of queryParams.entries()) {
  //   params[key] = value;
  // }
  //
  // const dispatch = useDispatch();
  // const [search, setSearch] = useState(false);
  //
  // useEffect(() => {
  //   if (params) {
  //     dispatch(saveVenuesParams(params));
  //     dispatch(fetchVenues(params));
  //     setSearch(false);
  //   }
  // }, [dispatch, search]);
  return (
    <>
      <PageWrapper variant={"home"}>
        <MainSection />
        <Help />
        <Blog wid={true} />
      </PageWrapper>
      <PopularLocations />
      <Footer />
    </>
  );
}
