import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin, userLogout, getUserInfo } from "./authAction";
import jwt_decode from "jwt-decode";

const userToken = localStorage.getItem("userToken");

const initialState = {
  loading: false,
  userInfo: {},
  userToken,
  error: null,
  loginSuccess: false,
  loginError: null,
  registerError: null,
  registerSuccess: false,
  logoutSuccess: false,
  fetchedUserInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserAsAuthenticated: (state, action) => {
      state.userToken = localStorage.getItem("userToken");
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.registerError = null;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.registerSuccess = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.registerError = payload;
      })
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.loginError = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.userToken = payload.token; // Assuming payload contains the token
        state.loginSuccess = true;
        // Token expiration handling should be done in the action, not in the reducer
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.loginError = payload;
      })
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.userInfo = {};
        state.userToken = null;
        state.loading = false;
        state.logoutSuccess = true;
        state.loginSuccess = false;
        localStorage.removeItem("userToken");
      })
      .addCase(userLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fetchedUserInfo = payload; // Store the fetched user info here
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { setUserAsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
