import React from "react";
import styles from "./WhatsAppOverlay.module.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const WhatsAppOverlay = () => {
  const handleClick = (event) => {
    event.stopPropagation(); // This stops the click event from reaching the parent Link
  };
  return (
    <div className={styles.container}>
      <div className={styles.text}>Like what you see?</div>
      <a
        href="https://api.whatsapp.com/send?phone=38269000000"
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
      >
        <button className={styles.btnWA} onClick={handleClick}>
          {" "}
          <WhatsAppIcon /> WhatsApp
        </button>
      </a>
    </div>
  );
};

export default WhatsAppOverlay;
