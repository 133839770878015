import React from "react";
import styles from "./NumberOfPersonsPartContainer.module.css";
import AttendeesBedroomsRow from "./AttendeesBedroomsRow/AttendeesBedroomsRow";
import BookingTypeRow from "./BookingTypeRow/BookingTypeRow";

const NumberOfPersonsPartContainer = ({ title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {title === "Attendees & Bedrooms" && (
        <>
          <AttendeesBedroomsRow type={"Attendees"} />
          <AttendeesBedroomsRow type={"Bedrooms"} />
        </>
      )}
      {title === "Booking Type" && (
        <>
          <BookingTypeRow type={"Book Online"} />
          <BookingTypeRow type={"Get Quotes"} />
        </>
      )}
    </div>
  );
};

export default NumberOfPersonsPartContainer;
