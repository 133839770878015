import React from "react";
import { Drawer } from "@mui/material";

const MUIDrawer = ({ open, onClose, position, children }) => {
  return (
    <Drawer
      anchor={position}
      open={open}
      onClose={onClose}
      sx={{
        flexShrink: 0,
        zIndex: 1000,
        "& .MuiDrawer-paper": {
          width: {
            xs: "80vw", //  <600px
            sm: "50vw", //  ≥600px and <800px
            md: "35vw", // ≥800px
          },
          backgroundColor: "white",
          boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default MUIDrawer;
