import styles from "./Summary.module.css";
import Title from "../../../common/components/Title/Title";
import GraySubtitle from "../../../common/components/GraySubtitle/GraySubtitle";
import ShortlistCard from "../ShortlistCard/ShortlistCard";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { useSelector } from "react-redux";
const Summary = () => {
  const arr = [
    "Complete our short form",
    "Venues will then start bidding",
    "Make changes late",
  ];

  const shortlist = useSelector((state) => state.shortlist);
  return (
    <div className={styles.summaryContainer}>
      <div>
        <Title text={"Summary"} fw={"700"} />
        <GraySubtitle text={"Your Enquiry Details"} />
      </div>

      <Title text={"Shortlist"} />
      {shortlist.length !== 0 ? (
        <div className={styles.scrollableShortlist}>
          {shortlist.map((venue) => {
            return <ShortlistCard venue={venue} key={venue.id} />;
          })}
        </div>
      ) : (
        <>Your Shortlist is empty</>
      )}

      <div className={styles.freeServiceSection}>
        <Title text={"Free Service - no booking fees"} />
        <div className={styles.freeServiceSubsection}>
          <div className={styles.item}>
            {arr.map((item) => {
              return (
                <div
                  key={item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {" "}
                  <VerifiedUserOutlinedIcon
                    sx={{ color: "var(--clr-pink)" }}
                  />{" "}
                  {item}{" "}
                </div>
              );
            })}
          </div>
          <div>
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: "var(--clr-gray-280)", fontSize: "3rem" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
