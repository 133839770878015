import React from "react";
import TextField from "@mui/material/TextField";

const FormTextField = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      required,
      style,
      variant,
      type,
      multiline,
      numOfRows,
      disabled,
      onFocus,
      onBlur,
      autocomplete,
      helperText,
      error,
    },
    ref
  ) => {
    return (
      <TextField
        error={error}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
        variant={variant}
        required={required}
        sx={style}
        type={type}
        multiline={multiline}
        rows={numOfRows}
        inputRef={ref} // Use inputRef instead of ref for Material-UI TextField
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autocomplete}
        helperText={helperText}
      />
    );
  }
);

export default FormTextField;
