import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  searchResults: [],
  loading: [false],
  error: null,
  unitsSearchParams: null,
};

const unitsSlice = createSlice({
  name: "units",
  initialState: initialStateValue,
  reducers: {
    updateUnitsSearchParameters: (state, action) => {
      state.unitsSearchParams = action.payload;
    },
    startUnitsSearch: (state) => {
      state.loading = true;
      state.error = null;
    },
    saveUnitsSearchResults: (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    },
    unitsSearchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateUnitsSearchParameters,
  startUnitsSearch,
  saveUnitsSearchResults,
  unitsSearchError,
} = unitsSlice.actions;

export default unitsSlice.reducer;
