import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  GetQuote,
  useEffect,
  useDispatch,
  BlogPage,
  ListPage,
  ListPageCoworking,
  fetchBlogs,
  Login,
  PreviewPage,
  PreviewPage1,
  SingleBlogPage,
  Register,
  StayForm,
  Navbar,
} from "./routeImports";
import VenuesTable1 from "./features/search/pages/PreviewPage/sections/VenuesTableSection/VenuesTable1/VenuesTable1";
import ContactDetails from "./pages/ContactDetails/ContactDetails";
import PaymentDetails from "./pages/PaymentDetails/PaymentDetails";
import EventForm from "./pages/EventForm/EventForm";
import ProtectedRoute from "./utils/ProtectedRoute";
import { useSelector } from "react-redux";
import SimpleBackdrop from "./common/components/MUIComponents/MUIBackdrop";
import UserArea from "./features/userArea/UserArea";
import Dashboard from "./features/userArea/dashboard/Dashboard";
import UserInformation from "./features/userArea/UserInformation/UserInformation";
import UserDocuments from "./features/userArea/UserDocuments/UserDocuments";
import Shortlist from "./features/userArea/Shortlist/Shortlist";
import Inquiry from "./features/userArea/Inquiry/Inquiry";
import Bookings from "./features/userArea/Bookings/Bookings";
import { HelmetProvider } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import { fetchUserInfoAndSetAuthenticated } from "./features/authentication/redux/authAction";
import { fetchCities } from "./redux/slices/cities";
import { fetchEventTypes } from "./redux/slices/eventTypeSlice";
import { fetchAmenities } from "./redux/slices/amenitiesSlice";
import { fetchVenueTypes } from "./redux/slices/venueTypeSlice";
import { isTokenExpired } from "./features/authentication/services/authServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBlogs());
    dispatch(fetchCities());
    dispatch(fetchEventTypes());
    dispatch(fetchAmenities());
    dispatch(fetchVenueTypes());
    if (!isTokenExpired()) {
      dispatch(fetchUserInfoAndSetAuthenticated());
    }
  }, [dispatch]);

  const { userInfo, loading } = useSelector((state) => state.auth);

  if (loading) {
    return <SimpleBackdrop />;
  }
  const notify = () => toast("404");
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/venues/:slug" element={<PreviewPage />} />
            <Route path="/venues/:id/:unitId" element={<PreviewPage1 />} />
            <Route path="/search" element={<ListPage />} />
            <Route path="/coworking/list" element={<ListPageCoworking />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/stay-form" element={<StayForm />} />
            <Route path="/get-quote" element={<GetQuote />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/blog/:id" element={<SingleBlogPage />} />
            <Route path="/test" element={<VenuesTable1 />} />
            <Route path="/contact-details" element={<ContactDetails />} />
            <Route path="/payment-details" element={<PaymentDetails />} />
            <Route path="/event-form" element={<EventForm />} />
            {userInfo && (
              <Route
                path="/user-area"
                element={
                  <ProtectedRoute userInfo={userInfo}>
                    <UserArea />
                  </ProtectedRoute>
                }
              >
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="information" element={<UserInformation />} />
                <Route path="documents" element={<UserDocuments />} />
                <Route path="shortlist" element={<Shortlist />} />
                <Route path="offers" element={<Inquiry />} />
                <Route path="bookings" element={<Bookings />} />
              </Route>
            )}

            <Route
              path="*"
              element={
                <>
                  {" "}
                  <div>
                    <button onClick={notify}>Page not found!</button>
                    <ToastContainer />
                  </div>{" "}
                </>
              }
            />
          </Routes>
        </Router>
      </div>
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
