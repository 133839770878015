import styles from "./FloorPlansSection.module.css";
import Button from "../../../../../../common/components/Button/Button";
import { useState } from "react";
import img6 from "../../../../../../common/assets/designer icons/ikonice/png/ikonice Mice-06.png";

export default function FloorPlansSection(props) {
  const { floorPlan, description, handleOpen } = props;
  const [showTriangle, setShowTriangle] = useState(false);

  const handleButtonHover = () => {
    setShowTriangle(true);
  };

  const handleButtonLeave = () => {
    setShowTriangle(false);
  };

  return (
    <div className={styles.floorPlansSection}>
      <div className={styles.titleButtonContainer}>
        <a href={floorPlan} target="_blank" rel="noreferrer">
          <span>Download Floor Plans</span>
        </a>
        <Button color={"pink"} onClick={() => handleOpen()}>
          Select Unit{" "}
        </Button>
      </div>
      <div>{description}</div>
      <div className={`${styles.titleButtonContainer} ${styles.images}`}>
        <img src={floorPlan} alt="" />
        <div className={styles.createCustomDiagramContainer}>
          <img
            src={img6}
            alt=""
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonLeave}
          />

          <div
            className={`${styles.createCustomDiagramSubContainer} ${
              showTriangle ? styles.show : ""
            }`}
          >
            <div className={styles.triangle}></div>
            <Button>Create custom diagram</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
