import React, { useState } from "react";
import styles from "./Newsletter.module.css";
import Title from "../../../../../../common/components/Title/Title";
import GraySubtitle from "../../../../../../common/components/GraySubtitle/GraySubtitle";
import NewsletterForm from "../NewsletterForm/NewsletterForm";

const Newsletter = () => {
  const style = {
    color: "var(--clr-purple)",
    "& .MuiInputLabel-root.Mui-focused": {
      color: "var(--clr-purple)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--clr-purple)",
    },
  };

  const [formData, setFormData] = useState({
    gender: "",
    degree: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    position: "",
    zipCode: "",
    city: "",
    country: "",
  });

  const newsletterText =
    "Monthly you will find the best venue and hotel tips for your events and meetings, updates from the event sector,\n" +
    "          news about business travels and special offers for your holidays, directly in your mailbox. Apply for our newsletter, your Mondial Location Finder Team!";

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className={styles.newsletterContainer}>
      <Title text={"Newsletter"} fw={"400"} color={"purple"} />
      <GraySubtitle
        text={
          "Sign up for Newsletter and stay up-to-date for the latest meeting industry news"
        }
      />
      <div>{newsletterText}</div>
      <NewsletterForm
        formData={formData}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        style={style}
      />
    </div>
  );
};

export default Newsletter;
