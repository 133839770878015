import styles from "./ListItemsSection.module.css";
import ListItem from "./ListItem/ListItem";
import Title from "../../../../../../common/components/Title/Title";
import Hr from "../../../../../../common/components/Hr/Hr";

const ListItemsSection = ({ items }) => {
  const title = `${items.length} search results.`;
  return (
    <section>
      <Title color={"black"} text={title} fw={700} />
      <Hr />
      <div className={styles.listItemsContainer}>
        {items.map((item) => {
          return (
            <div key={item.id}>
              <ListItem item={item} />
              <Hr />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ListItemsSection;
