import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./leafletMap.css";

const EsriWorldGrayCanvas = () => {
  const url =
    "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";
  const attribution = "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ";
  const maxZoom = 16;

  return <TileLayer attribution={attribution} url={url} maxZoom={maxZoom} />;
};

export default function LeafletMap({ isGray, markers }) {
  const dummyMarkers = [
    {
      geocode: [48.86, 2.3522],
      popUp: "Pop up 1",
    },
    {
      geocode: [48.85, 2.34],
      popUp: "Pop up 2",
    },
    {
      geocode: [48.855, 2.36],
      popUp: "Pop up 3",
    },
  ];

  return (
    <MapContainer
      center={
        markers && markers.length > 0 ? markers[0].geocode : [48.8566, 2.3522]
      }
      zoom={9}
      scrollWheelZoom={true}
    >
      {isGray ? (
        <EsriWorldGrayCanvas />
      ) : (
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )}

      {markers
        ? markers.map((marker, index) => {
            return (
              <Marker position={marker.geocode} key={index}>
                <Popup>{marker.popUp}</Popup>
              </Marker>
            );
          })
        : dummyMarkers.map((marker, index) => {
            return (
              <Marker position={marker.geocode} key={index}>
                <Popup>{marker.popUp}</Popup>
              </Marker>
            );
          })}
    </MapContainer>
  );
}
