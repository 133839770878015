import ImageSection from "./sections/ImageSection/ImageSection";
import FloorPlansSection from "./sections/FloorPlansSection/FloorPlansSection";
import AmenitiesSection from "./sections/AmenitiesSection/AmenitiesSection";
import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import Footer from "../../../../common/components/Footer/Footer";
import TableSection from "./sections/TableSection/TableSection";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitAmenitiesById,
  fetchVenueUnitById,
} from "../../../../redux/FinalVenues/venueSlice";
import EventForm from "../../../../pages/EventForm/EventForm";
import { Helmet } from "react-helmet-async";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import CircularIndeterminate from "../../../../common/components/MUIComponents/MUIProgress";

export default function PreviewPage1() {
  const { id, unitId } = useParams();
  const dispatch = useDispatch();
  const {
    data: unit,
    status,
    error,
  } = useSelector((state) => state.venue.unit);
  console.log("UNIT: ", unit);
  const { amenitiesSections, venue } = useSelector((state) => state.venue);
  const events = venue?.events?.map((event) => event.title);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (unitId) {
      dispatch(fetchVenueUnitById(unitId));
    }
  }, [unitId]);

  useEffect(() => {
    if (unit?.amenities_sections.length > 0) {
      unit?.amenities_sections.map((section) => {
        dispatch(fetchUnitAmenitiesById(section.pivot.section_id));
      });
    }
  }, [unit?.amenities_sections]);

  useScrollToTop();
  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (status !== "succeeded") {
    return <CircularIndeterminate />;
  }

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title>{unit.meta_title}</title>
        <meta name="description" content={unit.meta_description} />
        <meta name="keywords" content={unit.meta_keyword} />
      </Helmet>
      <PageWrapper variant={"grid"}>
        <ImageSection
          name={unit.name}
          images={unit.photo_gallery}
          size={unit.size}
          ceilingHeight={unit.ceiling_height}
          dimensions={unit.dimensions}
        />
        <FloorPlansSection
          floorPlan={unit.floor_plan_image}
          description={unit.description}
          handleOpen={handleOpen}
        />
        <TableSection text="Meeting Room Capacities" data={unit.meeting_room} />
        <AmenitiesSection amenitiesSections={amenitiesSections} />
        <TableSection text="Exhibits" data={unit.exhibits} />
      </PageWrapper>
      <PopularLocations />
      <EventForm
        open={open}
        handleOpen={handleOpen}
        venueId={id}
        unitId={unitId}
        events={events}
      />
      <Footer />
    </>
  );
}
