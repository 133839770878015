import styles from "./PaymentDetails.module.css";
import { ReactComponent as CreditCard } from "../../common/assets/svg-mice/credit-card.svg";
import Hr from "../../common/components/Hr/Hr";
import InputContainer from "../ContactDetails/components/InputContainer/InputContainer";
import ExampleIosSwitch from "../../common/components/MUIComponents/MUISwitch";
import Button from "../../common/components/Button/Button";
import { ReactComponent as InfoIcon } from "../../common/assets/svg-mice/i.svg";

const PaymentDetails = () => {
  return (
    <div className={styles.paymentDetails}>
      <Hr />
      <div className={styles.title}>PAYMENT DETAILS</div>
      <div className={styles.creditCardIconContainer}>
        <CreditCard />{" "}
        <span className={styles.grayText}>Secure Payment Details</span>
      </div>
      <Hr />
      <div className={styles.inputContainer}>
        <InputContainer col={true} label={"Cardholders Name"} />
        <InputContainer col={true} label={"Card Details"} />
      </div>

      <div className={styles.creditCardIconContainer}>
        <ExampleIosSwitch /> <span>Save card to my account</span>
      </div>
      <div className={styles.creditCardIconContainer}>
        <ExampleIosSwitch />{" "}
        <span>
          I agree to the{" "}
          <span className={styles.pinkText}>Terms & Conditions</span> and{" "}
          <span className={styles.pinkText}>Privacy Policy</span>
        </span>
      </div>
      <Button>REQUEST TO BOOK</Button>
      <div className={styles.creditCardIconContainer}>
        <InfoIcon /> <span>No Charge Until Accepts</span>
      </div>
    </div>
  );
};

export default PaymentDetails;
