import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import FilledTextField from "../../../common/components/FormComponents/FilledTextField";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { Button } from "@mui/material";
import Wrapper from "../Wrapper";

const UserInformation = () => {
  const token = localStorage.getItem("userToken");
  const user = jwt_decode(token);
  console.log(user.sub);

  const [name, setName] = useState(user.name);
  const [surname, setSurname] = useState(user.surname);
  const [birthday, setBirthday] = useState(user.birthday);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper title={"Edit User Information"}>
      {" "}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <FilledTextField placeholder={"Name"} />
          </Grid>
          <Grid xs={12}>
            <FilledTextField placeholder={"Surname"} />
          </Grid>
          <Grid xs={12}>
            <FilledTextField placeholder={"Email"} />
          </Grid>
          <Grid xs={12}>
            <Button onClick={(event) => handleSubmit(event)}>Submit</Button>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default UserInformation;
