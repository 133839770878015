import React, { useEffect, useState } from "react";
import styles from "./EventForm.module.css";
import FormContainer from "../ContactDetails/components/FormContainer/FormContainer";
import InputContainer from "../ContactDetails/components/InputContainer/InputContainer";
import ControlledComponent from "../../common/components/MUIComponents/MUIDatePicker";
import MUIDateRangePicker from "../../common/components/MUIComponents/MUIDateRangePicker/MUIDateRangePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import FilledTextField from "../../common/components/FormComponents/FilledTextField";
import Button from "../../common/components/Button/Button";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useDispatch } from "../../routeImports";
import { venueInquiries } from "../../redux/FinalVenues/venueSlice";
import dayjs from "dayjs";
import { MenuItem, Select } from "@mui/material";
import { getUserInfo } from "../../features/authentication/redux/authAction";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { authAxios } from "../../axios/axios";

const EventType = () => {
  return <InputContainer label={"Event type"} col={true} />;
};

const EventDates = ({ form, arrive, depart, setForm, change }) => {
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setForm((prev) => ({ ...prev, rooms_min: newValue[0] }));
    } else {
      setForm((prev) => ({ ...prev, rooms_max: newValue[1] }));
    }
  };
  return (
    <div className={styles.eventDates}>
      <MUIDateRangePicker arrive={arrive} depart={depart} setForm={setForm} />
      <div className={styles.rooms}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Are your event dates flexible?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="are_your_event_dates_flex"
            value={form.are_your_event_dates_flex}
            onChange={change}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
      <Box sx={{ width: 300 }}>
        <div>Rooms</div>

        <Slider
          value={[form.rooms_min, form.rooms_max]}
          onChange={handleChange}
          aria-label="Custom"
          valueLabelDisplay="auto"
          min={0}
          max={320}
          marks={[
            {
              value: 0,
              label: "<1",
            },
            {
              value: 320,
              label: "320",
            },
          ]}
          sx={{
            color: "var(--clr-pink)",
            "& .MuiSlider-thumb": {
              color: "var(--clr-pink)",
            },
            "& .MuiSlider-track": {
              color: "var(--clr-pink)",
            },
            "& .MuiSlider-rail": {
              color: "rgba(var(--clr-pink), 0.38)", // Assuming you want a slightly transparent version for the rail
            },
            "& .MuiSlider-valueLabel": {
              color: "white",
            },
            "& .MuiSlider-mark": {
              backgroundColor: "var(--clr-pink)",
            },
            "& .MuiSlider-markLabel": {
              color: "var(--clr-pink)",
            },
          }}
        />
      </Box>
    </div>
  );
};

const GuestRooms = () => {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">
        Does your event need guest rooms?
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

const EventSpaceType = ({ attendees, doYouNeedMeetingRooms, handleChange }) => {
  return (
    <>
      <FormControl>
        <FormLabel id={"meeting-rooms"}>
          Does your event need meeting rooms?
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="meeting-rooms"
          name="do_you_need_meeting_rooms"
          value={doYouNeedMeetingRooms}
          onChange={handleChange}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
        <FormLabel id={"attendees-per-day"}>Attendees per Day</FormLabel>
        <FilledTextField
          value={attendees}
          name="attendees_per_day"
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};

const EventForm = ({ open, handleOpen, venueId, unitId, events, venueIDS }) => {
  //console.log(events);
  console.log("VENUE ID EVENT FORM: ", venueId);
  console.log("EVENTS: ", events);
  const [page, setPage] = useState(0);
  const [minWidth, setMinWidth] = useState("800px");
  const handleClose = () => {
    setPage(0);
    setForm(initialState);
    handleOpen();
  };
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({});
  // Fetch user info
  useEffect(() => {
    const getUser = async () => {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        const config = {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };

        const decoded = jwt_decode(userToken);
        const id = decoded.user_id;
        const response = await authAxios.get(`showUser/${id}`, config);
        // console.log("USER DATA from the thunk: ", response.data);
        // console.log("USER DATA, ", response.data);
        setUserData(response.data);
      }
    };
    getUser();
  }, []);

  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      setMinWidth(window.innerWidth <= 800 ? "100%" : "800px");
    };
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("VENUE ID BEFORE INITIAL STATE: ", venueId);
  let initialVenues = [];
  if (Array.isArray(venueIDS) && venueIDS.length) {
    // Use venueIDS if it's an array and not empty
    initialVenues = venueIDS;
  } else if (venueId) {
    // Use venueId if it's provided and not an array
    initialVenues = [venueId];
  }
  let initEvent = "";
  if (events && typeof events[0] === "object" && events[0] !== null) {
    initEvent = events[0].title;
  } else {
    if (events) initEvent = events[0];
  }
  console.log("INIT EVENT, ", initEvent);
  const initialState = {
    // venue_requested_id: Number(venueId),
    // event_type: events ? events[0] : "",
    event_type: initEvent,
    arrive_on: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
    depart_on: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
    are_your_event_dates_flex: "",
    rooms_min: 0,
    rooms_max: 5,
    do_you_need_meeting_rooms: "",
    attendees_per_day: "",
    tell_us_more: "",
    first_name: userData.name || "",
    last_name: userData.surname || "",
    gender: "",
    country: "",
    email: userData.email || "",
    phone: "",
    industry: "",
    venues: initialVenues,
  };
  // useEffect(() => {
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     venues: [venueId ? venueId : prevForm.venues[0]],
  //   }));
  // }, [venueId]);
  // useEffect(() => {
  //   setForm((prevForm) => {
  //     const updatedVenues = prevForm.venues.includes(venueId)
  //       ? prevForm.venues
  //       : [...prevForm.venues, venueId];
  //     return {
  //       ...prevForm,
  //       venues: updatedVenues,
  //     };
  //   });
  // }, [venueId]);
  // useEffect(() => {
  //   setForm((prevForm) => {
  //     let updatedVenues;
  //
  //     // Handling the case when venueIDS is an array
  //     if (Array.isArray(venueIDS)) {
  //       // Combine the existing venues with the new venueIDS, removing duplicates
  //       updatedVenues = [...new Set([...prevForm.venues, ...venueIDS])];
  //     }
  //     // Handling the case when venueId is a single number and not included in the existing list
  //     else if (venueId && !prevForm.venues.includes(venueId)) {
  //       updatedVenues = [...prevForm.venues, venueId];
  //     } else {
  //       // If neither venueId nor venueIDS changes the existing list, retain the current list
  //       updatedVenues = prevForm.venues;
  //     }
  //
  //     return {
  //       ...prevForm,
  //       venues: updatedVenues,
  //     };
  //   });
  // }, [venueId, venueIDS]); // Dependency array now includes both venueId and venueIDS

  const [form, setForm] = useState(initialState);
  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      first_name: userData.name || prevForm.first_name,
      last_name: userData.surname || prevForm.last_name,
      email: userData.email || prevForm.email,
      user_id: userData.id || prevForm.id,
    }));
  }, [userData]);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("FORM DATA FOR INQ: ", form);
    if (unitId) {
      dispatch(venueInquiries({ ...form, unit_requested_id: Number(unitId) }));
    } else {
      dispatch(venueInquiries(form));
    }
    setForm(initialState);
    setPage(0);
    handleClose();
  };

  const handleChange = (e) => {
    if (
      e.target.name === "are_your_event_dates_flex" ||
      e.target.name === "do_you_need_meeting_rooms"
    ) {
      setForm((prev) => ({
        ...prev,
        [e.target.name]: JSON.parse(e.target.value),
      }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "10px 0 10px 0",
          minWidth: minWidth,
        },
      }}
    >
      <div className={styles.eventFormContainer}>
        <div className={styles.title}>
          Let's talk about your event! Share with us your event details <br />{" "}
          and you will receive fast and precise quote based on your request
        </div>
        {page === 0 ? (
          <>
            <FormContainer
              title={"Event type"}
              rightComponent={
                <FormControl fullWidth>
                  <Select
                    value={form.event_type}
                    onChange={handleChange}
                    name="event_type"
                  >
                    {events?.map((event, index) => {
                      // Check if event is an object
                      if (typeof event === "object" && event !== null) {
                        return (
                          <MenuItem
                            key={event.id || index}
                            value={event.title || event}
                          >
                            {event.title || event}
                          </MenuItem>
                        );
                      } else {
                        // Handle the case when event is not an object
                        return (
                          <MenuItem key={event} value={event}>
                            {event}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              }
            />
            <FormContainer
              title={"EVENT DATES"}
              rightComponent={
                <EventDates
                  form={form}
                  arrive={form.arrive_on}
                  depart={form.depart_on}
                  setForm={setForm}
                  change={handleChange}
                />
              }
            />
            <FormContainer
              title={"GUEST ROOMS"}
              rightComponent={<GuestRooms />}
            />
            <FormContainer
              title={"EVENT SPACE TYPE"}
              rightComponent={
                <EventSpaceType
                  attendees={form.attendees_per_day}
                  doYouNeedMeetingRooms={form.do_you_need_meeting_rooms}
                  handleChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"TELL US MORE ABOUT YOUR EVENT"}
              rightComponent={
                <FilledTextField
                  value={form.tell_us_more}
                  name="tell_us_more"
                  multiline
                  rows={4}
                  onChange={handleChange}
                />
              }
            />
          </>
        ) : (
          <div className={styles.eventFormContainer}>
            <FormContainer
              title={"First name"}
              rightComponent={
                <FilledTextField
                  value={form.first_name}
                  name="first_name"
                  onChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"Last name"}
              rightComponent={
                <FilledTextField
                  value={form.last_name}
                  name="last_name"
                  onChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"Gender"}
              rightComponent={
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="gender"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              }
            />
            <FormContainer
              title={"Country"}
              rightComponent={
                <FilledTextField
                  value={form.country}
                  name="country"
                  onChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"Email"}
              rightComponent={
                <FilledTextField
                  value={form.email}
                  name="email"
                  onChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"Phone"}
              rightComponent={
                <FilledTextField
                  value={form.phone}
                  name="phone"
                  onChange={handleChange}
                />
              }
            />
            <FormContainer
              title={"Industry"}
              rightComponent={
                <FilledTextField
                  value={form.industry}
                  name="industry"
                  onChange={handleChange}
                />
              }
            />
          </div>
        )}
        <div className={styles.buttons}>
          <span className={styles.nextButton}>
            <Button
              onClick={() =>
                page === 0 ? setPage(page + 1) : setPage(page - 1)
              }
              style={{
                backgroundColor: "orange",
                color: "black",
                width: 150,
                height: 40,
                borderRadius: "5px 0 5px 0",
              }}
            >
              {" "}
              {page === 0 ? (
                <>
                  Next <EastIcon />
                </>
              ) : (
                <>
                  <WestIcon /> Previous
                </>
              )}{" "}
            </Button>{" "}
          </span>
          {page === 1 && (
            <span className={styles.nextButton}>
              <Button
                onClick={handleSubmit}
                style={{ width: 150, height: 40, borderRadius: "5px 0 5px 0" }}
              >
                Send
              </Button>{" "}
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default EventForm;
