import React from "react";
import styles from "./SectionWrapper.module.css";

const SectionWrapper = React.forwardRef(({ variant, children }, ref) => {
  return (
    <section className={`${styles.wrapper} ${styles[variant]}`} ref={ref}>
      {children}
    </section>
  );
});

export default SectionWrapper;
