import StarRating from "../../../../../../common/components/StarRating/StarRating";
import styles from "./RatingsText.module.css";

export default function RatingsText({ text, num, link }) {
  return (
    <div className={styles.container}>
      <a className={styles.purple} href={link} rel="noopener noreferrer" target="_blank">
      {text}
      </a>
      <StarRating num={num} />
    </div>
  );
}
    