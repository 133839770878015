import { useEffect } from "react";

const useHideScroll = (open) => {
  useEffect(() => {
    const body = document.getElementsByTagName("body");
    if (open) {
      body[0].style.overflowY = "hidden";
    } else {
      body[0].style.overflowY = "scroll";
    }
  }, [open]);
};

export default useHideScroll;
