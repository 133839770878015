import styles from "./GuestRoomsPhotosSection.module.css";
import React, {  Fragment, useState } from "react";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import CustomizedImageGalleryModal from "../../../../../../common/components/CustomizedImageGalleryModal";
import TextGrid from "../../components/TextGrid/TextGrid";
import useAxios from "../../../../../../hooks/useAxios";

const GuestRoomsPhotosSection = React.forwardRef(({totalGuestRooms, typeOfGuestRoom}, ref) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [guestRoomsDetails, setGuestRoomsDetails] = useState([]);
  //
  // const fetchGuestRoomDetails = (roomId) => {
  //   const { response, fetchData } = useAxios({
  //     url: `/type-of-guest-room/${roomId}`,
  //     method: "get",
  //   });
  //   fetchData();
  //   return response;
  // };
  //
  // useEffect(() => {
  //   // Fetch details for each guest room
  //   Promise.all(
  //     venue.guest_room.map((room) => fetchGuestRoomDetails(room.id))
  //   ).then((results) => {
  //     // Map the results to the desired format
  //     const mappedResults = results.map((result, index) => ({
  //       x: venue.guest_room[index].name_en, // or any other property you want to display
  //       y: result, // assuming the response contains the details you want to display
  //     }));
  //     setGuestRoomsDetails(mappedResults);
  //   });
  // }, [venue]);
  //
  const data2 = [{ x: "Total guest rooms", y: totalGuestRooms }];


  if(!typeOfGuestRoom) {
    return
  }
  return (
    <SectionWrapper variant={"alignItemsFlexStart"} ref={ref}>
      <TextGrid title="Guest Rooms" data={data2} />
      <div className={styles.title}>Photos</div>
      <div className={styles.grid}>
        {typeOfGuestRoom.map(guestRoom => {
          return <Fragment key={guestRoom.id}>
          {guestRoom.galleries.map(image => (
            <div className={styles.imageContainer} key={image.id}>
            <img src={image.image} alt="" />
          </div>
        ))}
        {guestRoom.galleries.length > 3 && 
        <>
        <div className={styles.imageContainer} onClick={handleOpen}>
                <div className={styles.openGallery}>
                  <div>+{guestRoom.galleries.length - 2}</div>
                </div>
                <img src={guestRoom.galleries && guestRoom.galleries[guestRoom.galleries.length - 1].image} alt="" />
              </div>
              <CustomizedImageGalleryModal handleClose={handleClose} open={open} images={guestRoom.galleries} />
              </>
        }
        </Fragment>
    })}
      
      </div>
    </SectionWrapper>
  );
});

export default GuestRoomsPhotosSection;
