const inputStyles = {
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--clr-pink)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--clr-pink)",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0.3rem 0 0.3rem 0",
    // minWidth: 300,
  },
};

export default inputStyles;
