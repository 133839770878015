import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import styles from "../styles/AuthStyles.module.css";
const AccountMessage = ({ login }) => {
  return (
    <div className={styles.dontHaveAccount}>
      <Typography variant="body2" align="center">
        {login ? "Don't have an account yet? " : "Already have an account? "}
        <Link to={login ? "/register" : "/login"}>
          {login ? "Sign up" : "Log in"}
        </Link>
      </Typography>
      <Typography variant="body2" align="center">
        <Link to={"#"}>Help&Support</Link>
      </Typography>
    </div>
  );
};

export default AccountMessage;
