import styles from "./BasicDropdown.module.css";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

export default function BasicDropdown({
  options,
  onChange,
  property,
  isDropdownOpen,
  handleDropdownClick,
  handlePlaceholder,
}) {
  return (
    <div
      className={
        isDropdownOpen ? styles.dropdownContentOpen : styles.dropdownContent
      }
    >
      {options.map((option) => (
        <div
          className={styles.dropdownItem}
          key={option}
          onClick={() => {
            onChange(property, option);
            handlePlaceholder(option);
            handleDropdownClick();
          }}
        >
          {property === "location" || property === "venue" ? (
            <PlaceOutlinedIcon style={{ color: "#7e7e7e", fontSize: "1rem" }} />
          ) : null}{" "}
          {option}
        </div>
      ))}
    </div>
  );
}
