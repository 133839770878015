import React from "react";
import FormTextField from "../../../../../../common/components/FormComponents/FormTextField";
import FormSelectField from "../../../../../../common/components/FormComponents/FormSelectField";
import styles from "../Newsletter/Newsletter.module.css";
import DataProtectionCheckbox from "../../../../../../common/components/FormComponents/DataProtection";
import FormSubmitButton from "../../../../../../common/components/FormComponents/FormSubmitButton";
const CompanyDetails = ({ formData, handleChange, style }) => {
  const fields = [
    {
      label: "Company",
      component: FormTextField,
      name: "company",
      style,
      variant: "standard",
    },
    {
      label: "Your Position",
      component: FormTextField,
      name: "position",
      style,
      variant: "standard",
    },
    {
      label: "ZIP Code",
      component: FormTextField,
      name: "zipCode",
      required: true,
      style,
      variant: "standard",
    },
    {
      label: "City",
      component: FormTextField,
      name: "city",
      required: true,
      style,
      variant: "standard",
    },
    {
      label: "Country*",
      component: FormSelectField,
      name: "country",
      options: ["Select Country", "Montenegro", "Serbia", "Croatia"],
      required: true,
    },
  ];

  return (
    <div className={styles.formSection}>
      {fields.map(({ label, component: FormComponent, ...props }) => (
        <FormComponent
          key={label}
          label={label}
          value={formData[props.name]}
          onChange={(value) => handleChange(props.name, value)}
          {...props}
        />
      ))}
      <DataProtectionCheckbox />
      <FormSubmitButton />
    </div>
  );
};

export default CompanyDetails;
