import React, { useState, useEffect, useRef } from "react";
import styles from "../SustainableVenuesFinal/SustainableVenuesFinal.module.css";
import { ReactComponent as MenuIcon } from "../../../assets/svg-mice/lines.svg";
import VenueTypeAndFacilitiesDropdown from "./VenueTypeAndFacilitiesDropdown/VenueTypeAndFacilitiesDropdown";

const iconStyle = {
  color: "#707070",
  border: "1px solid rgb(180, 180, 180)",
  padding: "0.7rem 0.8rem",
  borderRadius: "0.4rem 0 0.4rem 0",
  marginRight: "0.5rem",
  cursor: "pointer",
  fontSize: "1.1rem",
  width: "56px",
  height: "56px",
  marginTop: "4px",
};
const VenueTypeFacilities = ({ selections, setSelections }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  // const closeDropdown = () => {
  //   setShowDropdown(false);
  // };

  const divRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (divRef.current !== e.target) {
        setShowDropdown(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className={styles.container}>
      <div onClick={() => setShowDropdown((prevState) => !prevState)}>
        <MenuIcon style={iconStyle} ref={divRef} />
      </div>
      {showDropdown && (
        <div>
          <VenueTypeAndFacilitiesDropdown
            isFullContent={true}
            // handleClose={closeDropdown}
            selections={selections}
            setSelections={setSelections}
            ref={dropdownRef}
          />
        </div>
      )}
    </div>
  );
};

export default VenueTypeFacilities;
