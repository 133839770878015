import styles from "./FloorPlanMeetingRoomGridSection.module.css";
import CustomButtonGroup from "../../../../../../common/components/CustomButtonGroup/CustomButtonGroup";
import React, { useState } from "react";
import TextGrid from "../../components/TextGrid/TextGrid";
import Hr from "../../../../../../common/components/Hr/Hr";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import MeetingRoomDataGrid from "../../components/MeetingRoomGrid/MeetingRoomDataGrid";
import CustomCard from "../../components/CustomCard/CustomCard";

const FloorPlanMeetingRoomGridSection = React.forwardRef((props, ref) => {
  const {
    conferenceRoomSpace,
    largestRoomSpace,
    numberOfConferenceRooms,
    units,
    handleOpen,
    title,
  } = props;
  const [isGrid, setIsGrid] = useState(true);

  const data1 =
    conferenceRoomSpace && largestRoomSpace && numberOfConferenceRooms
      ? [
          { x: "Conference rooms space", y: `${conferenceRoomSpace} m2` },
          { x: "Largest room", y: `${largestRoomSpace} m2` },
          { x: "Meeting rooms", y: `${numberOfConferenceRooms}` },
        ]
      : null;

  return (
    <SectionWrapper variant={"alignItemsFlexStart"} ref={ref}>
      {data1 && <TextGrid title="Conference Rooms" data={data1} />}
      {!data1 && <div style={{ fontSize: "1.5rem" }}>{title}</div>}
      <div className={styles.filtersContainer}>
        <div></div>
        <div>
          <CustomButtonGroup
            text1="Interactive floor plan"
            text2="Table view"
            setBtnFlag={setIsGrid}
          />
        </div>
      </div>
      {isGrid ? (
        <div className={styles.cardsContainer}>
          {units.map((unit) => (
            <CustomCard
              key={unit.id}
              id={unit.pivot.unit_id}
              url={unit.featured_image}
              title={unit.name}
              subtitle={unit.description}
              floorPlanImage={unit.floor_plan_image}
              handleOpen={handleOpen}
              button1Text={"Send Inquiry"}
              button2Text={"View More"}
              button3Text={"Download Floor Plan"}
            />
          ))}
        </div>
      ) : (
        <>
          <MeetingRoomDataGrid units={units} />
          <Hr />
        </>
      )}
    </SectionWrapper>
  );
});

export default FloorPlanMeetingRoomGridSection;
