import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import WhenDropdown from "../DropdownComponents/WhenDropdown/WhenDropdown";
import NumberOfPersonsDropdown from "../DropdownComponents/NumberOfPersonsDropdown/NumberOfPersonsDropdown";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";

const CustomAutocomplete = ({ options, label, wid, value, setValue }) => {
  const [showNumberOfPersonsDropdown, setShowNumberOfPersonsDropdown] =
    useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const dispatch = useDispatch(); // Use the useDispatch hook

  const handleOptionClick = (option) => {
    if (option && option.label === "Duration") {
      setShowDatePicker(true);
    }
    if (option && option.label === "Select") {
      setShowNumberOfPersonsDropdown(true);
    }

    if (option && option.value) {
      //dispatch(setCity(option.value));
      setValue(option.value);
    }
  };

  const handleDatePickerClose = () => {
    console.log("close");
    setShowDatePicker(false);
  };

  const getTextFieldStyles = () => {
    if (wid) {
      return {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "var(--clr-pink)",
          },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "var(--clr-pink)",
          fontFamily: "'Montserrat', sans-serif",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "'Montserrat', sans-serif",
          color: "var(--clr-gray-500)",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "0.5rem 0 0.5rem 0",
          minWidth: 250,
          maxWidth: 600,
          margin: "0",
          fontFamily: "'Montserrat', sans-serif",
        },
      };
    } else {
      return {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "var(--clr-pink)",
          },
        "& .MuiInputLabel-root": {
          fontFamily: "'Montserrat', sans-serif",
          color: "var(--clr-gray-500)",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "var(--clr-pink)",
          fontFamily: "'Montserrat', sans-serif",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "0.5rem 0 0.5rem 0",
          minWidth: 200,
          maxWidth: 600,
          margin: "0",
          fontFamily: "'Montserrat', sans-serif",
        },
      };
    }
  };
  return (
    <>
      {label === "No. of Persons" ? (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">No. of Persons</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={label}
            sx={getTextFieldStyles()}
          >
            <MenuItem>
              <NumberOfPersonsDropdown
                isDropdownOpen={showNumberOfPersonsDropdown}
              />
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <div>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            onChange={(event, option) => handleOptionClick(option)}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                margin="normal"
                sx={getTextFieldStyles()}
              />
            )}
          />
          {showDatePicker && <WhenDropdown onClose={handleDatePickerClose} />}
        </div>
      )}
    </>
  );
};

export default CustomAutocomplete;
