import styles from "./Help.module.css";
import WhatsApp from "../WhatsApp/WhatsApp";
import NeedHelp from "../NeedHelp/NeedHelp";

export default function Help() {
  return (
    <div className={styles.helpContainer}>
      <NeedHelp />
      <WhatsApp />
    </div>
  );
}
