import axios from "axios";

export const authAxios = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
});

export const miceAxios = axios.create({
  baseURL: process.env.REACT_APP_MICE_API,
});

export const blogAxios = axios.create({
  baseURL: process.env.REACT_APP_BLOG_API,
});
