import styles from "./PhotosAndVideosSection.module.css";
import LinearGradient from "../../../../../../common/components/LinearGradient/LinearGradient";
import React, { useState } from "react";
import Hr from "../../../../../../common/components/Hr/Hr";
import Button from "../../../../../../common/components/Button/Button";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomizedVideoGallery from "../../components/CustomizedVideoGallery/CustomizedVideoGallery";
import { YouTube } from "@mui/icons-material";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import Title from "../../../../../../common/components/Title/Title";
import SlickSlider from "../../../../../../common/components/SlickSlider/SlickSlider";

const PhotosAndVideosSection = React.forwardRef(({ images, videos }, ref) => {
  const [open, setOpen] = useState(false);
  const [activeVideoIndex, setActiveVideoIndex] = useState("");
  const handleOpen = (index) => {
    setOpen(true);
    setActiveVideoIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [flag, setFlag] = useState(true);
  const [numPhotos, setNumPhotos] = useState(6);
  const [numVideos, setNumVideos] = useState(6);

  const showMore = (images) => {
    if (images) {
      setNumPhotos(numPhotos + 3);
    } else {
      setNumVideos(numVideos + 3);
    }
  };

  const showLess = (images) => {
    if (images) {
      setNumPhotos(6);
    } else {
      setNumVideos(6);
    }
  };

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  // console.log(videos);
  const renderButton = (num, files, isImages) => {
    return (
      <>
        {num < files.length ? (
          <div onClick={() => showMore(isImages)}>
            <Button variant={"outlined"} color={"gray"}>
              {" "}
              Show More
            </Button>
          </div>
        ) : num > 6 && num >= files.length ? (
          <div onClick={() => showLess(isImages)}>
            <Button variant={"outlined"} color={"gray"}>
              Show Less{" "}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <SectionWrapper ref={ref}>
      <div className={styles.buttonsContainer}>
        <Title text="Media Gallery" />
        <div className={styles.buttonsSubContainer}>
          <div onClick={() => setFlag(true)}>
            <Button color="pink" variant={"outlined"}>
              PHOTO GALLERY{" "}
            </Button>
          </div>
          <div onClick={() => setFlag(false)}>
            <Button color="purple" variant={"outlined"}>
              VIDEO GALLERY{" "}
            </Button>
          </div>
        </div>
      </div>
      <Hr />
      <div
        style={{
          color: "var(--clr-pink)",
          fontSize: "1.2rem",
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          margin: "1rem 0",
          fontWeight: "500",
        }}
      >
        {flag ? "Photos" : "Videos"}
      </div>
      {flag ? (
        <>
          <div className={styles.grid}>
            {images.slice(0, numPhotos).map((image) => (
              <div className={styles.gridItem} key={image.id}>
                <LinearGradient />
                <img src={image.image} alt="img" />
              </div>
            ))}
          </div>
          {renderButton(numPhotos, images, true)}
          {/*<SlickSlider content={images} type="image" />*/}
        </>
      ) : (
        <>
          <div className={styles.videosContainer}>
            <div className={styles.grid}>
              {videos.slice(0, numVideos).map((video, index) => (
                <div key={video.id} className={styles.gridItem}>
                  <img src={video.thumbnail_image} alt="" />
                  <YouTube
                    className={styles.youtubeIcon}
                    onClick={() => handleOpen(index)}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                      color: "white",
                      zIndex: "50",
                      fontSize: "5rem",
                      boxShadow:
                        "box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 24px",
                    }}
                  />
                </div>
              ))}
              {/*<SlickSlider content={videos} type="video" />*/}
            </div>
            {renderButton(numVideos, videos, false)}
          </div>
        </>
      )}
      <Hr />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        sx={{
          backgroundColor: "#1c2229",
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div>
            <CustomizedVideoGallery
              handleClose={handleClose}
              activeVideoIndex={activeVideoIndex}
              videos={videos}
            />
          </div>
        </Fade>
      </Modal>
    </SectionWrapper>
  );
});

export default PhotosAndVideosSection;
