import React from "react";
import styles from "./BlogInfo.module.css";

const BlogInfo = ({ title, date, author }) => {
  return (
    <>
      {title ? (
        <div className={styles.title}>{title}</div>
      ) : (
        <div>No title for this Blog.</div>
      )}
      <div className={styles.author}>
        <div>{date}</div>

        {author ? (
          <div>By: {author}</div>
        ) : (
          <div> No Author for this Blog. </div>
        )}
      </div>
    </>
  );
};

export default BlogInfo;
