import styles from "./VenueOverviewSection.module.css";
import Description from "../../components/Description/Description";
import Title from "../../../../../../common/components/Title/Title";
import RatingsText from "../../components/RatingsText/RatingsText";
import TextInfo from "../../components/TextInfo/TextInfo";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import React from "react";

const VenueOverviewSection = React.forwardRef(({ venue }, ref) => {
  const subtitleStyle = {
    fontWeight: "600",
    color: "var(--clr-purple)",
  };
  // console.log(venue);

  const temp1 = [
    { x: "Chain", y: venue.chain },
    { x: "Style", y: [...venue.styles] },
    { x: "Renovated", y: venue.renovated },
    {
      x: "Total number of Conference rooms",
      y: venue.total_number_of_conference_rooms,
    },
    { x: "Brands", y: venue.brand },
    { x: "Built", y: venue.built },
    {
      x: "Total Conference rooms capacity",
      y: venue.total_conference_room_space,
    },
    {
      x: "Largest room capacity",
      y: venue.largest_rooms_space,
    },
    { x: "Guest Rooms", y: venue.total_guest_rooms },
  ];

  const data1 = temp1.filter(
    (item) =>
      item.y !== null &&
      item.y !== undefined &&
      (Array.isArray(item.y) ? item.y.length > 0 : true)
  );
  const temp = [
    {
      title: "General Information",
      data: [...venue.general_informations],
    },
    {
      title: "Amenities",
      data: [...venue.amenities_lists],
    },
    {
      title: "Type of Event that can be organised",
      data: [...venue.events],
    },
    {
      title: "Categories",
      data: [...venue.venue_categories],
    },
  ];

  const data2 = temp.filter((item) => item.data.length > 0);

  return (
    <SectionWrapper variant={"alignItemsFlexStart"} ref={ref}>
      <Title text="Overview" />
      {venue.provider && (
        <div>
          Provider:{" "}
          <span style={{ fontStyle: "italic", color: "var(--clr-purple)" }}>
            {venue.provider}
          </span>
        </div>
      )}
      <div
        style={{
          fontSize: "1rem",
        }}
      >
        {venue?.description}
      </div>

      <div style={subtitleStyle}> Description </div>
      <div className={styles.grid}>
        {data1.map((item, index) => {
          return <Description text1={item.x} text2={item.y} key={index} />;
        })}
      </div>
      <div style={subtitleStyle}> Ratings </div>
      <div className={styles.grid}>
        {venue.reviews.map((review) => (
          <RatingsText
            key={review.id}
            text={review.name}
            num={review.stars}
            link={review.review_link}
          />
        ))}
      </div>
      <div className={styles.grid}>
        {data2.map((item, index) => {
          return <TextInfo data={item} key={index} />;
        })}
      </div>
    </SectionWrapper>
  );
});

export default VenueOverviewSection;
