import styles from "./VenuesTableSection.module.css";

import AccessibleTable from "./MUITable";
export default function VenuesTableSection({ matchVenues }) {
  return (
    <section className={styles.sectionWrapper}>
      <div className={`${styles.sectionWrapper} ${styles.leftSection}`}>
        <div className={styles.text}>
          Check out {matchVenues.length} venues that also match your needs
        </div>
        <AccessibleTable matchVenues={matchVenues} />
      </div>
    </section>
  );
}
