import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { miceAxios } from "../../axios/axios";

const initialState = {
  loading: false,
  amenities: [],
  error: null,
  success: false,
};

export const fetchAmenities = createAsyncThunk(
  "amenities/fetchAmenities",
  async () => {
    const response = await miceAxios.get("/amenities-list");
    return response.data;
  }
);

const amenitiesSlice = createSlice({
  name: "amenities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmenities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAmenities.fulfilled, (state, action) => {
        state.loading = false;
        state.amenities = action.payload;
        state.success = true;
      })
      .addCase(fetchAmenities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default amenitiesSlice.reducer;
