import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Wrapper = ({ children, description, title }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "calc(8px + 1.5625vw)",
        overflow: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography>{description}</Typography>
        <Typography variant={"h5"}>{title}</Typography>
        {children}
      </Stack>
    </Box>
  );
};

export default Wrapper;
