import styles from "./VenuesTable1.module.css";
import Button from "../../../../../../../common/components/Button/Button";

const VenuesTable1 = () => {
  const data = [
    {
      id: 0,
      imageUrl:
        "https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg",
      venue: "London Marriot Hotel Canary Wharf",
      venueType: "Hotel",
      location: "London",
      rating: 4,
      guestRooms: 301,
      meetingRooms: 19,
      largestRoom: 2000,
      meetingSpace: 15000,
    },
    {
      id: 1,
      imageUrl:
        "https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg",
      venue: "Another Venue",
      venueType: "Another Type",
      location: "Another Location",
      rating: 5,
      guestRooms: 100,
      meetingRooms: 10,
      largestRoom: 1500,
      meetingSpace: 8000,
    },
    {
      id: 2,
      imageUrl:
        "https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg",
      venue: "Yet Another Venue",
      venueType: "Yet Another Type",
      location: "Yet Another Location",
      rating: 3,
      guestRooms: 200,
      meetingRooms: 15,
      largestRoom: 1800,
      meetingSpace: 12000,
    },
  ];

  return (
    <div className={styles.container}>
      <table>
        <tbody>
          <tr>
            <th className={styles.headerCell}></th>
            <th className={styles.imageCell}>
              <img
                src={
                  "https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg"
                }
                alt={"venue"}
              />
            </th>
            <th className={styles.imageCell}>
              <img
                src={
                  "https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg"
                }
                alt={"venue"}
              />
            </th>
            <th className={styles.imageCell}>
              <img
                src={`https://officebanao.com/wp-content/uploads/2022/10/Modern-office-design-5-1024x576.jpg`}
                alt={"venue"}
              />
            </th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Venue</th>
            <th className={`${styles.dynamicDataCell} ${styles.venueName}`}>
              London Marriot Hotel Canary Wharf
            </th>
            <th className={`${styles.dynamicDataCell} ${styles.venueName}`}>
              London Marriot Hotel Canary Wharf
            </th>
            <th className={`${styles.dynamicDataCell} ${styles.venueName}`}>
              London Marriot Hotel Canary Wharf
            </th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Venue Type</th>
            <th className={styles.dynamicDataCell}>Hotel</th>
            <th className={styles.dynamicDataCell}>Hotel</th>
            <th className={styles.dynamicDataCell}>Hotel</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Location</th>
            <th className={styles.dynamicDataCell}>London</th>
            <th className={styles.dynamicDataCell}>London</th>
            <th className={styles.dynamicDataCell}>London</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Rating</th>
            <th className={styles.dynamicDataCell}>Venue Rating</th>
            <th className={styles.dynamicDataCell}>Venue Rating</th>
            <th className={styles.dynamicDataCell}>Venue Rating</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Guest Rooms</th>
            <th className={styles.dynamicDataCell}>301</th>
            <th className={styles.dynamicDataCell}>301</th>
            <th className={styles.dynamicDataCell}>301</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Meeting Rooms</th>
            <th className={styles.dynamicDataCell}>19</th>
            <th className={styles.dynamicDataCell}>19</th>
            <th className={styles.dynamicDataCell}>19</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Largest Room</th>
            <th className={styles.dynamicDataCell}>2,000 m2</th>
            <th className={styles.dynamicDataCell}>2,000 m2</th>
            <th className={styles.dynamicDataCell}>2,000 m2</th>
          </tr>
          <tr>
            <th className={styles.headerCell}>Meeting Space</th>
            <th className={styles.dynamicDataCell}>15, 000 m2</th>
            <th className={styles.dynamicDataCell}>15, 000 m2</th>
            <th className={styles.dynamicDataCell}>15, 000 m2</th>
          </tr>
          <tr>
            <th className={styles.headerCell}></th>
            <th className={styles.dynamicDataCell}>
              <div className={`${styles.dynamicDataCell} ${styles.buttonCell}`}>
                <Button color={"pink"}>Select Venue</Button>
              </div>
            </th>
            <th className={styles.dynamicDataCell}>
              <div className={`${styles.dynamicDataCell} ${styles.buttonCell}`}>
                <Button color={"pink"}>Select Venue</Button>
              </div>
            </th>
            <th className={styles.dynamicDataCell}>
              <div className={`${styles.dynamicDataCell} ${styles.buttonCell}`}>
                <Button color={"pink"}>Select Venue</Button>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VenuesTable1;
