import { Link } from "react-router-dom";
import styles from "./FirstColumnCell.css";

const FirstColumnCell = ({ name, imageUrl, id }) => {
  return (
    <div className={"firstColumnCell"}>
      <img src={imageUrl} alt={"venue"} className={"firstColumnCellImage"} />

      <div className={"textContainer"}>
        <div>{name}</div>
        <Link className={"viewDetails"} to={`./unit/${id}`}>
        View Details
        </Link>
      </div>
    </div>
  );
};

export default FirstColumnCell;
