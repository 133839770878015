import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import styles from "./NeedHelp.module.css";
import { Link } from "react-router-dom";

export default function NeedHelp() {
  return (
    <div className={styles.needHelpContainer}>
      <div className={styles.grayBackgroundContainer}>
        <WhereToVoteIcon sx={{ color: "var(--clr-purple)" }} />
        <span className={styles.needHelpSpan}>Need help sourcing a venue?</span>
        <span className={styles.weProvideSpan}>
          We provide a free of charge venue finding service for all your
          corporate events.
        </span>
      </div>
      <Link to={"/stay-form"}>
        <button className={styles.askAnExpertButton}>ASK AN EXPERT</button>
      </Link>
    </div>
  );
}
