import {
  startVenuesSearch,
  saveVenuesSearchResults,
  venuesSearchError,
} from "./venue.js";
import { miceAxios } from "../../axios/axios";

export const fetchVenues = (postData, url, type) => async (dispatch) => {
  dispatch(startVenuesSearch());
  try {
    const response = await miceAxios.post(url, postData);
    localStorage.setItem(`${type}SearchParams`, JSON.stringify(postData));
    //console.log(JSON.stringify(postData));
    const sortedVenues = response.data.venues.sort(
      (a, b) => a.order_venue_in_list - b.order_venue_in_list
    );
    dispatch(saveVenuesSearchResults(sortedVenues));
    localStorage.removeItem("venues_categories");
    localStorage.removeItem("amenities_lists");
  } catch (error) {
    dispatch(venuesSearchError(error));
  }
};
