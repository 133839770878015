import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BasicDropdown from "./BasicDropdown/BasicDropdown";
import styles from "./CustomDropdownSelect.module.css";
import NumberOfPersonsDropdown from "../NumberOfPersonsDropdown/NumberOfPersonsDropdown";
import StayBedroomsDropdown from "../StayBedroomsDropdown/StayBedroomsDropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function CustomDropdownSelect({
  options,
  placeholder,
  onChange,
  property,
  type,
  label,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePlaceholder = (text) => {
    // setPlaceholderState(text);
  };

  return (
    <div className={styles.dropdown}>
      {/*<div className={styles.dropdownBtnLabel}>{label}</div>*/}
      <div
        className={`${styles.dropdownBtn} ${styles[type]}`}
        onClick={handleDropdownClick}
      >
        {placeholder}
        {type === "doubleNumerical" ? (
          <div className={styles.doubleNumericalIconsWrapper}>
            <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
          </div>
        ) : (
          <ArrowDropDownIcon />
        )}
      </div>

      {property === "numberOfPersons" ? (
        <NumberOfPersonsDropdown
          isDropdownOpen={isDropdownOpen}
          handleDropdownClick={handleDropdownClick}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      ) : property === "bedrooms" ? (
        <StayBedroomsDropdown
          isDropdownOpen={isDropdownOpen}
          handleDropdownClick={handleDropdownClick}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      ) : (
        <BasicDropdown
          options={options}
          onChange={onChange}
          property={property}
          isDropdownOpen={isDropdownOpen}
          handleDropdownClick={handleDropdownClick}
          handlePlaceholder={handlePlaceholder}
        />
      )}
    </div>
  );
}
