import React, { useState } from "react";
import styles from "./SingleBlogPage.module.css";
import Footer from "../../../../common/components/Footer/Footer";
import { useLocation } from "react-router-dom";
import LinearIndeterminate from "../../../../common/components/MUIComponents/LinearIndeterminate";
import { useSelector } from "react-redux";
import Hr from "../../../../common/components/Hr/Hr";
import Button from "../../../../common/components/Button/Button";
import { Link } from "react-router-dom";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import BlogHeaderImage from "./components/BlogHeaderImage/BlogHeaderImage";
import BlogDescription from "./components/BlogDescription/BlogDescription";
import BlogInfo from "./components/BlogInfo/BlogInfo";
import RoundImage from "./components/RoundImage/RoundImage";
import { selectBlogById } from "../../redux/blogSelectors";

const SingleBlogPage = () => {
  useScrollToTop();

  let location = useLocation();
  const id = location.pathname.split("/")[2];

  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const toggleTextExpanded = () => setIsTextExpanded(!isTextExpanded);

  const blogData = useSelector(selectBlogById)(id);
  const { blog: response, loading, error } = blogData;

  if (loading) return <LinearIndeterminate />;

  if (error) return <div>Error: {error.message}</div>;

  if (!response) return <div>No blog found with id: {id}</div>;

  const formattedDate = new Date(response.date).toLocaleString("en-US", {
    dateStyle: "long",
    timeStyle: "short",
  });

  const imgURL =
    "https://www.aptouring.com/-/media/apt-responsive-website/europe/hero-panel-12-5/montenegro-12-5/hc-a-eu-montenegro-kotor-bay-221786749-s-12-5.jpg?useCustomFunctions=1&rw=2280&w=1900&h=950&cropX=190&cropY=0";

  const smallerImgURL =
    "https://i0.wp.com/www.adventureswithluda.com/wp-content/uploads/2021/06/IMG_0693.jpg?resize=640%2C480&ssl=1";

  return (
    <PageWrapper variant={"blog"}>
      <BlogHeaderImage src={response.main_image} alt={"blog header"} />
      <div className={styles.mainBlogContainer}>
        <BlogInfo
          author={response.author}
          date={formattedDate}
          title={response.title_me}
        />
        <BlogDescription
          text={response.big_description_me}
          isTextExpanded={isTextExpanded}
          toggleTextExpanded={toggleTextExpanded}
        />
        <RoundImage alt={"circle"} url={response.card_image} />
      </div>
      <Link to={"/blogs"}>
        <Button>See All Blogs </Button>
      </Link>
      <Hr />
      <Footer />
    </PageWrapper>
  );
};

export default SingleBlogPage;
