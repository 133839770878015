import styles from "./VenueGallerySection.module.css";
import LinearGradient from "../../../../../../common/components/LinearGradient/LinearGradient";
import Button from "../../../../../../common/components/Button/Button";
import ImageIcons from "../../../../../../common/components/ImageWithIconsAndGradient/ImageIcons";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import { useEffect, useState } from "react";
import CustomizedImageGalleryModal from "../../../../../../common/components/CustomizedImageGalleryModal";
import { blogAxios, miceAxios } from "../../../../../../axios/axios";

export default function VenueGallerySection({ venue }) {
  const [open, setOpen] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const fetchImageUrls = async (imageIds) => {
    try {
      const response = await blogAxios.get("getAllFiles");
      const allImages = response.data;
      return allImages.filter((image) => imageIds.includes(image.id));
    } catch (error) {
      console.error("Error fetching image URLs:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const galleryResponses = await Promise.all(
          venue.photo_galleries.map((gallery) =>
            miceAxios.post("get-gallery-images", { gallery_id: gallery.id })
          )
        );
        const galleryImages = galleryResponses.map(
          (response) => response.data.images
        );
        //console.log(galleryImages);
        const imageIds = galleryImages.map((gallery) =>
          gallery.map((image) => image.image_id)
        );

        const galleriesImagesPromises = imageIds.map((ids) =>
          fetchImageUrls(ids)
        );

        const galleriesImages = await Promise.all(galleriesImagesPromises);
        const nonEmptyGalleries = galleriesImages.filter(
          (gallery) => Array.isArray(gallery) && gallery.length > 0
        );
        setGalleries(nonEmptyGalleries);
        //console.log("GALLERIES", nonEmptyGalleries);
      } catch (error) {
        console.error("Error fetching galleries:", error);
      }
    };

    if (venue.photo_galleries.length) {
      fetchGalleries();
    }
  }, [venue.photo_galleries]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SectionWrapper>
      {galleries.length >= 3 ? (
        <div className={styles.grid}>
          <div className={`${styles.gridItem} ${styles.spanCol1}`}>
            <ImageIcons
              url={
                galleries[0] && galleries[0][0]
                  ? galleries[0][0].path
                  : "/common/assets/png/empty.jpg"
              }
            />
          </div>
          <div className={`${styles.gridItem} ${styles.spanCol2}`}>
            <img
              src={
                galleries[1] && galleries[1][0]
                  ? galleries[1][0].path
                  : "/common/assets/png/empty.jpg"
              }
              alt={
                galleries[1] && galleries[1][0]
                  ? galleries[1][0].alt_image_me
                  : "No image available"
              }
            />
            <LinearGradient />
          </div>
          {galleries[2] && galleries[2][0]?.path && (
            <div className={`${styles.gridItem} ${styles.spanCol3}`}>
              <LinearGradient />

              <img
                src={
                  galleries[2] && galleries[2][0]
                    ? galleries[2][0].path
                    : "/common/assets/png/empty.jpg"
                }
                alt={
                  galleries[2] && galleries[2][0]
                    ? galleries[2][0].alt_image_me
                    : "No image available"
                }
              />

              <Button color={"white"} onClick={handleOpen}>
                View Galleries ({galleries.length}){" "}
              </Button>
              <CustomizedImageGalleryModal
                open={open}
                handleClose={handleClose}
                images={galleries}
              />
            </div>
          )}
        </div>
      ) : galleries.length === 2 ? (
        <div className={styles.twoGalleriesGrid}>
          <div className={`${styles.gridItem} ${styles.spanCol1}`}>
            <ImageIcons
              url={
                galleries[0] && galleries[0][0]
                  ? galleries[0][0].path
                  : "/common/assets/png/empty.jpg"
              }
            />
          </div>
          <div className={`${styles.gridItem} ${styles.spanCol2}`}>
            <img
              src={
                galleries[1] && galleries[1][0]
                  ? galleries[1][0].path
                  : "/common/assets/png/empty.jpg"
              }
              alt={
                galleries[1] && galleries[1][0]
                  ? galleries[1][0].alt_image_me
                  : "No image available"
              }
            />
            <LinearGradient />
            <Button color={"white"} onClick={handleOpen}>
              View Galleries ({galleries.length}){" "}
            </Button>
            <CustomizedImageGalleryModal
              open={open}
              handleClose={handleClose}
              images={galleries}
            />
          </div>
        </div>
      ) : galleries.length === 1 ? (
        <div className={styles.twoGalleriesGrid}>
          <div className={`${styles.gridItem} ${styles.spanCol1}`}>
            <ImageIcons
              url={
                galleries[0] && galleries[0][0]
                  ? galleries[0][0].path
                  : "/common/assets/png/empty.jpg"
              }
            />

            <LinearGradient />
            <Button color={"white"} onClick={handleOpen}>
              View Galleries ({galleries.length}){" "}
            </Button>
            <CustomizedImageGalleryModal
              open={open}
              handleClose={handleClose}
              images={galleries}
            />
          </div>
        </div>
      ) : (
        <div>There are no images for this Venue.</div>
      )}
    </SectionWrapper>
  );
}
