import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { miceAxios } from "../../axios/axios";

const initialState = {
  loading: false,
  eventTypes: [],
  error: null,
  success: false,
};

export const fetchEventTypes = createAsyncThunk(
  "eventTypes/fetchEventTypes",
  async () => {
    const response = await miceAxios.get("/events-that-can-be-organised");
    return response.data;
  }
);

const eventTypesSlice = createSlice({
  name: "eventTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.eventTypes = action.payload;
        state.success = true;
      })
      .addCase(fetchEventTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default eventTypesSlice.reducer;
