import React from "react";
import styles from "./CustomCheckbox.module.css";

export default function CustomCheckbox({ label, value, isChecked, onChange }) {
  return (
    <label className={styles.formControl}>
      <input
        type="checkbox"
        name="checkbox"
        value={value}
        className={styles.customCheckbox}
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChange(value, e.target.checked)}
      />
      <span onClick={(e) => e.stopPropagation()}>{label}</span>
    </label>
  );
}
