import styles from "./AmenitiesColumn.module.css";

export default function AmenitiesColumn({ title, data }) {
  return (
    <div className={styles.container}>
       <div style={{ fontWeight: "bold" }}>
         {title}
        </div>
      {data.map((item) => {
        return (
          <div key={item.id}>
            {item.title}
          </div>
        );
      })}
    </div>
  );
}
