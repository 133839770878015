import React from "react";
import styles from "./Button.module.css";

export default function Button({
  children,
  variant,
  color,
  homePage,
  ...rest
}) {
  const className = `${styles.button} ${styles[variant]} ${styles[color]} ${styles[homePage]}`;

  return (
    <button className={className} {...rest}>
      {children}
    </button>
  );
}
