import styles from "./IconContainer.module.css";

export default function IconContainer({ title, subtitle, iconPath }) {
  return (
    <div className={styles.iconContainer}>
      <img src={iconPath} alt="icon-img" className={styles.gray} />

      <div className={styles.textContainer}>
        <div>{title}</div>
        <div>{subtitle}</div>
      </div>
    </div>
  );
}
