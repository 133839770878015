import styles from "./AmenitiesSection.module.css";
import AmenitiesColumn from "./components/AmenitiesColumn/AmenitiesColumn";
import Title from "../../../../../../common/components/Title/Title";

export default function AmenitiesSection({amenitiesSections}) {
  return (
    <div className={styles.container}>
      <Title text="Amenities" color={"black"} fw={400} />
      <div className={styles.subContainer}>
        {amenitiesSections.map(amenitiesSection => (
        <AmenitiesColumn key={amenitiesSection.id} title={amenitiesSection.title} data={amenitiesSection.amenities} />
        ))}
      </div>
    </div>
  );
}
