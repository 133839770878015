import { updateSearchParameters } from "./workspace";

export const saveParams = (params) => async (dispatch) => {
  const updatedParams = {
    location: params.location,
    startDate: new Date(),
    endDate: new Date(),
    attendees: params.numOfPersons,
    checkBookingOnline: false,
    checkGetQuotes: false,
  };
  localStorage.setItem("searchParameters", JSON.stringify(updatedParams));
  dispatch(updateSearchParameters(updatedParams));
};
