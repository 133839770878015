import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
export default function MUICard({ blog }) {
  if (!blog) {
    return <div>Missing data</div>;
  }

  const formattedDate = new Date(blog.date).toLocaleString("en-US", {
    dateStyle: "long",
    timeStyle: "short",
  });

  return (
    <Link to={`/blog/${blog.id}`} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          maxWidth: 450,
          borderRadius: "0.4rem 0 0.4rem 0",
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100%"
            image={blog.card_image}
            alt={blog.meta_title_me}
            sx={{ objectFit: "cover", objectPosition: "center" }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {blog.title_me}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                fontWeight: "bold",
                letterSpacing: "0",
                fontSize: "1rem",
                marginTop: "-0.8rem",
              }}
            >
              {formattedDate}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ paddingTop: "1rem", paddingBottom: "3rem" }}
            >
              1 km away <br />
              {blog.short_description_me.slice(0, 100)}
              <span
                style={{
                  color: "#49559d",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Read more
              </span>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
