import styles from "./ToggleButtons.module.css";
import { useDispatch } from "react-redux";
import { setSelected } from "../../../redux/selected";

const ToggleButtons = ({ data }) => {
  const dispatch = useDispatch();

  const handleClick = (e, button) => {
    dispatch(setSelected(button.text));
    button.onClick();
  };
  return (
    <>
      {data.map((button, index) => (
        <button
          key={index}
          className={`${styles.toggleCardButton} ${
            button.state ? styles.clicked : ""
          }`}
          onClick={(event) => {
            handleClick(event, button);
          }}
        >
          {button.icon}
          <span>{button.text}</span>
        </button>
      ))}
    </>
  );
};

export default ToggleButtons;
