import styles from "./VenueTitle.module.css";
import Button from "../../../../../../common/components/Button/Button";

const VenueTitle = ({name, address, handleOpen}) => {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.titleSubContainer}>
        <div className={styles.title}>{name}</div>
        <div>{address}</div>
      </div>
        <Button color={"pink"} onClick={() => handleOpen()}> Send Inquiry</Button>
    </div>
  );
};

export default VenueTitle;
