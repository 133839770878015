import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  selected: "Venues",
};

export const selectedSlice = createSlice({
  name: "selected",
  initialState: initialStateValue,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setSelected } = selectedSlice.actions;

export default selectedSlice.reducer;
