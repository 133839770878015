import Button from "../../Button/Button";
import styles from "./StayBedroomsDropdown.module.css";
import Hr from "../../Hr/Hr";
import AttendeesBedroomsRow from "../NumberOfPersonsDropdown/NumberOfPersonsPartContainer/AttendeesBedroomsRow/AttendeesBedroomsRow";

export default function StayBedroomsDropdown({
  isDropdownOpen,
  handleDropdownClick,
  setIsDropdownOpen,
}) {
  return (
    <div
      className={`${styles.dropdownContainer} ${
        isDropdownOpen ? "" : styles.closed
      }`}
    >
      <AttendeesBedroomsRow type={"Bedrooms"} />
      <Hr />
      <div className={styles.buttons}>
        <div onClick={() => setIsDropdownOpen(false)}>
          <Button variant={"inverse"}>Cancel </Button>
        </div>
        <Button>Apply </Button>
      </div>
    </div>
  );
}
