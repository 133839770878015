import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickSlider.css";
import LinearGradient from "../LinearGradient/LinearGradient";

const imgStyle = {
  width: "100%",
  height: "400px",
  maxWidth: "100%",
  borderRadius: "1rem 0 1rem 0",
  objectFit: "cover",
};

const iframeStyle = {
  width: "100%",
  height: "400px",
  maxWidth: "100%",
  borderRadius: "1rem 0 1rem 0",
};

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(0,0,0,0.3)",
        borderRadius: "55%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
      }}
      onClick={onClick}
    />
  );
}

const SlickSlider = ({ content, type }) => {
  const settings = {
    dots: true,
    infinite: true,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    centerMode: true,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slick-slider-container" style={{ height: "500px" }}>
      <Slider {...settings}>
        {content.map((item, index) => (
          <div key={item.id} style={{ height: "100%" }}>
            {type === "image" ? (
              <div className={"image-container-div"}>
                <img
                  src={item.image}
                  alt={item.alt_text || "slide-content"}
                  style={imgStyle}
                />
                <LinearGradient />
              </div>
            ) : (
              <iframe
                title={item.description || "video-content"}
                src={item.video_url + "?autoplay=0"}
                style={iframeStyle}
                frameBorder="0"
                //allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider;
