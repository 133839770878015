import React from "react";
import FlexItemsContainer from "../../components/FlexItemsContainer/FlexItemsContainer";
import Title from "../../../../../../common/components/Title/Title";
import styles from "./TableSection.module.css";

export default function TableSection({ text, data }) {
  return (
    <div className={styles.container}>
      <Title text={text} fw={400} color={"black"} />
      <FlexItemsContainer data={data} />
    </div>
  );
}
