import styles from "./FlexItemsContainer.module.css";
import FlexItem from "../FlexItem/FlexItem";

export default function FlexItemsContainer({ data }) {
  return (
    <div className={styles.container}>
      {data.map((item, index) => {
        return (
          <FlexItem
            icon={item.icon}
            altText={item.icon_alt_text}
            title={item.title}
            num={item.size}
            bgClr={index % 2 === 0 ? "gray" : ""}
            key={item.id}
          />
        );
      })}
    </div>
  );
}
