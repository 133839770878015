import styles from "./WhatsApp.module.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function WhatsApp() {
  return (
    <div className={styles.whatsAppWrapper}>
      <a href="https://api.whatsapp.com/send?phone=38269000000" target="_blank" rel="noreferrer">
      <WhatsAppIcon
        style={{
          color: "white",
          position: "absolute",
          zIndex: "1",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "35px",
        }}
      />
      </a>
    </div>
  );
}
