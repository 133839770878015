import React from "react";
import styles from "../styles/AuthStyles.module.css";

const AuthCard = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>{children}</div>
    </div>
  );
};

export default AuthCard;
