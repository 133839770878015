// SlideContent.js
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./SlideContent.css";
function SlideContent({ title, text, name, subtitle }) {
  return (
    <div className="slide-bg">
      <div>
        <ChevronLeftIcon
          style={{ fontSize: "4rem", color: "var(--clr-gray-500)" }}
        />
      </div>
      <div className="carousel-title">{title}</div>
      <div className="carousel-text">{text}</div>
      <div>
        <div className="carousel-name">{name}</div>
        <div className="carousel-subtitle">{subtitle}</div>
      </div>
    </div>
  );
}

export default SlideContent;
