import React from "react";

function Links({ socialMedia }) {
  const iconStyle = {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      {socialMedia.map((social) => (
        <a
          target="_blank"
          href={social.link}
          rel="noreferrer"
          style={{ color: "black" }}
        >
          <div style={{ ...iconStyle }}>
            <img
              src={social.icon}
              alt={social.icon_alt_text}
              style={{ cursor: "pointer" }}
              width={30}
              height={30}
            />
            <div>{social.title}</div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Links;
