import * as React from "react";
import TextField from "@mui/material/TextField";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function ControlledComponent() {
  const [value, setValue] = React.useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["DatePicker"]}
        sx={{
          "& .MuiStack-root": {
            width: "100% !important",
          },
        }}
      >
        <DatePicker
          value={value}
          onChange={(newValue) => setValue(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth={true}
              variant="filled"
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "var(--clr-purple)",
                  },
                backgroundColor: "#dde2fb",
                "& .MuiStack-root": {
                  width: "100% !important",
                },
              }}
            />
          )}
          sx={{
            backgroundColor: "#dde2fb",
            border: "none",
            width: "100%",
            "& .MuiStack-root": {
              width: "100% !important",
            },
            "& .MuiFormControl-root.MuiTextField-root": {
              width: "100% !important",
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
