import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { miceAxios } from "../../axios/axios";

const initialState = {
  venue: null,
  leftVenue: null,
  rightVenue: null,
  unit: {
    data: null,
    status: "idle",
    error: null,
  },
  amenitiesSections: [],
  typeOfGuestRoom: [],
  distance: null,
  venueInquiries: null,
  status: "idle",
  error: null,
};

const fetchVenueById = createAsyncThunk("venue/fetchVenueById", async (id) => {
  const response = await miceAxios.get(`/venues/${id}`);
  return response.data;
});

const fetchVenueBySlug = createAsyncThunk(
  "venue/fetchVenueBySlug",
  async (slug) => {
    const response = await miceAxios.get(`/venues/search?slug=${slug}`);
    const [venueData] = response.data;
    //console.log(venueData);
    return venueData;
  }
);

const fetchVenueUnitById = createAsyncThunk(
  "venue/fetchVenueUnitById",
  async (id) => {
    const response = await miceAxios.get(`/venue-units/${id}`);
    return response.data;
  }
);

const fetchLeftVenue = createAsyncThunk(
  "venue/fetchLeftVenue",
  async (slug) => {
    // const response = await miceAxios.get(`/venues/${id}`);
    // return response.data;
    const response = await miceAxios.get(`/venues/search?slug=${slug}`);
    return response.data;
  }
);

const fetchRightVenue = createAsyncThunk(
  "venue/fetchRightVenue",
  async (id) => {
    const response = await miceAxios.get(`/venues/${id}`);
    return response.data;
  }
);

const fetchTypeOfGuestRoom = createAsyncThunk(
  "venue/typeOfGuestRoom",
  async (id) => {
    const response = await miceAxios.get(`/type-of-guest-room/${id}`);
    return response.data;
  }
);

const fetchDistance = createAsyncThunk("venue/fetchDistance", async (id) => {
  const response = await miceAxios.get(`/distance-section/${id}`);
  return response.data;
});

const fetchUnitAmenitiesById = createAsyncThunk(
  "venue/fetchUnitAmenitiesById",
  async (id) => {
    const response = await miceAxios.get(`/venue-unit-amenity-section/${id}`);
    return response.data;
  }
);

const venueInquiries = createAsyncThunk(
  "venue/venueInquiries",
  async (form) => {
    console.log(form);
    const response = await miceAxios.post(`/venue-inquiries`, form);
    console.log("venue inquiries ", response.data);
    return response.data;
  }
);

const venueSlice = createSlice({
  name: "venue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVenueById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVenueById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.venue = action.payload.data;
      })
      .addCase(fetchVenueById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchVenueBySlug.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVenueBySlug.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.venue = action.payload;
      })
      .addCase(fetchVenueBySlug.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLeftVenue.fulfilled, (state, action) => {
        state.leftVenue = action.payload;
      })
      .addCase(fetchRightVenue.fulfilled, (state, action) => {
        state.rightVenue = action.payload;
      })
      .addCase(fetchVenueUnitById.pending, (state) => {
        state.unit.status = "loading";
      })
      .addCase(fetchVenueUnitById.fulfilled, (state, action) => {
        state.unit.status = "succeeded";
        state.unit.data = action.payload;
      })
      .addCase(fetchVenueUnitById.rejected, (state, action) => {
        state.unit.status = "failed";
        state.unit.error = action.error.message;
      })
      .addCase(fetchDistance.fulfilled, (state, action) => {
        state.distance = action.payload;
      })
      .addCase(fetchTypeOfGuestRoom.fulfilled, (state, action) => {
        const existingObjectIndex = state.typeOfGuestRoom.findIndex(
          (item) => item.pivot.room_id === action.payload.id
        );
        if (existingObjectIndex === -1) {
          state.typeOfGuestRoom.push(action.payload);
        }
      })
      .addCase(fetchUnitAmenitiesById.fulfilled, (state, action) => {
        const existingObjectIndex = state.amenitiesSections.findIndex(
          (item) => item.id === action.payload.id
        );

        if (existingObjectIndex === -1) {
          state.amenitiesSections.push(action.payload);
        }
      })
      .addCase(venueInquiries.fulfilled, (state, action) => {
        state.venueInquiries = action.payload;
        toast.success("Inquiry successfully sent"); // change with toast
      });
  },
});

export default venueSlice.reducer;
export {
  fetchVenueById,
  fetchLeftVenue,
  fetchRightVenue,
  fetchVenueUnitById,
  fetchTypeOfGuestRoom,
  fetchUnitAmenitiesById,
  fetchDistance,
  venueInquiries,
  fetchVenueBySlug,
};
