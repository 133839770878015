import styles from "./EighthSection.module.css";
import NeedHelp from "../../../../../../common/components/NeedHelp/NeedHelp";
import React from "react";
import { ReactComponent as Email } from "../../../../../../common/assets/svg-mice/email.svg";
import { ReactComponent as AttachFile } from "../../../../../../common/assets/svg-mice/share-pp.svg";
import { ReactComponent as Facebook } from "../../../../../../common/assets/svg-mice/facebook.svg";
import { ReactComponent as Twitter } from "../../../../../../common/assets/svg-mice/twitter.svg";
import { ReactComponent as Vkontakt } from "../../../../../../common/assets/svg-mice/vkontant.svg";
import { ReactComponent as Weibo } from "../../../../../../common/assets/svg-mice/weibo.svg";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";

let currentUrl = window.location.href;

export const handleCopyUrl = () => {
  const tempInput = document.createElement("input");
  tempInput.value = currentUrl;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  alert("URL copied to clipboard");
};

const shareOptions = [
  {
    icon: Email,
    backgroundColor: "#f7adc2",
    text: "E-mail",
    url: `mailto:?subject=Check%20out%20this%20website&body=Here%20is%20the%20link%20to%20the%20website:%20${currentUrl}`,
  },
  {
    icon: AttachFile,
    backgroundColor: "#eba708",
    text: "Copy URL",
    onClick: handleCopyUrl,
  },
  {
    icon: Facebook,
    backgroundColor: "#2968cc",
    text: "Facebook",
    url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
  },
  {
    icon: Twitter,
    backgroundColor: "#1aa5f1",
    text: "Twitter",
    url: `https://twitter.com/intent/tweet?url=${currentUrl}`,
  },
  {
    icon: Vkontakt,
    backgroundColor: "#0077ff",
    text: "Vkontant",
    url: `http://vk.com/share.php?url=${currentUrl}`,
  },
  {
    icon: Weibo,
    backgroundColor: "#df152c",
    text: "Weibo",
    url: `http://service.weibo.com/share/share.php?url=${currentUrl}`,
  },
];

export default function EighthSection() {
  const iconStyle = {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const renderShareOption = (option) => (
    <>
      <a
        target="_blank"
        href={option.url}
        onClick={() => option.onClick && option.onClick()}
        rel="noreferrer"
      >
        <div>{React.createElement(option.icon)}</div>
      </a>
      <div>{option.text}</div>
      {option.text !== "Weibo" && <div className={styles.iconsLine}></div>}
    </>
  );

  return (
    <SectionWrapper variant={"alignItemsFlexStart"}>
      <NeedHelp />
      <div className={styles.shareWithContainer}>
        <div className={styles.text}>Share with your colleagues</div>
        <div className={styles.shareWithIconsContainer}>
          {shareOptions.map((option, index) => (
            <React.Fragment key={index}>
              {renderShareOption(option)}
            </React.Fragment>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}
