import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinearGradient from "../LinearGradient/LinearGradient";
import styles from "./ImageIcons.module.css";
import { handleCopyUrl } from "../../../features/search/pages/PreviewPage/sections/EighthSection/EighthSection";

const ImageIcons = ({ url }) => {
  const iconsStyle = {
    color: "white",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
  };

  let currentUrl = window.location.href;

  const shareOptions = [
    { icon: AttachFileIcon, onClick: handleCopyUrl  },
    { icon: EmailIcon, url: `mailto:?subject=Check%20out%20this%20venue&body=Here%20is%20the%20link%20to%20the%20website:%20${currentUrl}`},
    { icon: LinkedInIcon,  url: `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`},
    { icon: FacebookIcon, url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}` },
    { icon: TwitterIcon, url: `https://twitter.com/intent/tweet?url=${currentUrl}` },
  ];

  return (
    <>
      <div className={styles.iconsContainer}>
      {shareOptions.map((option, index) => (
         <a key={index} target="_blank" href={option.url} onClick={() => option.onClick && option.onClick()} rel="noreferrer">
          <option.icon style={iconsStyle}/>
        </a>
      ))}
      </div>
      <LinearGradient />
      <img src={url} alt="blog" className={styles.iconsImage} />
    </>
  );
};

export default ImageIcons;
