import styles from "./InputContainer.module.css";
import FilledTextField from "../../../../common/components/FormComponents/FilledTextField";

const InputContainer = ({ label, col }) => {
  const containerClassName = `${styles.container} ${col ? styles.col : ""}`;

  return (
    <div className={containerClassName}>
      <div className={styles.label}>{label}</div>
      <FilledTextField />
    </div>
  );
};

export default InputContainer;
