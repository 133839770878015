import React, { useState } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import styles from "./AttendeesBedroomsRow.module.css";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { useDispatch, useSelector } from "react-redux";
//import { updateVenuesSearchParameters } from "../../../../../../redux/venues/venue";

const removeIconStyle = {
  fontSize: "2rem",
  color: "var(--clr-gray-300)",
  cursor: "pointer",
};

const addIconStyle = {
  fontSize: "2rem",
  color: "var(--clr-purple)",
  cursor: "pointer",
};

const iconStyle = { fontSize: "1rem", color: "var(--clr-gray-800" };

const AttendeesBedroomsRow = ({ type }) => {
  const dispatch = useDispatch();

  const venuesSearchParameters = useSelector(
    (state) => state.venues.venuesSearchParameters
  );

  const [attendee, setAttendee] = useState(
    //venuesSearchParameters.attendeeCount
    0
  );
  const [bedroom, setBedroom] = useState(
    // venuesSearchParameters.bedroomCount
    //   ? venuesSearchParameters.bedroomCount
    //   : 0
    0
  );

  const handleMinus = () => {
    if (type === "Attendees") {
      const newValue = Math.max(0, attendee - 1);
      setAttendee(newValue);
      // dispatch(
      //   updateVenuesSearchParameters({
      //     ...venuesSearchParameters,
      //     attendeeCount: newValue,
      //   })
      // );
    } else {
      const newValue = Math.max(0, bedroom - 1);
      setBedroom(newValue);
      // dispatch(
      //   updateVenuesSearchParameters({
      //     ...venuesSearchParameters,
      //     bedroomCount: newValue,
      //   })
      // );
    }
  };

  const handlePlus = () => {
    if (type === "Attendees") {
      const newValue = +attendee + 1;
      setAttendee(newValue);
      // dispatch(
      //   updateVenuesSearchParameters({
      //     ...venuesSearchParameters,
      //     attendeeCount: newValue,
      //   })
      // );
    } else {
      const newValue = bedroom + 1;
      setBedroom(newValue);
      // dispatch(
      //   updateVenuesSearchParameters({
      //     ...venuesSearchParameters,
      //     bedroomCount: newValue,
      //   })
      // );
    }
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      if (type === "Attendees") {
        setAttendee(value);
        // dispatch(
        //   updateVenuesSearchParameters({
        //     ...venuesSearchParameters,
        //     attendeeCount: value,
        //   })
        // );
      } else {
        setBedroom(value);
        // dispatch(
        //   updateVenuesSearchParameters({
        //     ...venuesSearchParameters,
        //     bedroomCount: value,
        //   })
        // );
      }
    }
  };

  return (
    <div className={styles.rowMainContainer}>
      <div className={styles.rowSubContainer}>
        {type === "Attendees" && <PeopleAltOutlinedIcon sx={iconStyle} />}
        {type === "Bedrooms" && <BedOutlinedIcon sx={iconStyle} />}
        <span> {type} </span>
      </div>
      <div className={styles.rowSubContainer}>
        <RemoveCircleOutlineOutlinedIcon
          sx={removeIconStyle}
          onClick={handleMinus}
        />
        <input
          type="text"
          value={type === "Attendees" ? attendee : bedroom}
          onChange={handleInputChange}
          className={styles.inputStyle} // You can style this input to match your design
          min="0"
        />
        <ControlPointOutlinedIcon sx={addIconStyle} onClick={handlePlus} />
      </div>
    </div>
  );
};

export default AttendeesBedroomsRow;
