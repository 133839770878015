import styles from "./BackToTop.module.css";

import WhatsApp from "../WhatsApp/WhatsApp";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { useEffect, useState } from "react";
function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={`${styles.backToTop} ${styles.rightSection}`}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <WhatsApp />
      <button
        className={styles.backToTopButton}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <VerticalAlignTopIcon style={{ color: "var(--clr-pink)" }} />
        BACK TO THE TOP
      </button>
    </div>
  );
}

export default BackToTop;
