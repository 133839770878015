import SearchTypeInfo from "../SearchTypeInfo/SearchTypeInfo";
import FinalSearchTab from "../../../../components/FinalSearchTab/FinalSearchTab";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import shapePng from "../../../../../../common/assets/png/home-slider/Shape.png";
import axios from "axios";
import "./MainSection.css";
import React, { useState, useEffect } from "react";
import PopUpInfo from "../../../../../../common/components/Header/PopUpInfo/PopUpInfo";
import ModalIcon from "../../../../../../common/components/ModalIcon/ModalIcon";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useHideScroll from "../../../../../../hooks/useHideScroll";

const MainSection = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  useHideScroll(open);

  const handleClose = () => setOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1400
  );
  useHideScroll(isOpen);
  const openModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const searchType = useSelector((state) => state.selected.selected);
  const [sliderImages, setSliderImages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MICE_API}/get-slider-images`
        );

        const filtered = response.data
          .filter((venue) => venue.is_public)
          .sort((a, b) => a.slider_order - b.slider_order);
        setSliderImages(filtered);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={"mainSection"}>
      {open && (
        <div className={`mainSectionModalCardContainer ${open ? "open" : ""}`}>
          <CloseOutlinedIcon
            sx={{
              color: "var(--clr-purple)",
              alignSelf: "flex-end",
              cursor: "pointer",
              margin: "0 10px",
            }}
            onClick={handleClose}
          />
          <SearchTypeInfo type={searchType} />
          <FinalSearchTab display={"mobile"} />
        </div>
      )}

      {screenWidth > 1250 ? (
        <>
          <div className={"mainSectionLeftPart"}>
            <PopUpInfo isOpen={isOpen} setIsOpen={setIsOpen} />
            <ModalIcon openModal={openModal} />
          </div>
          <main className={"mainSectionMain"}>
            <SearchTypeInfo type={searchType} />
            <FinalSearchTab />
          </main>
          <aside
            className={
              screenWidth < 1250 ? "sliderDots centerAside" : "sliderDots"
            }
          >
            <Carousel
              autoPlay={true}
              infiniteLoop={false}
              interval={5000}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              //renderArrowNext={customNextArrow}
              //renderArrowPrev={customPrevArrow("175px")}
            >
              {sliderImages.map((image, index) => {
                return (
                  <Link
                    to={`/venues/${image.slug}`}
                    target="_blank"
                    key={image.id}
                  >
                    <div
                      key={index}
                      style={{ height: "100%", position: "relative" }}
                    >
                      <img
                        src={shapePng}
                        alt={image.alt_text}
                        style={{
                          position: "absolute",
                          maxWidth: "200px",
                          height: "110%",
                          left: "-15px",
                          top: "-10px",
                        }}
                      />
                      <div className={"sliderVenueTextContainer"}>
                        <div className={"sliderVenueName"}>{image.name}</div>
                        <div className={"sliderVenueName location"}>
                          {image.cities.length > 0
                            ? image.cities.map((city, index) => (
                                <React.Fragment key={city.id}>
                                  {city.name}
                                  {index < image.cities.length - 1 ? ", " : ""}
                                </React.Fragment>
                              ))
                            : "City"}
                        </div>
                      </div>

                      <img
                        src={image.featured_image}
                        alt={image.name}
                        style={{ height: "100%" }}
                      />
                    </div>
                  </Link>
                );
              })}
            </Carousel>
          </aside>
        </>
      ) : (
        <div className={"responsiveContainer"}>
          <button className={"mainSectionButton"} onClick={handleOpen}>
            Start your Search
          </button>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            showStatus={false}
            // renderArrowNext={customNextArrow}
            // renderArrowPrev={customPrevArrow("25px")}
            showArrows={false}
          >
            {sliderImages.map((image, item) => {
              return (
                <Link
                  to={`/venues/${image.slug}`}
                  target="_blank"
                  key={image.id}
                >
                  <div key={item} className={"mainSectionImageContainer"}>
                    <div className={"sliderVenueTextContainer mobile"}>
                      <div className={"sliderVenueName"}>{image.name}</div>
                      <div className={"sliderVenueName location"}>
                        Podgorica, MNE
                      </div>
                    </div>
                    <img src={image.featured_image} alt={image.name} />
                  </div>
                </Link>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default MainSection;
