import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const FormSubmitButton = () => {
  return (
    <Button
      variant="outlined"
      color="primary"
      type="submit"
      sx={{
        color: "var(--clr-pink)",
        borderColor: "var(--clr-pink)",
        paddingY: "1rem",
        "&:hover": {
          color: "var(--clr-pink)",
          borderColor: "var(--clr-pink)",
          backgroundColor: "transparent",
          animation: "scaleAnimation 0.5s",
        },
        "&:focus": {
          color: "var(--clr-pink)",
          borderColor: "var(--clr-pink)",
        },
        "@keyframes scaleAnimation": {
          "0%": {
            transform: "scale(1)",
          },
          "100%": {
            transform: "scale(1.01)",
          },
        },
      }}
    >
      <KeyboardDoubleArrowRightIcon /> Apply for Newsletter
    </Button>
  );
};
export default FormSubmitButton;
