//import { updateVenuesSearchParameters } from "./venue.js";

export const saveVenuesParams = (params) => async (dispatch) => {
  const updatedParams = {
    cities: params.cities || [],
    total_number_of_people: params.total_number_of_people || 0,
    events: params.events || [],
    total_guest_rooms: params.total_guest_rooms || 0,
    total_number_of_conference_rooms:
      params.total_number_of_conference_rooms || 0,
    venue_categories: params.venue_categories || [],
    amenities_lists: params.amenities_lists || [],
  };

  // Save to localStorage
  localStorage.setItem("venuesSearchParams", JSON.stringify(updatedParams));

  // If you need to update the Redux store with the updated parameters:
  // dispatch(updateVenuesSearchParameters(updatedParams));
};
