import styles from "./BlogListView.module.css";
import MUICard from "../../../../../common/components/MUIComponents/MUICard";
import LinearIndeterminate from "../../../../../common/components/MUIComponents/LinearIndeterminate";
import { useSelector } from "react-redux";
import Hr from "../../../../../common/components/Hr/Hr";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import * as React from "react";

export default function BlogListView({ wid }) {
  const [blogsPerPage, setBlogsPerPage] = useState(3);

  const blogs = useSelector((state) => state.blogs.blogs);
  const loading = useSelector((state) => state.blogs.loading);
  const error = useSelector((state) => state.blogs.error);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const updateBlogsPerPage = () => {
      if (window.innerWidth <= 600) {
        setBlogsPerPage(1);
      } else if (window.innerWidth <= 900) {
        setBlogsPerPage(2);
      } else {
        setBlogsPerPage(3);
      }
    };

    updateBlogsPerPage();
    window.addEventListener("resize", updateBlogsPerPage);

    return () => {
      window.removeEventListener("resize", updateBlogsPerPage);
    };
  }, []);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  if (loading) {
    return <LinearIndeterminate />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const BasicPagination = () => {
    return (
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    );
  };

  const startIndex = (page - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const blogsToDisplay = blogs.slice(startIndex, endIndex);
  const blogClassContainer = wid
    ? `${styles.blogContainer} ${styles.smaller}`
    : styles.blogContainer;
  return (
    <div className={blogClassContainer}>
      <div className={styles.upperContainer}>
        <div className={styles.blogTitle}>Blogs</div>
        <div></div>
      </div>
      <Hr />
      <div className={styles.gridContainer}>
        {Array.isArray(blogsToDisplay) &&
          blogsToDisplay.map((blog) => (
            <MUICard key={blog.id} blog={blog} linkTo={`/blog/${blog.id}`} />
          ))}
      </div>
      <BasicPagination />
    </div>
  );
}
