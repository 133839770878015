import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import { CardActionArea, CardActions } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import { useDispatch } from "react-redux";
import { removeVenueFromShortlist } from "../../../../../../redux/slices/shortlistSlice";
import EventForm from "../../../../../../pages/EventForm/EventForm";
export default function CustomCard({
  deleteProp,
  id,
  url,
  title,
  subtitle,
  venue,
  floorPlanImage,
  handleOpen,
  button1Text,
  button2Text,
  button3Text,
  sendInquiry,
}) {
  // console.log("custom card: ", venue);
  const [open, setOpen] = useState(false);
  const [openEventForm, setOpenEventForm] = useState(false);

  const handleOpenEventForm = () => {
    setOpenEventForm(!openEventForm);
  };

  const dispatch = useDispatch();
  const handleRemoveFromShortlist = () => {
    dispatch(removeVenueFromShortlist(venue));
    setOpen(false);
  };
  const events = venue?.events?.map((event) => event.title);
  // console.log(events);

  const handleSendInquiry = () => {
    console.log("Test");
  };

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="220"
          image={url}
          alt="green iguana"
        />
        <CardContent>
          <Link
            to={`/venues/preview/${venue?.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ textDecoration: "none" }}
            >
              {title}
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ textDecoration: "none" }}
          >
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        {button1Text && (
          <Button
            onClick={() => handleOpen(id)}
            size="small"
            sx={{
              color: "var(--clr-purple)",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            {button1Text}
          </Button>
        )}

        {button2Text && (
          <Link to={`./${id}`}>
            <Button
              size="small"
              sx={{
                color: "var(--clr-purple)",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              {button2Text}
            </Button>
          </Link>
        )}
        {button3Text && (
          <a href={floorPlanImage} target="_blank" rel="noreferrer">
            <Button
              size="small"
              sx={{
                color: "var(--clr-purple)",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              {button3Text}
            </Button>
          </a>
        )}
        {deleteProp && (
          <>
            <div>
              <DeleteIcon
                sx={{ color: "var(--clr-purple)", cursor: "pointer" }}
                onClick={() => setOpen(true)}
              />
              {sendInquiry && (
                <Button
                  onClick={handleOpenEventForm}
                  sx={{
                    textTransformation: "none",
                    color: "var(--clr-purple)",
                  }}
                >
                  Send Inquiry
                </Button>
              )}
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {
                    "Are you sure you want to delete this venue from your shortlist?"
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {""}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>No</Button>
                  <Button onClick={handleRemoveFromShortlist} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <EventForm
              open={openEventForm}
              handleOpen={handleOpenEventForm}
              venueId={venue.id}
              events={events}
            />
          </>
        )}
      </CardActions>
    </Card>
  );
}
