import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./MuiDateRangePicker.module.css";
import dayjs from "dayjs";

function MUIDateRangePicker({ arrive, depart, setForm }) {
  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    setForm((prev) => ({ ...prev, arrive_on: formattedDate }));
    validateDateRange(date, depart);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    setForm((prev) => ({ ...prev, depart_on: formattedDate }));
    validateDateRange(arrive, date);
  };

  const validateDateRange = (start, end) => {
    if (start && end && start > end) {
      toast.error("End date cannot be before start date.");
    }
  };

  const disableDates = (date) => {
    return arrive ? date < arrive : false;
  };

  return (
    <div className={`${styles.muiDateRangePicker} column`}>
      {/*<ToastContainer position="top-center" autoClose={3000} />*/}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="Start Date"
            value={dayjs(arrive)}
            onChange={handleStartDateChange}
            renderInput={(props) => (
              <TextField
                {...props}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "var(--clr-pink)",
                    },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "var(--clr-pink)",
                  },
                }}
              />
            )}
          />
        </DemoContainer>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="End Date"
            value={dayjs(depart)}
            onChange={handleEndDateChange}
            renderInput={(props) => <TextField {...props} />}
            shouldDisableDate={disableDates}
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}

export default MUIDateRangePicker;
