import styles from "./ModalIcon.module.css";

export default function ModalIcon({ openModal }) {
  return (
    <div className={styles.leftHeaderContainer}>
      <button onClick={openModal} className={styles.iButton}>
        i
      </button>
      <div className={styles.learnHowContainer}>
        Learn how the Event Supplier <br /> Network works
      </div>
    </div>
  );
}
