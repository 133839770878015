import "./customizedVideoGallery.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
const renderVideo = (item) => {
  return (
    <div className="video-wrapper">
      <iframe
        width="100%"
        height="480px"
        src={item.embedUrl}
        frameBorder="0"
        allowFullScreen
        title="ex"
      />
    </div>
  );
};

const CustomLeftArrow = (props) => {
  const handleClick = () => {
    props.onClick();
  };
  return (
    <button className="custom-arrow custom-left-arrow" onClick={handleClick}>
      <KeyboardArrowLeftIcon style={{ color: "white" }} fontSize="large" />
    </button>
  );
};

const CustomRightArrow = (props) => {
  return (
    <button className="custom-arrow custom-right-arrow" onClick={props.onClick}>
      <KeyboardArrowRightIcon style={{ color: "white" }} fontSize="large" />
    </button>
  );
};

const CustomizedVideoGallery = ({ handleClose, activeVideoIndex, videos }) => {
  const formatVideos = videos.map(video => {
    return {
      embedUrl: video.video_url,
      thumbnail: video.thumbnail_image,
      renderItem: renderVideo.bind(this),
    }
    
  })
  return (
    <div className={"customized-video-gallery-container"}>
      <CloseIcon
        onClick={handleClose}
        sx={{
          color: "white",
          zIndex: "200",
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      />

      <ImageGallery
        items={formatVideos}
        startIndex={activeVideoIndex} 
        renderItem={renderVideo}
        showFullscreenButton={false}
        showPlayButton={false}
        renderLeftNav={(onClick) => <CustomLeftArrow onClick={onClick} />}
        renderRightNav={(onClick) => (
          <CustomRightArrow onClick={onClick} showPlayButton />
        )}
      />
    </div>
  );
};

export default CustomizedVideoGallery;
