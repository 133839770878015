import styles from "./Shortlisted.module.css";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ShortlistedVenueCard from "./ShortlistedVenueCard/ShortlistedVenueCard";
import Hr from "../Hr/Hr";
import Button from "../Button/Button";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import EventForm from "../../../pages/EventForm/EventForm";
import { useState } from "react";

const Shortlisted = ({ closeDrawer }) => {
  const shortlistedVenues = useSelector((state) => state.shortlist);
  const shortlistedVenueIds = shortlistedVenues.map((venue) => venue.id);
  console.log(shortlistedVenueIds);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const sendInquiry = () => {
    if (shortlistedVenueIds.length) handleOpen();
    else {
    }
    // closeDrawer();
  };
  const eventTypes = useSelector((state) => state.eventTypes.eventTypes);
  return (
    <div className={styles.shortlistedContainer}>
      <div className={styles.shortlistedTitleContainer}>
        <div className={styles.shortlistedTitle}>
          <ControlPointOutlinedIcon style={{ fontSize: "1.7rem" }} />
          <span>Add up to 10 venues</span>
        </div>
        <CloseOutlinedIcon
          style={{ cursor: "pointer" }}
          onClick={closeDrawer}
        />
      </div>
      <Hr />

      <div className={styles.venuesCardsContainer}>
        {shortlistedVenues.length === 0 ? (
          <div className={styles.noShortlistVenues}>
            Currently there are no venues in your Shortlist.{" "}
          </div>
        ) : (
          <>
            {shortlistedVenues.length > 0 &&
              shortlistedVenues.map((venue) => {
                return <ShortlistedVenueCard venue={venue} key={venue.id} />;
              })}
          </>
        )}
        <Hr />
        {shortlistedVenues.length === 10 && (
          <div className={styles.maxMsg}>
            {" "}
            You have added a maximum of 10 venues
          </div>
        )}
      </div>

      <div className={styles.shortlistedBottomContainer}>
        <Button color={"pink"} onClick={closeDrawer}>
          Add Venues{" "}
        </Button>

        <Button color={"gray"} variant={"outlined"} onClick={sendInquiry}>
          Send Inquiry
        </Button>
      </div>
      <EventForm
        venueIDS={shortlistedVenueIds}
        open={open}
        handleOpen={handleOpen}
        events={eventTypes}
      />
    </div>
  );
};

export default Shortlisted;
