import Badge from "@mui/material/Badge";
import React from "react";

const MUIBadge = ({ children, value }) => {
  return (
    <Badge
      badgeContent={value}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: "var(--clr-pink)",
          color: "white",
        },
      }}
    >
      {children}
    </Badge>
  );
};

export default MUIBadge;
