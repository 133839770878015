import styles from "./ListItemsSection.module.css";
import ListItem from "./ListItem/ListItem";
import Title from "../../../../../../common/components/Title/Title";
import Hr from "../../../../../../common/components/Hr/Hr";
import React from "react";
const ListItemsSection = React.forwardRef(
  ({ items, handleOpen, activeTab }, ref) => {
    // const filteredVenues = items.filter((item) => item.is_public === 1);
    const title =
      items.length === 1
        ? "1 search result."
        : `${items.length} search results.`;

    return (
      <section ref={ref} style={{ width: "100%", flex: "4" }}>
        <Title color={"black"} text={title} fw={700} />
        <Hr />
        <div className={styles.listItemsContainer}>
          {items.map((item) => {
            return (
              <div key={item.id} style={{ display: "contents" }}>
                <ListItem
                  item={item}
                  handleOpen={handleOpen}
                  activeTab={activeTab}
                />
                <Hr />
              </div>
            );
          })}
        </div>
      </section>
    );
  }
);

export default ListItemsSection;
