import React from "react";
import styles from "./BlogDescription.module.css";

const BlogDescription = ({ text, isTextExpanded, toggleTextExpanded }) => {
  return text ? (
    <div className={styles.longDescription}>
      <p>{isTextExpanded ? text : `${text.substring(0, 1100)}...`}</p>
      <button onClick={toggleTextExpanded}>
        {isTextExpanded ? "Show Less" : "Read More"}
      </button>
    </div>
  ) : (
    <p>No description for this blog.</p>
  );
};

export default BlogDescription;
