import PageWrapper from "../../common/components/PageWrapper/PageWrapper";
import Title from "../../common/components/Title/Title";
import FormContainer from "./components/FormContainer/FormContainer";
import styles from "./ContactDetails.module.css";
import InputContainer from "./components/InputContainer/InputContainer";
import RowRadioButtonsGroup from "../../common/components/FormComponents/RadioGroup";
import Hr from "../../common/components/Hr/Hr";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import ControlledComponent from "../../common/components/MUIComponents/MUIDatePicker";
import Button from "../../common/components/Button/Button";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";

const data = [1, 2, 3];

const Grid = () => {
  return (
    <div className={styles.gridContainer}>
      <RowRadioButtonsGroup
        label={"Are you planning this event for your organization?"}
        options={[
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ]}
      />
      <InputContainer col={true} label={"First Name"} />
      <InputContainer col={true} label={"Last Name"} />
      <InputContainer col={true} label={"Email"} />
      <InputContainer col={true} label={"Phone"} />
      <InputContainer col={true} label={"City"} />
      <InputContainer col={true} label={"Country/Region"} />
    </div>
  );
};

const NoTitleForm = () => {
  return (
    <div className={styles.noTitleForm}>
      <InputContainer col={true} label={"Organisation"} />
      <div></div>
      <InputContainer col={true} label={"Organisation type"} />
      <InputContainer col={true} label={"Industry"} />
    </div>
  );
};

const Responses = () => {
  return (
    <div className={styles.responsesContainer}>
      {/*<InputContainer label={"When do you need to hear from us?"} col={true} />*/}
      <ControlledComponent />
      <InputContainer label={"How do you prefer to be contacted?"} col={true} />
    </div>
  );
};

const Sharing = () => {
  return (
    <div className={styles.sharing}>
      <Checkbox
        color="primary"
        sx={{
          "&.MuiCheckbox-colorPrimary.Mui-checked": {
            color: "var(--clr-purple)",
          },
        }}
      />
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        sx={{ fontSize: "1.2rem" }}
      >
        By checking this box, I agree to share personal information provided on
        this RPF to the selected hotels. To learn more, read{" "}
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: "var(--clr-purple)",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          Privacy Policy
        </Typography>{" "}
        and{" "}
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: "var(--clr-purple)",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          Terms of Use.
        </Typography>
      </Typography>
    </div>
  );
};

const ContactDetails = () => {
  return (
    <PageWrapper>
      <Hr />
      <div className={styles.textContainer}>
        <Title fw={"600"} text={"CONTACT DETAILS"} />
        <div className={styles.subtitle}>
          Share with us your contact details, so we can respond to your request.{" "}
        </div>
      </div>

      <div className={styles.contactDetailsFrom}>
        <FormContainer
          title={"PLANNER INFORMATION"}
          rightComponent={<Grid />}
        />
        <Hr />
        <FormContainer title={""} rightComponent={<NoTitleForm />} />
        <Hr />

        <FormContainer title={"RESPONSES"} rightComponent={<Responses />} />
        <Hr />
        <FormContainer title={"SHARING"} rightComponent={<Sharing />} />
        <div style={{ alignSelf: "center" }}>
          <Link to={"/payment-details"}>
            <Button>
              Next <EastIcon />
            </Button>
          </Link>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ContactDetails;
