import { configureStore } from "@reduxjs/toolkit";
import venuesReducer from "./venues/venue";
import venueReducer from "./FinalVenues/venueSlice";
import workspaceReducer from "./workspace/workspace";
import blogsReducer from "../features/blog/redux/blogs";
import selectedReducer from "./selected";
import authReducer from "../features/authentication/redux/authSlice";
import venueTypeReducer from "./slices/venueTypeSlice";
import eventTypesReducer from "./slices/eventTypeSlice";
import amenitiesReducer from "./slices/amenitiesSlice";
import shortlistReducer from "./slices/shortlistSlice";
import citiesReducer from "./slices/cities";
import unitsReducer from "./units/units";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: {
    venues: venuesReducer,
    venue: venueReducer,
    workspace: workspaceReducer,
    blogs: blogsReducer,
    selected: selectedReducer,
    auth: authReducer,
    venueTypes: venueTypeReducer,
    eventTypes: eventTypesReducer,
    amenities: amenitiesReducer,
    shortlist: shortlistReducer,
    cities: citiesReducer,
    units: unitsReducer,
  },
  middleware: [thunk],
});

export default store;
