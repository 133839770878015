import styles from "./ToggleCard.module.css";
import { useEffect, useState } from "react";
import CustomDropdownSelect from "../../../../../common/components/DropdownComponents/CustomDropdownSelect/CustomDropdownSelect";
import ToggleButtons from "../../../../../common/components/ToggleButtons/ToggleButtons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CustomAutocomplete from "../../../../../common/components/MUIComponents/CustomAutocomplete";
import { ReactComponent as DoorIcon } from "../../../../../common/assets/svg-mice/door.svg";
import { fetchEventTypes } from "../../../../../redux/slices/eventTypeSlice";
import TextField from "@mui/material/TextField";
import { miceAxios } from "../../../../../axios/axios";

import SustainableVenues from "../../../../../common/components/DropdownComponents/SustainableVenuesFinal/SustainableVenuesFinal";
import VenueTypeFacilities from "../../../../../common/components/DropdownComponents/VenueTypeFacilitiesFinal/VenueTypeFacilities";
import { fetchWorkspaces } from "../../../../../redux/workspace/workspaceSearch";

export default function ToggleCard({
  background,
  page,
  location,
  setLocation,
  numOfPersons,
  setNumOfPersons,
  search,
  setSearch,
}) {
  const container = background
    ? styles.container
    : `${styles.container} ${styles.noShadow}`;

  const selected = useSelector((state) => state.selected.selected);

  console.log("toggle card selected: ", selected);

  const [isVenues, setIsVenues] = useState(false);
  const [isWorkplace, setIsWorkplace] = useState(true);

  const dispatch = useDispatch();

  const eventTypes = useSelector((state) => state.eventTypes.eventTypes);
  // const loadingEventTypes = useSelector((state) => state.eventTypes.loading);
  // const errorEventTypes = useSelector((state) => state.eventTypes.error);

  //const cities = useSelector((state) => state.cities.cities);
  // const loadingCities = useSelector((state) => state.venueTypes.loading);
  // const errorCities = useSelector((state) => state.venueTypes.error);

  //const units = useSelector((state) => state.units.number);

  const handleConferenceRoomsChange = (event) => {
    const value = parseInt(event.target.value, 10);
  };

  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    { label: "Option 4", value: "option4" },
    { label: "Option 5", value: "option5" },
  ];

  // const venueOptions = cities.map((city) => ({
  //   label: city.name,
  //   value: city.id.toString(), // Assuming id is a string
  // }));

  // console.log("cities autocomplete ", venueOptions);

  const eventOptions = eventTypes.map((event) => ({
    label: event.title,
    value: event.id.toString(),
  }));

  const [workspaceData, setWorkspaceData] = useState({
    location: "",
    date: new Date(),
    numOfPersons: 0,
  });

  const dateOptions = [
    { label: "Duration", value: "duration" },
    { label: "Morning", value: "morning" },
    { label: "Afternoon", value: "afternoon" },
    { label: "Evening", value: "evening" },
    { label: "Hourly", value: "hourly" },
    { label: "Full Day", value: "full-day" },
  ];

  const handleVenuesClick = () => {
    setIsVenues(true);
    setIsWorkplace(false);
  };

  const handleWorkplaceClick = () => {
    setIsVenues(false);
    setIsWorkplace(true);
  };

  const handleWorkspaceChange = (property, value) => {
    setWorkspaceData((prevData) => ({ ...prevData, [property]: value }));
  };

  const iconStyle = {
    color: "rgb(24, 24, 24)",
    verticalAlign: "middle",
  };

  const venuesDropdownData = [
    {
      options: [],
      label: "Location",
    },
    {
      options: eventOptions,
      label: "Type of Event",
    },
    {
      options: options,
      placeholder: "No. of Persons",
      func: handleWorkspaceChange,
      property: "numberOfPersons",
      type: "numerical",
      label: "No. of Persons",
    },
    {
      options: options,
      label: "Conference Rooms",
    },
  ];

  const workspaceDropdownData = [
    {
      options: [],
      label: "Location",
    },
    {
      options: dateOptions,
      label: "When",
    },
    {
      options: options,
      placeholder: "No. of Persons",
      func: handleWorkspaceChange,
      property: "numberOfPersons",
      type: "numerical",
      label: "No. of Persons",
    },
  ];

  const renderDropdown = (arr, letter) => {
    let str = "Find Venues";
    if (letter !== "v") {
      if (letter === "s") str = "Find Stay";
      if (letter === "w") str = "Find Workspace";
      if (letter === "m") str = "Find Meetings";
    }

    return (
      <>
        {arr.map((item, index) =>
          item.label === "Bedroom" ? (
            <CustomDropdownSelect
              options={item.options}
              placeholder={item.placeholder}
              onChange={item.func}
              property={item.property}
              type={item.type}
              label={item.label}
              key={index}
            />
          ) : item.label === "Conference Rooms" ? (
            <TextField
              id="outlined-number"
              label="Conference Rooms"
              type="number"
              onChange={handleConferenceRoomsChange}
              sx={{
                height: "64px",
                minWidth: "200px",
                marginTop: "15px",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "var(--clr-pink)",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--clr-pink)",
                  fontFamily: "'Montserrat', sans-serif",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "'Montserrat', sans-serif",
                  color: "var(--clr-gray-500)",
                },
              }}
            />
          ) : item.label === "No. of Persons" ? (
            <TextField
              id="outlined-number"
              label="No. of Persons"
              type="number"
              value={numOfPersons}
              onChange={(e) => setNumOfPersons(e.target.value)}
              sx={{
                height: "64px",
                minWidth: "250px",
                marginTop: "15px",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "var(--clr-pink)",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--clr-pink)",
                  fontFamily: "'Montserrat', sans-serif",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "'Montserrat', sans-serif",
                  color: "var(--clr-gray-500)",
                },
              }}
            />
          ) : item.label === "Location" ? (
            <TextField
              id="outlined-number"
              label="Location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              sx={{
                height: "64px",
                minWidth: "250px",
                marginTop: "15px",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "var(--clr-pink)",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--clr-pink)",
                  fontFamily: "'Montserrat', sans-serif",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "'Montserrat', sans-serif",
                  color: "var(--clr-gray-500)",
                },
              }}
            />
          ) : (
            <CustomAutocomplete
              options={item.options}
              label={item.label}
              key={index}
            />
          )
        )}

        <button
          className={styles.searchButton}
          onClick={(e) => setSearch(true)}
        >
          {str}
        </button>
      </>
    );
  };

  const toggleButtonData = [
    {
      icon: <DoorIcon style={iconStyle} />,
      text: "Venues",
      state: isVenues,
      onClick: handleVenuesClick,
    },
    {
      icon: <DoorIcon style={iconStyle} />,
      text: "Workspace",
      state: isWorkplace,
      onClick: handleWorkplaceClick,
    },
  ];

  return (
    <div className={container}>
      <div className={styles.upperContainer}>
        <div className={styles.buttonsContainer}>
          <ToggleButtons data={toggleButtonData} />
        </div>
        <div className={styles.iconsContainer}>
          <VenueTypeFacilities />
          <SustainableVenues />
        </div>
      </div>

      <div className={styles.selectContainer}>
        {isVenues && <>{renderDropdown(venuesDropdownData, "v")}</>}
        {isWorkplace && <>{renderDropdown(workspaceDropdownData, "w")}</>}
      </div>
    </div>
  );
}
