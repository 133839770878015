import styles from "./SingleBlog.module.css";
import ImageIcons from "../../../../../../common/components/ImageWithIconsAndGradient/ImageIcons";
import GraySubtitle from "../../../../../../common/components/GraySubtitle/GraySubtitle";
import Title from "../../../../../../common/components/Title/Title";
import { Link } from "react-router-dom";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";

const SingleBlog = ({ id, title, subtitle, description, image }) => {
  return (
    <SectionWrapper variant={"justifyContentAlignItemsFlexStart"}>
      <div className={styles.titlesContainer}>
        <Link to={`/blog/${id}`} style={{ textDecoration: "none" }}>
          <Title text={title} color={"purple"} fw={500} />{" "}
        </Link>
        <GraySubtitle text={subtitle} />
      </div>
      <div>{description}</div>
      <div className={styles.imageContainer}>
        <ImageIcons url={image} />
      </div>
    </SectionWrapper>
  );
};

export default SingleBlog;
