import React from "react";
import styles from "./FormContainer.module.css";

const FormContainer = ({ title, rightComponent }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>{title}</div>
      <div className={styles.rightContainer}>{rightComponent}</div>
    </div>
  );
};

export default FormContainer;
