import React, { useEffect, useRef, forwardRef } from "react";
import Button from "../../../Button/Button";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import styles from "./VenueTypeAndFacilitiesDropdown.module.css";
import Hr from "../../../Hr/Hr";
import { useSelector } from "react-redux";
import useOutsideClick from "../../../../../hooks/useOutsideClick";

const VenueTypeAndFacilitiesDropdown = forwardRef(
  ({ isFullContent, page, handleClose, selections, setSelections }, ref) => {
    const venueTypes = useSelector((state) => state.venueTypes.venueTypes);
    const amenities = useSelector((state) => state.amenities.amenities);

    const handleCheckboxChange = (category, value, isChecked) => {
      const currentSelections = selections[category] || [];
      let updatedSelections = {
        ...selections,
        [category]: isChecked
          ? [...currentSelections, value]
          : currentSelections.filter((item) => item !== value),
      };

      setSelections(updatedSelections);
      localStorage.setItem(
        category,
        JSON.stringify(updatedSelections[category])
      );
    };

    const renderCheckboxes = (data, category) =>
      data.map((checkbox) => (
        <CustomCheckbox
          label={checkbox.label}
          value={checkbox.value}
          key={checkbox.value}
          isChecked={selections[category]?.includes(checkbox.value)}
          onChange={(value, isChecked) =>
            handleCheckboxChange(category, value, isChecked)
          }
        />
      ));

    const handleClear = (e) => {
      e.stopPropagation();
      setSelections({
        checkedVenueTypes: [],
        checkedAmenities: [],
      });
      localStorage.removeItem("checkedVenueTypes");
      localStorage.removeItem("checkedAmenities");
    };

    const containerClassName =
      page === "list" ? `${styles.container} ${styles.list}` : styles.container;

    return (
      <>
        <div className={containerClassName} ref={ref}>
          {isFullContent && (
            <>
              <div className={styles.title}>Venue Category</div>
              <div className={styles.checkboxContainer}>
                {renderCheckboxes(
                  venueTypes.map((type) => ({
                    label: type.name,
                    value: type.id.toString(),
                  })),
                  "checkedVenueTypes"
                )}
              </div>
              <Hr />
            </>
          )}

          <div className={styles.title}>Amenities</div>
          <div className={styles.checkboxContainer}>
            {renderCheckboxes(
              amenities.map((amenity) => ({
                label: amenity.title,
                value: amenity.id.toString(),
              })),
              "checkedAmenities"
            )}
          </div>
          <Hr />
          <div className={styles.buttonsContainer}>
            <Button variant={"inverse"} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant={"inverse"} onClick={(e) => handleClear(e)}>
              Clear All
            </Button>
            <Button onClick={handleClose}>Apply</Button>
          </div>
        </div>
      </>
    );
  }
);
export default VenueTypeAndFacilitiesDropdown;
