import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomCard from "../../search/pages/PreviewPage/components/CustomCard/CustomCard";
import { useSelector, useDispatch } from "react-redux";
import EventForm from "../../../pages/EventForm/EventForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import Wrapper from "../Wrapper";
import styles from "./Shortlist.module.css";
const Shortlist = () => {
  const dispatch = useDispatch();
  const shortlist = useSelector((state) => state.shortlist);
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  console.log(shortlist);
  const title = "Your Shortlist";
  const description =
    " On Shortlist Page you can see your favorite venues. You can also send\n" +
    "          inquiry for shortlist and our agents will get back to you with offers.\n" +
    "          For any questions regarding your account and your bookings feel free\n" +
    "          to contact us at contact@montenegromice.me";
  return (
    <Wrapper title={title} description={description}>
      <div className={styles.shortlist}>
        {shortlist.length !== 0 ? (
          shortlist.map((venue) => {
            return (
              <CustomCard
                title={venue.name}
                subtitle={venue.description.slice(0, 30) + "..."}
                url={venue.featured_image}
                key={venue.id}
                venue={venue}
                deleteProp={true}
                sendInquiry={false}
              />
            );
          })
        ) : (
          <Typography>Your Shortlist is currently empty.</Typography>
        )}
      </div>
    </Wrapper>
  );
};

export default Shortlist;
