import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  searchResults: [],
  loading: false,
  error: null,
  venuesSearchParameters: null,
};

const venuesSlice = createSlice({
  name: "venues",
  initialState: initialStateValue,
  reducers: {
    updateVenuesSearchParameters: (state, action) => {
      state.venuesSearchParameters = action.payload;
    },
    startVenuesSearch: (state) => {
      state.loading = true;
      state.error = null;
    },
    saveVenuesSearchResults: (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    },
    venuesSearchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateVenuesSearchParameters,
  startVenuesSearch,
  saveVenuesSearchResults,
  venuesSearchError,
} = venuesSlice.actions;

export default venuesSlice.reducer;
