import styles from "./FlexItem.module.css";

export default function FlexItem({ icon, altText, title, num, bgClr }) {
  return (
    <div className={`${styles.container} ${styles[bgClr]}`}>
      <div className={`${styles.item} ${styles.item1}`}>
        <img src={icon} alt={altText} className={styles.icon} />
      </div>
      <div className={`${styles.item} ${styles.item2}`}>{title}</div>
      <div className={`${styles.item} ${styles.item3}`}>{num}</div>
    </div>
  );
}
