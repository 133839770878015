import * as Icons from "@mui/icons-material";
import Button from "../../Button/Button";
import styles from "./PopUpInfo.module.css";
import GraySubtitle from "../../GraySubtitle/GraySubtitle";
import Title from "../../Title/Title";
import { useEffect, useState } from "react";
import InfoItem from "./InfoItem/InfoItem";
import { ReactComponent as BuildingIcon } from "../../../assets/svg-mice/buildings.svg";
import { ReactComponent as StarDocument } from "../../../assets/svg-mice/star-document.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/svg-mice/documents.svg";

export default function PopUpInfo({ isOpen, setIsOpen }) {
  const closePopUp = () => {
    setIsOpen(false);
  };

  const containerClassName = isOpen
    ? styles.openContainer
    : styles.closedContainer;
  const contentClassName = isOpen ? styles.openContent : styles.closedContent;
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      setMobileView(window.innerWidth <= 700);
    };

    setResponsiveness();

    window.addEventListener("resize", setResponsiveness);

    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  const style1 = {
    color: "white",
    cursor: "pointer",
  };
  const style2 = { color: "var(--clr-purple)", fontSize: "2rem" };

  return (
    <div className={containerClassName}>
      <div className={contentClassName}>
        <div className={styles.upperContainer}>
          <span>Welcome to the Event Supplier Network!</span>
          <Icons.CloseOutlined onClick={closePopUp} style={style1} />
        </div>
        <div className={styles.bottomContainer}>
          <Title
            text={"Find Incredible Venues. Request Quotes. Book Event Space."}
            color={"purple"}
          />
          <GraySubtitle text={"No commission, no charges, no fees"} />
          <div className={styles.iconsContainer}>
            <InfoItem
              text={"Find venues, then add them to your list"}
              title={"1"}
              icon={<BuildingIcon />}
            />
            {!mobileView && <Icons.ArrowForwardIosOutlined style={style2} />}
            <InfoItem
              text={"Review selected venues and create a request"}
              title={"2"}
              icon={<DocumentIcon />}
            />
            {!mobileView && <Icons.ArrowForwardIosOutlined style={style2} />}
            <InfoItem
              text={"Compare proposals and book your ideal event space"}
              title={"3"}
              icon={<StarDocument />}
            />
          </div>
          <div onClick={closePopUp}>
            <Button>Okay, got it! </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
