import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component="div"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tabs,
  useIcons = false,
  boxShadow,
  isFullWidth = false,
}) {
  const [value, setValue] = React.useState(0);
  const boxShadowStyle = boxShadow
    ? "rgba(149, 157, 165, 0.2) 0px 8px 24px"
    : "none";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        // border: "1px solid var(--clr-gray-250)",
        boxShadow: boxShadowStyle,
        borderRadius: "1rem",
        // minWidth: 1000,
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flexStart",
        // alignItems: "flexStart",
        ...(isFullWidth && { width: "100%" }),
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "var(--clr-gray-250)",
          //width: "100%",
          minWidth: 200,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { backgroundColor: "var(--clr-pink)" } }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          sx={{
            "& .Mui-selected": {
              color: "var(--clr-pink)",
              fontWeight: "bold",
            },
            "& .MuiTab-root": {
              textTransform: "none",
              letterSpacing: "1px",
              fontSize: "1rem",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={useIcons ? tab.icon : null}
              iconPosition="start"
              sx={{
                "&&.Mui-selected": {
                  color: "var(--clr-pink) !important",
                  fontWeight: "bold",
                },
                "&.MuiTab-root": {
                  textTransform: "none",
                  letterSpacing: "1px",
                  fontSize: "1rem",
                },
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
}

BasicTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      icon: PropTypes.node, // Optional icon prop
    })
  ).isRequired,
  useIcons: PropTypes.bool, // New prop to determine if icons should be used
};
