import styles from "./TextGrid.module.css";
import Title from "../../../../../../common/components/Title/Title";

export default function TextGrid({ title, data }) {
  return (
    <div className={styles.container}>
      <Title text={title} />
      <div className={styles.grid}>
        {data.map((item, index) => {
          return (
            <div key={index}>
              {item.x} <br /> {item.y}
            </div>
          );
        })}
      </div>
    </div>
  );
}
