import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import FirstColumnCell from "./FirstColumnCell/FirstColumnCell";
import CheckIcon from "@mui/icons-material/Check";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

const columns = [
  {
    field: "col1",
    headerName: "Name",
    width: 250,
    headerAlign: "left",
    headerClassName: "meeting-room--header",
    cellClassName: "meeting-room-cells",
    renderCell: (params) => {
      return (
        <FirstColumnCell
          imageUrl={params.value[0]}
          name={params.value[1]}
          id={params.value[2]}
        />
      );
    },
  },
  {
    field: "col2",
    headerName: "Room size",
    width: 200,
    headerAlign: "center",
    cellClassName: "general--cells",
  },
  {
    field: "col3",
    headerName: "Celling height",
    width: 200,
    headerAlign: "center",
    cellClassName: "general--cells",
  },
  {
    field: "col4",
    headerName: "Room dimensions",
    width: 200,
    headerAlign: "center",
    cellClassName: "general--cells",
  },
  {
    field: "col5",
    headerName: "Maximum capacity",
    width: 200,
    headerAlign: "center",
    cellClassName: "general--cells",
  },
];

export default function MeetingRoomDataGrid({ units }) {
  const rows = units.map((unit) => ({
    id: unit.id,
    col1: [unit.featured_image, unit.name, unit.id],
    col2: `${unit.size} sq.m.`,
    col3: `${unit.ceiling_height}m`,
    col4: `${unit.dimensions}`,
    col5: `${unit.total_number_of_people}`,
  }));
  return (
    <div
      style={{
        height: "fit-content",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#dde2fb",
            marginTop: "10px",
            marginBottom: "10px",
          },
          "& .exhibition-column--cells": {
            backgroundColor: "#f5f5f9",
            marginLeft: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .exhibition-column--header": {
            backgroundColor: "#80909e",
            color: "white",
            marginLeft: "3px",
            marginRight: "3px",
            borderRadius: "0.5rem 0 0.5rem 0",
          },
          "& .meeting-room--header": {
            borderRadius: "0.5rem 0 0 0",
          },
          "& .theater--header": {
            borderRadius: "0 0 0.5rem 0",
          },
          "& .sun--header": {
            backgroundColor: "#ffd600",
            color: "white",
            marginLeft: "3px",
            marginRight: "3px",
            borderRadius: "0.5rem 0 0.5rem 0",
          },
          "& .sun--cells": {
            backgroundColor: "#fff4d9",
            marginLeft: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          // "& .css-1972wqj-MuiDataGrid-root": {
          //   border: "none",
          // },
          "& .general--cells": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .meeting-room-cells": {
            padding: 0,
          },
        }}
      />
    </div>
  );
}
