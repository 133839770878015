import * as React from "react";
import PropTypes from "prop-types";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function createData(name, value, distance) {
  return {
    name,
    value,
    distance,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { title, row } = props;
  const [open, setOpen] = React.useState(false);

  if (!row || row.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          align={"left"}
          sx={{ color: "var(--clr-purple)", width: "25%" }}
        >
          {title}
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: "bold", width: "25%" }}>
          {row[0]?.title || "Title"} {row[0]?.value || "Value"}
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: "bold", width: "25%" }}>
          {row[0].distance_amount}
        </TableCell>
        <TableCell align={"right"} sx={{ width: "25%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {row.slice(1).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell
                      align={"left"}
                      style={{
                        color: "white",
                        width: "25%",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    ></TableCell>
                    <TableCell
                      align={"center"}
                      sx={{
                        fontWeight: "bold",
                        width: "25%",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      {item.title}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      sx={{ fontWeight: "bold" }}
                      style={{
                        width: "25%",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      {item.distance_amount}
                    </TableCell>
                    <TableCell
                      align={"right"}
                      style={{
                        color: "white",
                        width: "25%",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    distance: PropTypes.string.isRequired,
  }).isRequired,
};

const rows = [
  createData("Nearest Airport", "Tivat Airport", "18"),
  createData("Nearest Beach", "Private Beach", "9.0"),
  createData("City Center", "Kotor", "16.0"),
];

export default function CollapsibleTable({ distance }) {
  const title =
    distance?.distance_section_title === "Airport"
      ? "Nearest Airport"
      : distance?.distance_section_title === "Attraction"
      ? 'City Center"'
      : distance?.distance_section_title === "Beach"
      ? "Nearest Beach"
      : "Distance section title";
  return (
    <TableContainer component={Paper} sx={{ minWidth: "70vw" }}>
      <Table aria-label="collapsible table">
        <TableBody>
          <Row title={title} row={distance?.distance_lists} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
