import styles from "./ConciergeEnquiryForm.module.css";
import StayFormText from "../StayFormText/StayFormText";
import { Button } from "@mui/material";
import FormTextField from "../../../common/components/FormComponents/FormTextField";
import { useState } from "react";

const ConciergeEnquiryForm = () => {
  const title = "Concierge Inquiry Form";
  const str1 =
    "Before you complete our form below, please note we can only take on enquires that satisfy the following criterira.";
  const str2 = "Your event or meeting must:";
  const arr = [
    { text1: "", text2: "be a corporate event or meeting" },
    { text1: "", text2: "have a budget of €10,000+" },
    { text1: "", text2: "be based in Montenegro" },
  ];
  const str3 =
    "We do not assist with birthdays, weddings, christenings or non-corporate parties.";

  const textFieldStyle = {
    color: "var(--clr-purple)",
    "& .MuiInputLabel-root.Mui-focused": {
      color: "var(--clr-purple)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--clr-purple)",
    },
  };

  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [size, setSize] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const textFields = [
    {
      label: "Name",
      required: true,
      onChange: setName,
      value: name,
      style: textFieldStyle,
    },
    {
      label: "Organization",
      required: true,
      onChange: setOrganization,
      value: organization,
      style: textFieldStyle,
    },
    {
      label: "Telephone",
      required: true,
      onChange: setTelephone,
      value: telephone,
      style: textFieldStyle,
    },
    {
      label: "Email",
      required: true,
      onChange: setEmail,
      value: email,
      style: textFieldStyle,
    },
    {
      label: "Size",
      required: true,
      onChange: setSize,
      value: size,
      style: textFieldStyle,
    },
    {
      label: "Additional Information",
      required: true,
      onChange: setAdditionalInfo,
      value: additionalInfo,
      style: textFieldStyle,
      multiline: true,
      numOfRows: 4,
    },
  ];

  return (
    <div className={styles.conciergeEnquiryFormContainer}>
      <StayFormText
        title={title}
        text1={str1}
        text2={str2}
        textArr={arr}
        text3={str3}
        color={"purple"}
      />
      <form className={styles.formContainer} noValidate autoComplete="off">
        {textFields.map((field, index) => (
          <FormTextField
            key={index}
            variant={"standard"}
            label={field.label}
            required={field.required}
            onChange={field.onChange}
            value={field.value}
            style={field.style}
            multiline={field.multiline}
            numOfRows={field.numOfRows}
          />
        ))}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            color: "black",
            backgroundColor: "var(--clr-yellow)",
            paddingY: "0.7rem",
            margin: "0 auto",
            width: "100px",
            "&:hover": {
              animation: "scaleAnimation 0.5s",
              backgroundColor: "var(--clr-yellow)",
            },

            "@keyframes scaleAnimation": {
              "0%": {
                transform: "scale(1)",
              },
              "100%": {
                transform: "scale(1.01)",
              },
            },
          }}
        >
          Send
        </Button>
      </form>
    </div>
  );
};

export default ConciergeEnquiryForm;
