import styles from "./Header.module.css";
import PopUpInfo from "./PopUpInfo/PopUpInfo";
import ModalIcon from "../ModalIcon/ModalIcon";
import FullMiceLogo from "../FullMiceLogo/FullMiceLogo";
import { useState } from "react";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  return (
    <div className={styles.headerContainer}>
      <PopUpInfo isOpen={isOpen} setIsOpen={setIsOpen} />
      <ModalIcon openModal={openModal} />
      <FullMiceLogo />
      <div className={styles.rightHeaderContainer}></div>
    </div>
  );
}
