import styles from "./CustomButtonGroup.module.css";
import { useState } from "react";

export default function CustomButtonGroup({ text1, text2, setBtnFlag }) {
  const [firstBtn, setFirstBtn] = useState(true);
  const [secondBtn, setSecondBtn] = useState(false);

  const handleFirstBtnClick = () => {
    setFirstBtn(true);
    setSecondBtn(false);
    setBtnFlag(true);
  };

  const handleSecondBtnClick = () => {
    setFirstBtn(false);
    setSecondBtn(true);
    setBtnFlag(false);
  };
  return (
    <>
      <button
        className={!firstBtn ? styles.firstButton : styles.clickedButton}
        onClick={handleFirstBtnClick}
      >
        {text1}
      </button>
      <button
        className={!secondBtn ? styles.secondButton : styles.clickedButton}
        onClick={handleSecondBtnClick}
      >
        {text2}
      </button>
    </>
  );
}
