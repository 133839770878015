import styles from "./AttendeesBedrooms.module.css";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import React, { forwardRef } from "react";

const iconStyle = {
  color: "var(--clr-purple)",
  cursor: "pointer",
};
const AttendeesBedrooms = forwardRef(
  (
    {
      attendees,
      bedrooms,
      onIncreaseAttendees,
      onDecreaseAttendees,
      onIncreaseBedrooms,
      onDecreaseBedrooms,
      setAttendees,
      setBedrooms,
    },
    ref
  ) => {
    return (
      <div className={styles.dropdown} ref={ref}>
        <div>Attendees and Bedrooms</div>
        <div className={styles.subcontainer}>
          <div>Attendees</div>
          <div className={styles.row}>
            <RemoveCircleOutlineOutlinedIcon
              sx={iconStyle}
              onClick={(e) => {
                e.stopPropagation();
                onDecreaseAttendees();
              }}
            />

            <input
              value={attendees}
              type={"text"}
              className={styles.inputNum}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setAttendees(e.target.value)}
            />
            <AddCircleOutlineOutlinedIcon
              sx={iconStyle}
              onClick={(e) => {
                e.stopPropagation();
                onIncreaseAttendees();
              }}
            />
          </div>
        </div>
        <div className={styles.subcontainer}>
          <div>Bedrooms</div>
          <div className={styles.row}>
            <RemoveCircleOutlineOutlinedIcon
              sx={iconStyle}
              onClick={(e) => {
                e.stopPropagation();
                onDecreaseBedrooms();
              }}
            />
            <input
              value={bedrooms}
              type={"text"}
              className={styles.inputNum}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setBedrooms(e.target.value)}
            />
            <AddCircleOutlineOutlinedIcon
              sx={iconStyle}
              onClick={(e) => {
                e.stopPropagation();
                onIncreaseBedrooms();
              }}
            />
          </div>
        </div>
        <div></div>
      </div>
    );
  }
);

export default AttendeesBedrooms;
