import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, Link } from "react-router-dom";
import MiceLogo from "../MiceLogo/MiceLogo";
import Button from "../Button/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Hr from "../Hr/Hr";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MUIBadge from "../MUIComponents/MUIBadge";
import MUIDrawer from "../MUIComponents/Drawer";
import { Shortlisted } from "../../../routeImports";
import UserAvatar from "../MUIComponents/UserAvatar";
import { useSelector } from "react-redux";
import { ReactComponent as SmallLogo } from "../../assets/svg-mice/SmallLogo.svg";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import PersonIcon from "@mui/icons-material/Person";

export default function Navbar() {
  const location = useLocation();
  const path = location.pathname.slice(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileView, setMobileView] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [openForCompanies, setOpenForCompanies] = useState(false);
  const [openForVenues, setOpenForVenues] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const shortlistedVenues = useSelector((state) => state.shortlist);

  const { userInfo } = useSelector((state) => state.auth);
  const { logoutSuccess } = useSelector((state) => state.auth);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth <= 750
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const handleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const arrowStyle = {
    verticalAlign: "bottom",
    color: "var(--clr-gray-800)",
    fontSize: "1.1" + "rem",
  };

  const openMobileNavbar = () => {
    setMobileDrawer(true);
  };

  const handleCloseMobileDrawer = () => {
    setMobileDrawer(false);
  };

  const displayDesktop = () => {
    return (
      <nav
        className={`${styles.navbarContainer} ${
          path === "preview-page-1" ? styles.full : ""
        }`}
      >
        <Link to={"/"}>
          <SmallLogo />
        </Link>
        <div className={styles.leftContainer}>
          <div>
            <div onClick={handleMenu} className={styles.navbarItem}>
              For Companies <KeyboardArrowDownIcon sx={arrowStyle} />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              classes={{ paper: styles.paper }}
            >
              <MenuItem onClick={handleClose}>Option 1</MenuItem>
              <MenuItem onClick={handleClose}>Option 2</MenuItem>
              <MenuItem onClick={handleClose}>Option 3</MenuItem>
            </Menu>
          </div>
          <div>
            <div onClick={handleMenu} className={styles.navbarItem}>
              For Venues <KeyboardArrowDownIcon sx={arrowStyle} />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              classes={{ paper: styles.paper }}
            >
              <MenuItem onClick={handleClose}>Option 1</MenuItem>
              <MenuItem onClick={handleClose}>Option 2</MenuItem>
              <MenuItem onClick={handleClose}>Option 3</MenuItem>
            </Menu>
          </div>
          <div onClick={handleMenu} className={styles.navbarItem}>
            Help <KeyboardArrowDownIcon sx={arrowStyle} />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            classes={{ paper: styles.paper }}
          >
            <MenuItem onClick={handleClose}>Option 1</MenuItem>
            <MenuItem onClick={handleClose}>Option 2</MenuItem>
            <MenuItem onClick={handleClose}>Option 3</MenuItem>
          </Menu>

          <div className={styles.navbarButtonsContainer}>
            {userInfo && Object.keys(userInfo).length > 0 ? (
              <>
                <UserAvatar />
                {/*<MUIBadge value={shortlistedVenues.length}>*/}
                {/*  <Button variant={"inverse"} onClick={openDrawer}>*/}
                {/*    Shortlist{" "}*/}
                {/*  </Button>*/}
                {/*</MUIBadge>*/}
                {/*<MUIDrawer*/}
                {/*  open={drawerOpen}*/}
                {/*  onClose={closeDrawer}*/}
                {/*  position={"right"}*/}
                {/*>*/}
                {/*  {" "}*/}
                {/*  <Shortlisted closeDrawer={closeDrawer} />*/}
                {/*</MUIDrawer>*/}
              </>
            ) : (
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <Button> Sign In</Button>
              </Link>
            )}
            <MUIBadge value={shortlistedVenues.length}>
              <Button variant={"inverse"} onClick={openDrawer}>
                Shortlist{" "}
              </Button>
            </MUIBadge>
            <MUIDrawer
              open={drawerOpen}
              onClose={closeDrawer}
              position={"right"}
            >
              {" "}
              <Shortlisted closeDrawer={closeDrawer} />
            </MUIDrawer>
          </div>
        </div>
      </nav>
    );
  };

  const displayMobile = () => {
    return (
      <nav>
        <div className={styles.mobileNavVisibleContainer}>
          <MenuIcon
            sx={{
              color: "var(--clr-pink)",
              margin: "1rem",
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
            onClick={openMobileNavbar}
          />
          <MUIBadge value={shortlistedVenues.length}>
            <Button variant={"inverse"} onClick={openDrawer}>
              Shortlist{" "}
            </Button>
          </MUIBadge>
          <MUIDrawer open={drawerOpen} onClose={closeDrawer} position={"right"}>
            {" "}
            <Shortlisted closeDrawer={closeDrawer} />
          </MUIDrawer>
        </div>
        <MUIDrawer
          open={mobileDrawer}
          position={"left"}
          onClose={handleCloseMobileDrawer}
        >
          <List sx={{ width: "100%" }}>
            <MiceLogo />
            <ListItemButton
              onClick={() => setOpenForCompanies(!openForCompanies)}
            >
              <ListItemText primary={"For Companies"}></ListItemText>
              {openForCompanies ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForCompanies} timeout={"auto"} unmountOnExit>
              <List component={"div"} disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 1"} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 2"} />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={() => setOpenForVenues(!openForVenues)}>
              <ListItemText primary={"For Venues"}></ListItemText>
              {openForVenues ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForVenues} timeout={"auto"} unmountOnExit>
              <List component={"div"} disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 1"} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 2"} />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={() => setOpenHelp(!openHelp)}>
              <ListItemText primary={"Help"}></ListItemText>
              {openHelp ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openHelp} timeout={"auto"} unmountOnExit>
              <List component={"div"} disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 1"} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={"Example 2"} />
                </ListItemButton>
              </List>
            </Collapse>

            {userInfo && Object.keys(userInfo).length > 0 ? (
              <>
                {" "}
                <ListItemButton onClick={() => setOpenProfile(!openProfile)}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={""}></ListItemText>
                  {openProfile ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openProfile} timeout={"auto"} unmountOnExit>
                  <List component={"div"} disablePadding>
                    <Link
                      to={"/user-area"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText primary={"My Account"} />
                      </ListItemButton>
                    </Link>
                    <Link
                      to={"/user-area/shortlist"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText primary={"My Shortlist"} />
                      </ListItemButton>
                    </Link>

                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary={"Log Out"} />
                    </ListItemButton>
                  </List>
                </Collapse>{" "}
              </>
            ) : (
              <>
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "var(--clr-purple)" }}
                >
                  <ListItemButton>
                    <ListItemText primary={"Sign In"}></ListItemText>
                  </ListItemButton>
                </Link>
                <Link
                  to={"/register"}
                  style={{ textDecoration: "none", color: "var(--clr-purple)" }}
                >
                  <ListItemButton>
                    <ListItemText primary={"Sign Up"}></ListItemText>
                  </ListItemButton>
                </Link>
              </>
            )}
          </List>
        </MUIDrawer>
      </nav>
    );
  };

  if (path.startsWith("user-area"))
    return <div style={{ display: "none" }}></div>;

  return (
    <div>
      {mobileView ? displayMobile() : displayDesktop()}
      {path.includes("preview-page") && <Hr />}
    </div>
  );
}
