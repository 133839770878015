import styles from "./StarRating.module.css";
import StarRateIcon from "@mui/icons-material/StarRate";

export default function StarRating({ num }) {
  return (
    <div className={styles.starRating}>
      {num} <StarRateIcon sx={{ fontSize: "1rem" }} />
    </div>
  );
}
