import TextField from "@mui/material/TextField";

const style = {
  ".MuiFilledInput-root": {
    backgroundColor: "#dde2fb",
    fontSize: "1.3rem",
    "&:hover": {},
    "&.Mui-focused": {
      color: "#4c58a0",
    },
  },
  ".MuiFilledInput-input": {
    color: "#4c58a0",
  },
  " .MuiInputBase-root.MuiFilledInput-root:after": {
    borderBottom: "2px solid var(--clr-purple)",
  },
};

const FilledTextField = ({ placeholder, value, onChange, name }) => {
  return (
    <TextField
      name={name}
      variant="filled"
      fullWidth={true}
      sx={style}
      required={true}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default FilledTextField;
