import styles from "./TextInfo.module.css";

export default function TextInfo({ data }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.title}</div>
      {data.data?.map((i) => (
        <div key={i.id}>{i.title || i.name}</div>
      ))}
    </div>
  );
}
