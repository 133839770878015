import React from "react";
import styles from "./GalleryPicker.module.css";
import CloseIcon from "@mui/icons-material/Close";
const GalleryPicker = ({ galleries, handleOpenGallery, handleClose }) => {
  // const nonEmptyGalleries = galleries.filter(
  //   (gallery) => Array.isArray(gallery) && gallery.length > 0
  // );
  return (
    <div className={styles.galleryPickerContainer}>
      <CloseIcon
        onClick={handleClose}
        sx={{
          color: "white",
          zIndex: "200",
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      />
      {galleries.map((gallery, index) => {
        return (
          <div key={Math.random()} className={styles.galleryContainer}>
            <div className={styles.subcontainer}>
              <img
                alt={gallery[0]?.alt_image_me}
                src={gallery[0]?.path}
                className={styles.galleryImage}
              />
              <button onClick={() => handleOpenGallery(gallery)}>
                Open Gallery
              </button>
            </div>

            <div className={styles.text}>Gallery {index}</div>
          </div>
        );
      })}
    </div>
  );
};

export default GalleryPicker;
