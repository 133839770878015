import styles from "./MiceLogo.module.css";
import { Link } from "react-router-dom";

export default function MiceLogo() {
  return (
    <Link to="/">
      <div className={styles.miceLogo}>
        <div className={`${styles.circle} ${styles.yellow}`}></div>
        <div className={styles.letterM}>M</div>
        <div className={`${styles.circle} ${styles.pink}`}></div>
      </div>
    </Link>
  );
}
