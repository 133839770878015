import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import Footer from "../../../../common/components/Footer/Footer";
import SingleBlog from "./components/SingleBlog/SingleBlog";
import Hr from "../../../../common/components/Hr/Hr";
import Newsletter from "./components/Newsletter/Newsletter";
import React from "react";
import { useSelector } from "react-redux";
import LinearIndeterminate from "../../../../common/components/MUIComponents/LinearIndeterminate";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import {
  selectAllBlogs,
  selectBlogsError,
  selectBlogsLoading,
} from "../../redux/blogSelectors";

const BlogPage = () => {
  useScrollToTop();

  const blogs = useSelector(selectAllBlogs);
  const loading = useSelector(selectBlogsLoading);
  const error = useSelector(selectBlogsError);

  if (loading) {
    return <LinearIndeterminate />;
  }

  if (error) return <div>Error: {error.message}</div>;

  if (!blogs) return <div>No blogs data</div>;

  return (
    <>
      <Hr />
      <PageWrapper variant={"blogs"}>
        {blogs.map((item) => {
          return (
            <SingleBlog
              id={item.id}
              title={item.title_me}
              subtitle={item.short_description_me}
              description={item.big_description_me}
              image={item.main_image}
              key={item.id}
            />
          );
        })}
        <Hr />
        <Newsletter />
      </PageWrapper>
      <PopularLocations />
      <Footer />
    </>
  );
};

export default BlogPage;
