import { createSlice } from "@reduxjs/toolkit";

const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchBlogsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBlogsSuccess(state, action) {
      state.blogs = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchBlogsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchBlogsStart, fetchBlogsSuccess, fetchBlogsFailure } =
  blogsSlice.actions;

export default blogsSlice.reducer;
