import styles from "./NumberOfPersonsDropdown.module.css";
import Button from "../../Button/Button";
import NumberOfPersonsPartContainer from "./NumberOfPersonsPartContainer/NumberOfPersonsPartContainer";
import Hr from "../../Hr/Hr";
import { useEffect, useRef } from "react";

export default function NumberOfPersonsDropdown({
  isDropdownOpen,
  setIsDropdownOpen,
  setStateObject,
  stateObject,
}) {
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);
  const handleCancel = () => {
    setIsDropdownOpen(false);
  };

  const handleApply = () => {};

  return (
    <div
      ref={dropdownRef}
      className={`${styles.dropdownContainer} ${
        isDropdownOpen ? "" : styles.closed
      }`}
    >
      <NumberOfPersonsPartContainer title={"Attendees & Bedrooms"} />
      <Hr />
      <NumberOfPersonsPartContainer title={"Booking Type"} />
      <Hr />
      <div className={styles.buttonsContainer}>
        <Button variant={"inverse"} onClick={handleCancel}>
          Cancel{" "}
        </Button>
        <Button variant={"inverse"}>Reset Filters</Button>
        <Button onClick={handleApply}> Apply </Button>
      </div>
    </div>
  );
}
