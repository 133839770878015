import React, { useEffect, useState } from "react";
import GraySubtitle from "../../../GraySubtitle/GraySubtitle";
import styles from "./InfoItem.module.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const InfoItem = ({ title, text, icon }) => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      setMobileView(window.innerWidth <= 700);
    };

    setResponsiveness();

    window.addEventListener("resize", setResponsiveness);

    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  return (
    <div className={styles.items}>
      <span className={styles.title}>{title}</span>

      {icon}
      <GraySubtitle text={text} />
    </div>
  );
};

export default InfoItem;
