import { ReactComponent as LeafIcon } from "../../../assets/svg-mice/leaf.svg";
import SustainableVenuesDropdown from "./SustainableVenuesDropdown/SustainableVenuesDropdown";
import styles from "./SustainableVenuesFinal.module.css";
import { useState, useRef, useEffect } from "react";

const iconStyle = {
  color: "#707070",
  border: "1px solid rgb(180, 180, 180)",
  padding: "0.7rem 0.8rem",
  borderRadius: "0.4rem 0 0.4rem 0",
  marginRight: "0.5rem",
  cursor: "pointer",
  fontSize: "1.1rem",
  width: "50px",
  height: "50px",
};

const SustainableVenues = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <div className={styles.container}>
      <div onClick={() => setShowDropdown((prevState) => !prevState)}>
        <LeafIcon style={iconStyle} />
      </div>
      {showDropdown && (
        <div ref={dropdownRef}>
          {" "}
          <SustainableVenuesDropdown />
        </div>
      )}
    </div>
  );
};

export default SustainableVenues;
