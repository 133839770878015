import { blogAxios } from "../../../axios/axios";
import { fetchBlogsStart, fetchBlogsSuccess, fetchBlogsFailure } from "./blogs";

export const fetchBlogs = () => async (dispatch) => {
  dispatch(fetchBlogsStart());
  try {
    const response = await blogAxios.get(`getAllBlogs`);
    dispatch(fetchBlogsSuccess(response.data));
  } catch (error) {
    dispatch(fetchBlogsFailure(error.message));
  }
};
