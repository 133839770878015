import React from "react";

export default function Title({ text, color, fw }) {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        color: `var(--clr-${color})`,
        fontWeight: fw,
      }}
    >
      {text}
    </div>
  );
}
