import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialShortlist = JSON.parse(localStorage.getItem("shortlist")) || [];

const notifyMaxVenues = () => {
  toast("You have added the maximum of 10 venues in your Shortlist!");
};

const shortlistSlice = createSlice({
  name: "shortlist",
  initialState: initialShortlist,
  reducers: {
    addVenueToShortlist: (state, action) => {
      const isVenueInShortlist = state.some(
        (venue) => venue.id === action.payload.id
      );
      if (!isVenueInShortlist) {
        const updatedShortlist = [...state, action.payload];
        localStorage.setItem("shortlist", JSON.stringify(updatedShortlist));
        if (updatedShortlist.length === 10) {
          notifyMaxVenues();
        }
        return updatedShortlist;
      }
      return state;
    },
    removeVenueFromShortlist: (state, action) => {
      const updatedShortlist = state.filter(
        (venue) => venue.id !== action.payload.id
      );
      localStorage.setItem("shortlist", JSON.stringify(updatedShortlist));
      return updatedShortlist;
    },
    isVenueInShortlist: (state, action) => {
      return state.some((venue) => venue.id === action.payload.id);
    },
    emptyShortlist: (state, action) => {
      localStorage.removeItem("shortlist");
      return [];
    },
  },
});

export const { addVenueToShortlist, removeVenueFromShortlist, emptyShortlist } =
  shortlistSlice.actions;

export default shortlistSlice.reducer;
