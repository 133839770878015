import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";

const FormSelectField = ({
  label,
  value,
  onChange,
  options,
  required = false,
}) => {
  return (
    <FormControl fullWidth={true}>
      <FormLabel component="legend">{label}</FormLabel>
      <Select
        labelId={`${label.toLowerCase()}-label`}
        id={label.toLowerCase()}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        fullWidth
        placeholder={"Please Choose"}
        required={required}
        sx={{
          color: "var(--clr-purple)",
          "& .MuiInputLabel-root.Mui-focused": {
            color: "var(--clr-purple)",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "var(--clr-purple)",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            disabled={option === "Select Country"}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormSelectField;
