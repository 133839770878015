import jwt_decode from "jwt-decode";

export const isTokenExpired = () => {
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    return true;
  }

  try {
    const decoded = jwt_decode(userToken);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token: ", error);
    return true;
  }
};
