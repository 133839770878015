import styles from "./ListItem.module.css";
import GraySubtitle from "../../../../../../../common/components/GraySubtitle/GraySubtitle";
import Title from "../../../../../../../common/components/Title/Title";
import StarRating from "../../../../../../../common/components/StarRating/StarRating";
import { Link } from "react-router-dom";
import Button from "../../../../../../../common/components/Button/Button";

const ListItem = ({ item }) => {
  const imgUrl =
    "https://assets.bedful.com/images/21896bfd778e98fb84b1e69dbd77bad9c9107714/large.jpg";
  return (
    <div className={styles.listItemContainer}>
      <img src={imgUrl} alt={"something"} className={styles.image} />
      <div className={styles.rightContainer}>
        <GraySubtitle text={item.description} />
        <Link to={"/preview-page"} style={{ textDecoration: "none" }}>
          <Title text={item.name} fw={400} color={"black"} />
        </Link>
        <div className={styles.venueButtons}>
          <div className={styles.venueInfo}>
            {" "}
            {item.total_no_of_conference_rooms} Conference Rooms
          </div>
          <div className={styles.venueInfo}>
            {" "}
            {item.total_conference_room_space} Workspaces
          </div>
          <div className={styles.venueInfo}>
            {" "}
            {item.total_conference_room_space} Meeting Rooms
          </div>
          <div className={styles.venueInfo}>
            {" "}
            {item.total_conference_room_space} Guest Rooms
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.starRatingContainer}>
            <StarRating num={"10.0"} />
            <div>
              <div>Superb</div>
              <GraySubtitle text={"1 Review"} />
            </div>
          </div>
          {/*<div className={styles.shortlistedButton}>*/}
          {/*  <ShortlistedButton />*/}
          {/*</div>*/}
          <div className={styles.buttonsContainerVariant}>
            <Button>Send Inquiry</Button>
            <Button variant={"outlined"} color={"purple"}>
              View More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
