import Fade from "@mui/material/Fade";
import CustomizedImageGallery from "../../features/search/pages/PreviewPage/components/CustomizedImageGallery/CustomizedImageGallery";
import Modal from "@mui/material/Modal";
import GalleryPicker from "../../features/search/pages/PreviewPage/components/GalleryPicker/GalleryPicker";
import React, { useState } from "react";
const CustomizedImageGalleryModal = ({ open, handleClose, images }) => {
  const [viewingGallery, setViewingGallery] = useState(false);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);

  const handleOpenGallery = (galleryImages) => {
    setSelectedGalleryImages(galleryImages);
    setViewingGallery(true);
  };

  const handleBackToPicker = () => {
    setViewingGallery(false);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleClose();
        setViewingGallery(false); // Reset the view when closing the modal
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1c2229",
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div>
          {viewingGallery ? (
            <CustomizedImageGallery
              handleClose={handleClose}
              images={selectedGalleryImages}
              handleBack={handleBackToPicker} // Pass handleBack function to allow going back to the picker
            />
          ) : (
            <GalleryPicker
              handleClose={handleClose}
              galleries={images}
              handleOpenGallery={handleOpenGallery}
            />
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomizedImageGalleryModal;
