import React from "react";
import { useSelector } from "react-redux";
import MUICard from "../../../../../../common/components/MUIComponents/MUICard";
import styles from "./LocalAttractionsBlogsSection.module.css";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import CollapsibleTable from "../../../../../../common/components/MUIComponents/CollapsibleTable";
import Title from "../../../../../../common/components/Title/Title";

export default function LocalAttractionsBlogsSection() {
  const blogs = useSelector((state) => state.blogs.blogs);

  return (
    <SectionWrapper variant={"alignItemsFlexStart"}>
      <Title text={"Local Attractions"} fw={"600"} color={"black"} />
      <div className={styles.gridCards}>
        {blogs &&
          [...blogs]
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .slice(0, 3)
            .map((blog) => <MUICard key={blog.id} blog={blog} />)}
      </div>
    </SectionWrapper>
  );
}
