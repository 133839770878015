import React from "react";
import styles from "./InquiryCard.module.css";
import BallotIcon from "@mui/icons-material/Ballot";
const InquiryCard = ({ inquiry, isGrid }) => {
  const getDate = (str) => {
    if (!str) return "";
    const dateString = "2024-01-10T08:43:46.000000Z";
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };
  console.log(inquiry);
  return (
    <div className={`${styles.container} ${isGrid ? styles.grid : ""}`}>
      <div className={`${styles.header} ${isGrid ? styles.grid : ""}`}>
        <div className={styles.centered}>
          <BallotIcon />
          {inquiry.id && (
            <span style={{ paddingRight: "5px" }}>
              INQUIRY ID: {inquiry.id}
            </span>
          )}
        </div>
        {inquiry.created_at && <span>Sent: {getDate(inquiry.created_at)}</span>}
      </div>

      <ul className={styles.inquiriesList}>
        {inquiry.event_type && (
          <li>
            <span className={styles.keyText}>Event type: </span>{" "}
            <span>{inquiry.event_type}</span>{" "}
          </li>
        )}
        {inquiry.arrive_on && inquiry.depart_on && (
          <li>
            <span className={styles.keyText}>Arrive on: </span>
            {getDate(inquiry.arrive_on)}
            <span className={styles.keyText}> Depart on: </span>

            {getDate(inquiry.depart_on)}
          </li>
        )}
        {inquiry.are_your_event_dates_flex && (
          <li>
            <span className={styles.keyText}>
              Are your events dates flexible?{" "}
            </span>
            {inquiry.are_your_event_dates_flex ? "Yes" : "No"}
          </li>
        )}
        {inquiry.rooms_min !== null &&
          inquiry.rooms_min !== undefined &&
          inquiry.rooms_max && (
            <li>
              <span className={styles.keyText}>Rooms min:</span>{" "}
              {inquiry.rooms_min}{" "}
              <span className={styles.keyText}> Rooms max:</span>{" "}
              {inquiry.rooms_max}
            </li>
          )}

        {inquiry.do_you_need_meeting_rooms != null && (
          <li>
            {inquiry.do_you_need_meeting_rooms ? (
              <span className={styles.keyText}>Needs meeting rooms</span>
            ) : (
              <span className={styles.keyText}>
                Does not need meeting rooms
              </span>
            )}
          </li>
        )}
        {inquiry.attendees_per_day && (
          <li>
            {" "}
            <span className={styles.keyText}> Attendees per day: </span>
            {inquiry.attendees_per_day}
          </li>
        )}
        {inquiry.tell_us_more && (
          <li>
            {" "}
            <span className={styles.keyText}>
              More information about event:
            </span>{" "}
            {inquiry.tell_us_more}
          </li>
        )}
        {inquiry.venues?.length && (
          <ul className={styles.sublist}>
            <li> Venues: </li>
            {inquiry.venues.map((venue) => (
              <li key={venue.id}>{venue.name}</li>
            ))}
          </ul>
        )}
      </ul>
    </div>
  );
};

export default InquiryCard;
