import { useState } from "react";
import styles from "./UserArea.module.css";
import { Outlet } from "react-router-dom";
import ProSidebar from "./common/ProSidebar/ProSidebar";

const UserArea = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <div className={styles.userArea}>
      <ProSidebar isSidebar={isSidebar} />
      <Outlet />
    </div>
  );
};

export default UserArea;
