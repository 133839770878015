import styles from "./PopularLocations.module.css";
import Item from "./PopularLocationsItem/Item";
export default function PopularLocations() {
  return (
    <div className={styles.popularLocations}>
      <div className={styles.title}>Popular Locations</div>
      <div className={styles.grid}>
        <Item />
        {/*<Item />*/}
        {/*<Item />*/}
        {/*<Item />*/}
      </div>
    </div>
  );
}
