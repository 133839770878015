import React from "react";
import FormTextField from "../../../../../../common/components/FormComponents/FormTextField";
import SalutationRadioGroup from "../../../../../../common/components/FormComponents/SalutationRadioGroup";
import styles from "../Newsletter/Newsletter.module.css";
const PersonalDetails = ({ formData, handleChange, style }) => {
  const fields = [
    { label: "Salutation*", component: SalutationRadioGroup, name: "gender" },
    {
      label: "Degree",
      component: FormTextField,
      name: "degree",
      style,
      variant: "standard",
    },
    {
      label: "First Name",
      component: FormTextField,
      name: "firstName",
      style,
      variant: "standard",
    },
    {
      label: "Last Name",
      component: FormTextField,
      name: "lastName",
      required: true,
      style,
      variant: "standard",
    },
    {
      label: "Email",
      component: FormTextField,
      name: "email",
      required: true,
      style,
      variant: "standard",
    },
  ];

  return (
    <div className={styles.formSection}>
      {fields.map(({ label, component: FormComponent, ...props }) => (
        <FormComponent
          key={label}
          label={label}
          value={formData[props.name]}
          onChange={(value) => handleChange(props.name, value)}
          {...props}
        />
      ))}
    </div>
  );
};

export default PersonalDetails;
