import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { miceAxios } from "../../axios/axios";

export const fetchCities = createAsyncThunk("cities/fetchCities", async () => {
  const response = await miceAxios.get("/cities");
  return response.data;
});
const initialState = {
  loading: false,
  error: null,
  success: false,
  cities: [],
};
const citiesSlice = createSlice({
  name: "citiesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
        state.success = true;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default citiesSlice.reducer;
