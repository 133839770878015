import Button from "../../../Button/Button";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import MUISwitch from "../../../MUIComponents/MUISwitch";
import styles from "./SustainableVenuesDropdown.module.css";
import { useSelector, useDispatch } from "react-redux";

export default function SustainableVenuesDropdown({ page }) {
  const dispatch = useDispatch();

  const handleToggle = () => {
    // dispatch(toggleSustainableVenue());
  };

  const containerClassName =
    page === "list"
      ? `${styles.sustainableVenuesContainer} ${styles.list}`
      : styles.sustainableVenuesContainer;
  return (
    <div className={containerClassName}>
      <div className={styles.sustainableVenuesUpperContainer}>
        <div className={styles.sustainableVenuesItem}>
          <SpaOutlinedIcon style={{ color: "var(--clr-wa)" }} />
          <span className={styles.title}>Sustainable Venues</span>
        </div>
        <MUISwitch checked={true} onChange={handleToggle} />
      </div>
      <div className={styles.sustainableVenuesBottomContainer}>
        <Button isInverse={false}>Apply </Button>
      </div>
    </div>
  );
}
