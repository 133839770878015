import styles from "./StayFormText.module.css";
import Title from "../../../common/components/Title/Title";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const StayFormText = ({ title, text1, text2, textArr, text3, color }) => {
  return (
    <div className={`${styles.stayFormTextContainer} ${color}`}>
      <Title text={title} color={color} fw={"700"} />
      <div className={styles.stayFormText}>{text1}</div>
      {/*<div className={styles.stayFormText}>{text2}</div>*/}
      <div className={styles.listContainer}>
        {textArr.map((item, index) => {
          return (
            <div key={index}>
              {!color ? (
                <KeyboardDoubleArrowRightIcon sx={{ color: "#a0abb5" }} />
              ) : (
                <FiberManualRecordIcon
                  sx={{ fontSize: "small", color: "(--clr-purple)" }}
                />
              )}
              <span className={`${styles.stayFormText} ${styles.purple}`}>
                {item.text1}
              </span>
              {item.text2}
            </div>
          );
        })}
      </div>
      <div className={styles.stayFormText}>{text3}</div>
    </div>
  );
};

export default StayFormText;
