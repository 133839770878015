import styles from "./Description.module.css";

export default function Description({ text1, text2 }) {
  return (
    <div className={styles.description}>
      <div className={styles.purple}>{text1}</div>
      {typeof text2 === "object" ? (
        text2.map((amenity) => (
          <div key={amenity.id}>
            {amenity.title ? amenity.title : amenity.name}
          </div>
        ))
      ) : (
        <div>{text2}</div>
      )}
    </div>
  );
}
