import React from "react";
import PersonalDetails from "./PersonalDetails";
import CompanyDetails from "./CompanyDetails";
import DataProtectionCheckbox from "../../../../../../common/components/FormComponents/DataProtection";
import FormSubmitButton from "../../../../../../common/components/FormComponents/FormSubmitButton";
import styles from "../Newsletter/Newsletter.module.css";
const NewsletterForm = ({ formData, handleSubmit, handleChange, style }) => {
  return (
    <form onSubmit={handleSubmit} className={styles.newsletterForm}>
      <PersonalDetails
        formData={formData}
        handleChange={handleChange}
        style={style}
      />
      <CompanyDetails
        formData={formData}
        handleChange={handleChange}
        style={style}
      />
    </form>
  );
};

export default NewsletterForm;
