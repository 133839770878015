import { Navigate } from "react-router-dom";
import { isTokenExpired } from "../features/authentication/services/authServices";
const ProtectedRoute = ({ userInfo, children }) => {
  if (Object.keys(userInfo).length === 0 || isTokenExpired()) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};

export default ProtectedRoute;
