import Title from "../../../common/components/Title/Title";
import React, { useState, useEffect } from "react";
import FormTextField from "../../../common/components/FormComponents/FormTextField";
import useScrollToTop from "../../../hooks/useScrollToTop";
import AccountMessage from "../common/components/AccountMessage";
import RememberForgotSection from "../common/components/RememberForgotSection";
import AuthCard from "../common/components/AuthCard";
import inputStyles from "../common/styles/inputStyles";
import { userLogin } from "../redux/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import styles from "../common/styles/Form.module.css";
import CircularIndeterminate from "../../../common/components/MUIComponents/MUIProgress";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../services/authServices";

const style = {
  color: "var(--clr-pink)",
  fontWeight: "700",
  borderColor: "var(--clr-pink)",
  paddingY: "1rem",
  "&:hover": {
    color: "var(--clr-pink)",
    borderColor: "var(--clr-pink)",
    backgroundColor: "transparent",
  },
  "&:focus": {
    color: "var(--clr-pink)",
    borderColor: "var(--clr-pink)",
  },
};
const Login = () => {
  useScrollToTop();
  const { loading, userInfo, error, loginSuccess } = useSelector(
    (state) => state.auth
  );
  console.log("login success: ", loginSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && !isTokenExpired()) {
      navigate("/");
    } else {
      setAuthCheckDone(true);
    }
  }, [userInfo, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [authCheckDone, setAuthCheckDone] = useState(false);

  const textFieldData = [
    {
      label: "Email",
      value: email,
      onChange: (e) => {
        setEmail(e.target.value);
      },
      type: "text",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => {
        setPassword(e.target.value);
      },
      type: "password",
    },
  ];

  useEffect(() => {
    if (loginSuccess) {
      console.log("TEST NAVIGATE");
      navigate("/");
    }
  }, [loginSuccess]);
  //
  // useEffect(() => {
  //   setErrorMessage(null);
  // }, [email, password]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(userLogin({ email, password }));
  };

  if (!authCheckDone) {
    return <CircularIndeterminate />;
  }

  return (
    <AuthCard>
      <Title text={"Log in"} fw={"700"} color={"pink"} />

      <form onSubmit={handleSubmit} className={styles.form}>
        {textFieldData.map((field, index) => (
          <FormTextField
            key={index}
            variant={"outlined"}
            label={field.label}
            required={true}
            style={inputStyles}
            value={field.value}
            onChange={field.onChange}
            type={field.type}
          />
        ))}

        <RememberForgotSection />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          fullWidth={true}
          sx={style}
          disabled={loading}
        >
          Log In
        </Button>
        {loading && <CircularIndeterminate />}
        {/*{errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}*/}
      </form>
      <AccountMessage login={true} />
    </AuthCard>
  );
};

export default Login;
