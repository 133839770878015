import styles from "./ImageSection.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconContainer from "./components/IconContainer/IconContainer";
import Title from "../../../../../../common/components/Title/Title";
import { Link } from "react-router-dom";
import img1 from "../../../../../../common/assets/designer icons/ikonice/png/ikonice Mice-01.png";
import img2 from "../../../../../../common/assets/designer icons/ikonice/png/ikonice Mice-02.png";
import img3 from "../../../../../../common/assets/designer icons/ikonice/png/ikonice Mice-03.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ImageSection(props) {
  const { name, images, size, ceilingHeight, dimensions } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  const handleLeftArrowClick = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
    );
  };

  const handleRightArrowClick = () => {
    setCurrentImageIndex(
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1
    );
  };

  const currentImage = images[currentImageIndex];
  return (
    <div className={styles.imageSectionContainer}>
      <div className={`${styles.flex} ${styles.backTo}`}>
        <KeyboardArrowLeftIcon
          sx={{ color: "var(--clr-purple)", fontSize: "1rem" }}
        />{" "}
        <span onClick={() => navigate(-1)}>Go Back</span>{" "}
      </div>
      <div className={`${styles.flex} ${styles.guestRoom}`}>
        <KeyboardArrowLeftIcon
          style={{ cursor: "pointer" }}
          onClick={handleLeftArrowClick}
        />{" "}
        <Title text={name} color={"black"} fw={400} />{" "}
        <KeyboardArrowRightIcon
          style={{ cursor: "pointer" }}
          onClick={handleRightArrowClick}
        />{" "}
      </div>
      <img
        className={styles.image}
        src={currentImage.image}
        alt={currentImage.alt_text}
      />
      <div className={styles.iconsContainer}>
        <IconContainer title="Size" subtitle={`${size} m2`} iconPath={img1} />
        <IconContainer
          title="Ceilling Height"
          subtitle={`${ceilingHeight} m`}
          iconPath={img2}
        />
        <IconContainer
          title="Dimensions"
          subtitle={dimensions}
          iconPath={img3}
        />
      </div>
    </div>
  );
}
