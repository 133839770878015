import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import styles from "../styles/AuthStyles.module.css";

const RememberForgotSection = () => {
  return (
    <div className={styles.rememberMe}>
      <div>
        <Checkbox id="rememberMe" />
        <label htmlFor="rememberMe">Remember me</label>
      </div>
      <Typography variant="body2">
        <a href="#">Forgot password?</a>
      </Typography>
    </div>
  );
};

export default RememberForgotSection;
