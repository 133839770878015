import React from "react";
import styles from "./TestimonialCard.module.css";
import Rating from "@mui/material/Rating";

const TestimonialCard = ({ testimonial, type }) => {
  return (
    <div className={styles.container}>
      {type !== "document" ? (
        <React.Fragment>
          {" "}
          <img
            src={testimonial.image}
            alt={testimonial.name}
            className={styles.testimonialImage}
          />
          <div className={styles.review}>
            <div className={styles.name}>
              {testimonial.name}, {testimonial.position}
            </div>
            <div className={styles.stars}>
              <Rating
                name="read-only"
                value={Number(testimonial.stars)}
                readOnly
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={styles.review}>
          <a
            href={testimonial?.document_link}
            rel="noreferrer"
            target={"_blank"}
          >
            <img
              src={testimonial.icon}
              alt={testimonial.icon_alt_text}
              style={{ width: "20px", height: "20px" }}
            />
          </a>

          <div>{testimonial.title}</div>
        </div>
      )}
    </div>
  );
};

export default TestimonialCard;
