import Title from "../../../common/components/Title/Title";
import React, { useState, useEffect, useRef } from "react";
import FormTextField from "../../../common/components/FormComponents/FormTextField";
import useScrollToTop from "../../../hooks/useScrollToTop";
import AccountMessage from "../common/components/AccountMessage";
import AuthCard from "../common/components/AuthCard";
import inputStyles from "../common/styles/inputStyles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../redux/authAction";
import { Button } from "@mui/material";
import styles from "../common/styles/Form.module.css";
import { useNavigate } from "react-router-dom";
import CircularIndeterminate from "../../../common/components/MUIComponents/MUIProgress";

const USER_REGEX = /^[A-Za-z]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const Register = () => {
  const navigate = useNavigate();

  const { loading, userInfo, error, registrationSuccess } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  useScrollToTop();
  //EMAIL
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  //NAME
  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);

  const nameRef = useRef();
  const errRef = useRef();
  //SURNAME
  const [surname, setSurname] = useState("");
  const [validSurname, setValidSurname] = useState(false);
  //PASSWORD
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [birthday, setBirthday] = useState(null);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  useEffect(() => {
    const result = USER_REGEX.test(name);
    setValidName(result);
  }, [name]);

  useEffect(() => {
    const result = USER_REGEX.test(surname);
    setValidSurname(result);
  }, [surname]);
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    const result = PWD_REGEX.test(password);
    setValidPassword(result);
  }, [password]);

  const textFieldData = [
    {
      label: "Name",
      required: true,
      value: name,
      onChange: (e) => {
        setName(e.target.value);
        setValidName(USER_REGEX.test(e.target.value));
      },
      type: "text",
      ref: nameRef,
      focus: setNameFocus,
      autocomplete: "off",
      helperText: "Name must have at least 3 letters.",
      error: !validName && name.length > 0,
    },
    {
      label: "Surname",
      required: true,
      value: surname,
      onChange: (e) => {
        setSurname(e.target.value);
        setValidSurname(USER_REGEX.test(e.target.value));
      },
      type: "text",
      autocomplete: "off",
      helperText: "Surname must have at least 3 letters.",
      error: !validSurname && surname.length > 0,
    },
    {
      label: "Email",
      required: true,
      value: email,
      onChange: (e) => {
        setEmail(e.target.value);
        setValidEmail(EMAIL_REGEX.test(e.target.value));
      },
      type: "text",
      autocomplete: "off",
      helperText: "Enter a valid email address for verification purposes.",
      error: !validEmail && email.length > 0,
    },
    {
      label: "Password",
      required: true,
      value: password,
      onChange: (e) => {
        setPassword(e.target.value);
        setValidPassword(PWD_REGEX.test(e.target.value));
      },
      type: "password",
      autocomplete: "off",
      helperText:
        "Password must be 8-24 characters and include at least one lowercase letter, one uppercase letter, one number, and one special character (!@#$%). ",
      error: !validPassword && password.length > 0,
    },
  ];

  const handleNavigation = () => {
    navigate("/login");
  };

  const style = {
    color: "var(--clr-pink)",
    fontWeight: "700",
    borderColor: "var(--clr-pink)",
    paddingY: "1rem",
    "&:hover": {
      color: "var(--clr-pink)",
      borderColor: "var(--clr-pink)",
      backgroundColor: "transparent",
    },
    "&:focus": {
      color: "var(--clr-pink)",
      borderColor: "var(--clr-pink)",
    },
  };

  const SuccessfulRegistration = () => {
    return (
      <div className={styles.form}>
        <div>You have succesfully signed up to our website!</div>
        <div>Please, log in to continue.</div>
        <Button
          variant="outlined"
          color="primary"
          fullWidth={true}
          sx={style}
          onClick={handleNavigation}
        >
          Log In
        </Button>
      </div>
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validPassword) {
      setErrMsg("Invalid Password");
      setValidPassword(false);

      return;
    } else if (!validName) {
      setErrMsg("Invalid Name");
      setValidName(false);
      return;
    } else if (!validSurname) {
      setErrMsg("Invalid Surname");
      setValidSurname(false);
      return;
    } else if (!validEmail) {
      setErrMsg("Invalid Email");
      setValidEmail(false);
      return;
    }
    const data = {
      name,
      email,
      password,
      surname,
      birthday,
    };
    dispatch(registerUser(data));
  };
  useEffect(() => {
    if (registrationSuccess) {
      // navigate("/login");
    }
    //
    // if (userInfo) navigate("/profile-page");
  }, [registrationSuccess]);
  return (
    <AuthCard>
      <Title text={"Register"} fw={"700"} color={"pink"} />
      {registrationSuccess && <div>user has registered </div>}
      <form onSubmit={handleSubmit} className={styles.form}>
        {textFieldData.map((field, index) => (
          <FormTextField
            key={index}
            variant={"outlined"}
            label={field.label}
            required={true}
            style={inputStyles}
            value={field.value}
            onChange={field.onChange}
            type={field.type}
            {...(field.ref && { ref: field.ref })}
            onFocus={field?.focus}
            onBlur={field?.focus}
            disabled={loading || registrationSuccess}
            autocomplete={field.autocomplete}
            helperText={field.helperText}
            error={field.error}
          />
        ))}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={"Birthday"}
            value={birthday}
            onChange={(newValue) => setBirthday(newValue)}
            sx={inputStyles}
          />
        </LocalizationProvider>

        <Button
          variant="outlined"
          color="primary"
          type="submit"
          fullWidth={true}
          sx={style}
          disabled={loading || registrationSuccess}
        >
          Sign Up
        </Button>
        {loading && <CircularIndeterminate />}
        {registrationSuccess && <SuccessfulRegistration />}
        <p
          ref={errRef}
          className={errMsg ? styles.errmsg : styles.offscreen}
          aria-live={"assertive"}
        >
          {errMsg}
        </p>
      </form>
      <AccountMessage login={false} />
    </AuthCard>
  );
};

export default Register;
