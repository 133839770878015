import Wrapper from "../Wrapper";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useState, useRef } from "react";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const UserDocuments = () => {
  const [documentType, setDocumentType] = useState("application/pdf");
  const [selectedFile, setSelectedFile] = useState([]);
  const inputFileRef = useRef();
  const handleChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile([...selectedFile, event.target.files[0]]);
  };

  const handleUpload = () => {
    console.log("Uploading", selectedFile);
  };

  const handleDownload = (file) => {
    // Create a URL for the file
    const url = URL.createObjectURL(file);

    // Create a temporary anchor element and set its attributes for download
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name || "download"; // Use the file name or a default name
    document.body.appendChild(a); // Append the anchor to the body

    a.click(); // Simulate a click on the anchor

    // Clean up: revoke the object URL and remove the anchor element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleRemove = (file) => {
    const filteredArr = selectedFile.filter((el) => el !== file);
    setSelectedFile(filteredArr);
  };
  const handleUploadButtonClick = () => {
    inputFileRef.current.click();
  };
  const desc =
    "On User Documents page you can review documents necessary for your inquiry. For any questions regarding your account and your inquiries feel free to contact us at mice@montenegromice.me";
  return (
    <Wrapper description={desc} title={"User Documents"}>
      <Typography variant={"h6"}>Important Inquiry Documents</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {" "}
        <FormControl fullWidth>
          <InputLabel id={"document-type"}>Document Type</InputLabel>
          <Select
            labelId={"document-type"}
            id={"documents"}
            value={documentType}
            label={"Document Type"}
            onChange={handleChange}
          >
            <MenuItem value={"application/pdf"}>PDF</MenuItem>
            <MenuItem value={".doc"}>DOC</MenuItem>
            <MenuItem value={"application/rtf"}>RTF</MenuItem>
            <MenuItem value={"application/vnd.oasis.opendocument.text"}>
              ODT
            </MenuItem>
          </Select>
        </FormControl>
        <input
          type="file"
          accept={documentType}
          onChange={handleFileChange}
          ref={inputFileRef}
          style={{ display: "none" }}
        />
        <Button onClick={handleUploadButtonClick}>
          <FileUploadIcon /> UPLOAD Document
        </Button>
      </Stack>
      <Typography variant={"h6"}>Documents</Typography>
      <TableContainer component={Paper}>
        <Table aria-label={"documents-table"}>
          <TableHead>
            <TableRow>
              <TableCell>DOCUMENT </TableCell>
              <TableCell> DOWNLOAD</TableCell>
              <TableCell>OPTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedFile.length !== 0 &&
              selectedFile.map((item) => (
                <TableRow key={Math.random() * Math.random()}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell
                    onClick={() => handleDownload(item)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DownloadIcon /> <span>Download file</span>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemove(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DeleteOutlineIcon /> <span>Remove</span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default UserDocuments;
