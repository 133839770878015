import styles from "./Footer.module.css";
import FullMiceLogo from "../FullMiceLogo/FullMiceLogo";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ReactComponent as Info } from "../../assets/svg-mice/i-2.svg";

const footerColumns = [
  {
    title: "About Us",
    items: ["Team", "News", "BlogListView", "Careers", "Contact Us"],
  },
  {
    title: "For Companies",
    items: [
      "Company account",
      "Marketplace",
      "Workspaces",
      "Safety",
      "Hybrid meetings",
      "Group stays",
      "How it works",
    ],
  },
  {
    title: "For Venues",
    items: ["List Space", "Booking Engine"],
  },
  {
    title: "Resources",
    items: [
      {
        prefix: <Info />,
        text: "White Paper",
      },
      { prefix: <Info />, text: "bp Case Study" },
    ],
  },
];

const socialIcons = [
  { icon: FacebookOutlinedIcon, style: { color: "#80909e" } },
  { icon: LinkedInIcon, style: { color: "#80909e" } },
  { icon: TwitterIcon, style: { color: "#80909e" } },
  { icon: InstagramIcon, style: { color: "#80909e" } },
];

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.upperFooter}>
        <div className={styles.footerCol}>
          <FullMiceLogo />
          <div>www.montenegromice.com</div>
          <div className={styles.footerPhone}>
            <PhoneInTalkIcon /> +382 63 000 000
          </div>
          <div className={styles.footerPhone}>
            <PhoneInTalkIcon /> +44 263 000 0000
          </div>
        </div>
        {footerColumns.map(({ title, items }, columnIndex) => (
          <div className={styles.footerCol} key={columnIndex}>
            <div className={styles.footerTitle}>{title}</div>
            {items.map((item, itemIndex) =>
              typeof item === "string" ? (
                <div key={itemIndex} className={styles.hoverItem}>
                  {item}
                </div>
              ) : (
                <div className={styles.footerPhone} key={itemIndex}>
                  <span>{item.prefix}</span>
                  {item.text}
                </div>
              )
            )}
          </div>
        ))}
      </div>
      <div className={styles.footerBottomContainer}>
        <div className={styles.privacy}>Privacy Policy / Terms</div>
        <div className={styles.footerBottomIconsContainer}>
          {socialIcons.map(({ icon: Icon, style }, index) => (
            <div key={index}>
              <Icon style={style} />
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
}
