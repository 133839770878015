import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Dashboard = () => {
  return (
    <div>
      {" "}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "calc(8px + 1.5625vw)",
        }}
      >
        <Stack spacing={2}>
          <Typography>
            Welcome to Montenegro MICE User Area page! Here you can follow your
            events and talk about them with our agents. For any questions
            regarding your account and your events feel free to contact us at
            contact@mice.me.
          </Typography>
          <Typography variant={"h5"}>Dashboard</Typography>
          <Stack direction={"row"} spacing={2}>
            <div>dashboard</div>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default Dashboard;
