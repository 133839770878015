import styles from "./GetQuote.module.css";
import Title from "../../common/components/Title/Title";
import MUIDateRangePicker from "../../common/components/MUIComponents/MUIDateRangePicker/MUIDateRangePicker";
import React, { useEffect, useState } from "react";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import MUISwitch from "../../common/components/MUIComponents/MUISwitch";
import Summary from "./Summary/Summary";
import CustomAutocomplete from "../../common/components/MUIComponents/CustomAutocomplete";
import Button from "../../common/components/Button/Button";
import PageWrapper from "../../common/components/PageWrapper/PageWrapper";
import FormTextField from "../../common/components/FormComponents/FormTextField";
import Hr from "../../common/components/Hr/Hr";
import CustomStepper from "../../common/components/MUIComponents/MUIStepper";
import useScrollToTop from "../../hooks/useScrollToTop";
const GetQuote = () => {
  useScrollToTop();
  const steps = ["Booking Details", "Dummy Step 1", "Dummy Step 2"];
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (step) => {
    if (step === 0) {
    } else if (step === 1) {
      // Navigate to the next page for Dummy Step 1
      // history.push('/dummy-step-1');
    } else if (step === 2) {
      // Navigate to the next page for Dummy Step 2
      // history.push('/dummy-step-2');
    }
    setActiveStep(step);
  };

  const [location, setLocation] = useState("");
  const [people, setPeople] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [event, setEvent] = useState("");

  const events = [
    {
      value: "event 1",
    },
    {
      value: "event 2",
    },
    {
      value: "event 3",
    },
  ];
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    { label: "Option 4", value: "option4" },
    { label: "Option 5", value: "option5" },
  ];

  const style = {
    color: "var(--clr-purple)",
    "& .MuiInputLabel-root.Mui-focused": {
      color: "var(--clr-purple)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--clr-purple)",
    },
  };
  return (
    <>
      <CustomStepper
        activeStep={activeStep}
        steps={steps}
        handleStepClick={handleStepClick}
      />
      <PageWrapper variant={"getQuote"}>
        <div className={styles.leftContainer}>
          <Title text={"Booking Details"} color={"black"} fw={"500"} />
          <form className={styles.form}>
            <FormTextField
              value={location}
              label={"Location"}
              onChange={setLocation}
              style={style}
              required={true}
              variant={"outlined"}
            />

            <FormTextField
              value={people}
              label={"People"}
              onChange={setPeople}
              style={style}
              required={true}
              variant={"outlined"}
            />

            <div className={styles.marginTop}>
              <MUIDateRangePicker />
            </div>

            <CustomAutocomplete options={options} label={"Purpose of Event"} />
            <div className={styles.flex}>
              <VerifiedUserOutlinedIcon /> Safe spacing
            </div>
            <div className={styles.flex}>
              {" "}
              <MUISwitch />
            </div>

            <div>reCaptcha</div>
            <Button color={"pink"}> Next</Button>
          </form>
        </div>
        <div className={styles.rightContainer}>
          <Summary />
        </div>
      </PageWrapper>
    </>
  );
};

export default GetQuote;
