import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "../../../../../../common/components/Button/Button";
import { Link } from "react-router-dom";

// Define headers
const headers = [
  "",
  "Venue",
  "Venue Type",
  "Location",
  "Rating",
  "Guest Rooms",
  "Meeting Rooms",
  "Largest Room",
  "Meeting Space",
  "",
];

// Sample data (for demonstration purposes)
const data = [
  [
    "https://www.w3schools.com/css/img_5terre.jpg",
    "Venue 1",
    "Type 1",
    "Location 1",
    "5",
    "100",
    "5",
    "50",
    "1000 sqft",
    "Button1",
  ],
  [
    "https://www.w3schools.com/css/img_5terre.jpg",
    "Venue 2",
    "Type 2",
    "Location 2",
    "4",
    "150",
    "6",
    "60",
    "1200 sqft",
    "Button2",
  ],
  [
    "https://www.w3schools.com/css/img_5terre.jpg",
    "Venue 2",
    "Type 2",
    "Location 2",
    "4",
    "150",
    "6",
    "60",
    "1200 sqft",
    "Button2",
  ],

  // ... add more venues as needed
];

// ... (rest of the code)

export default function AccessibleTable({ matchVenues }) {
  const formatVenues = matchVenues
    .sort((a, b) => a.id - b.id)
    .map((venue) => {
      return [
        venue.featured_image,
        venue.name,
        venue.style,
        venue.city,
        venue.general_review_score,
        venue.total_guest_rooms,
        venue.total_number_of_conference_rooms,
        venue.largest_rooms_space,
        `${venue.total_conference_room_space} sqft`,
        venue.id,
      ];
    });
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
        <TableBody>
          {headers.map((header, headerIndex) => (
            <TableRow key={headerIndex}>
              <TableCell
                style={{
                  backgroundColor: "#dde2fb",
                  fontWeight: "600",
                  color: "#151a16",
                  borderBottom: "1px solid rgb(210 208 208)",
                  borderTop:
                    headerIndex === 0
                      ? "1px solid rgba(224, 224, 224, 1)"
                      : "none",
                }}
              >
                {header}
              </TableCell>
              {formatVenues.map((venue) => (
                <TableCell
                  key={venue[9]}
                  align={header === "" ? "center" : "left"}
                  style={
                    header === "Venue"
                      ? { color: "var(--clr-purple)", fontWeight: "bold" }
                      : {}
                  }
                >
                  {headerIndex === 0 ? (
                    <img
                      src={venue[headerIndex]}
                      alt={`Venue ${venue.id + 1}`}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        borderRadius: "1rem 0 1rem 0",
                      }}
                    />
                  ) : header === "" ? (
                    // <Button color={"pink"}>Select Venue</Button>
                    <Link
                      to={`/venues/preview/${venue[headers.length - 1]}`}
                      style={{
                        color: "white",
                        backgroundColor: "var(--clr-pink)",
                        padding: "12px 18px",
                        border: "none",
                        outline: "none",
                        borderRadius: "0.5rem 0 0.5rem 0",
                        cursor: "pointer",
                      }}
                    >
                      Select Venue
                    </Link>
                  ) : (
                    venue[headerIndex]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
