import styles from "./SearchTypeInfo.module.css";

const SearchTypeInfo = ({ type }) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>{type} in</div>
        <div className={`${styles.title} ${styles.purple}`}>Montenegro</div>
      </div>
      <div className={styles.subtitle}>
        Find more than 350 {type} for your events
      </div>
    </div>
  );
};

export default SearchTypeInfo;
