import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MiceLogo from "../../../../common/components/MiceLogo/MiceLogo";
import Item from "./Item/Item";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import ArticleIcon from "@mui/icons-material/Article";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LogoutIcon from "@mui/icons-material/Logout";
import { userLogout } from "../../../authentication/redux/authAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const ProSidebar = ({ isSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(userLogout())
      .then(() => {
        console.log("Logged out, navigating");
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout Error: ", error);
      });
    console.log("LOGOUT");
  };

  const user = jwt_decode(localStorage.getItem("userToken"));

  const menuItems = [
    // { title: "Dashboard", to: "/user-area/dashboard", icon: <DashboardIcon /> },
    { title: "Inquiry", to: "/user-area/offers", icon: <LocalOfferIcon /> },
    { title: "Shortlist", to: "/user-area/shortlist", icon: <FavoriteIcon /> },
    {
      title: "User Documents",
      to: "/user-area/documents",
      icon: <ArticleIcon />,
    },

    {
      title: "User Information",
      to: "/user-area/information",
      icon: <InfoIcon />,
    },
    { title: "Log Out", icon: <LogoutIcon />, onClick: handleLogout },
  ];

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": { background: "var(--clr-pink) !important" },
        "& .pro-icon-wrapper": { backgroundColor: "transparent !important" },
        "& .pro-inner-item": { padding: "5px 35px 5px 20px !important" },
        "& .pro-inner-item:hover": { color: "yellow !important" },
      }}
    >
      <Sidebar collapsed={isCollapsed} style={{ height: "100%" }}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "10px 0 20px 0" }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
                <MiceLogo />
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                {/*<img*/}
                {/*  alt="profile-user"*/}
                {/*  width="100px"*/}
                {/*  height="100px"*/}
                {/*  src={"https://pfps.gg/assets/pfps/3731-.png"}*/}
                {/*  style={{ cursor: "pointer", borderRadius: "50%" }}*/}
                {/*/>*/}
              </Box>
              <Box textAlign="center">
                <Typography variant="h5" sx={{ m: "10px 0 0 0" }}>
                  {user.name} {user.surname}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {menuItems.map((item) => (
              <Item
                key={item.title}
                title={item.title}
                to={item.to}
                icon={item.icon}
                selected={selected}
                setSelected={setSelected}
                onClick={item.onClick}
              />
            ))}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default ProSidebar;
