import LeafletMap from "../../../../../../common/components/LeafletMap/LeafletMap.jsx";
import SectionWrapper from "../../../../../../common/components/SectionWrapper/SectionWrapper";
import BasicTabs from "../../../../../../common/components/MUIComponents/MUITabs";
import Title from "../../../../../../common/components/Title/Title";
import CollapsibleTable from "../../../../../../common/components/MUIComponents/CollapsibleTable";
import LocalAttractionsBlogsSection from "../LocalAttractionsBlogsSection/LocalAttractionsBlogsSection";
import React from "react";
import { useSelector } from "react-redux";
import Links from "../Links/Links.jsx";
import TestimonialCard from "./TestimonialCard/TestimonialCard";

const MapSection = React.forwardRef((props, ref) => {
  const { distance } = useSelector((state) => state.venue);
  const { name, altitude, longitude, socialMedia, venue } = props;

  const tabs = [
    {
      label: "Map",
      component: (
        <div className={"leaflet-container-preview-page"}>
          <LeafletMap
            isGray={false}
            markers={[
              {
                geocode: [altitude, longitude],
                popUp: name,
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: "Distance",
      component: (
        <div style={{ width: "100%" }}>
          <CollapsibleTable distance={distance} />
        </div>
      ),
    },
    {
      label: "Testimonials",
      component: (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {venue.testimonials.map((testimonial) => {
            return (
              <TestimonialCard
                testimonial={testimonial}
                key={testimonial.id}
                type={"testimonial"}
              />
            );
          })}
        </div>
      ),
    },
    {
      label: "Documentations",
      component: (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {venue.documentations.map((document) => {
            return (
              <TestimonialCard
                testimonial={document}
                key={document.id}
                type={"document"}
              />
            );
          })}
        </div>
      ),
    },
    {
      label: "Local Attractions",
      component: <LocalAttractionsBlogsSection />,
    },
    {
      label: "Links",
      component: <Links socialMedia={socialMedia} />,
    },
  ];
  return (
    <SectionWrapper variant={"alignItemsFlexStart"} ref={ref}>
      <Title text={"More"} />
      <BasicTabs tabs={tabs} boxShadow={false} isFullWidth={true} />
    </SectionWrapper>
  );
});

export default MapSection;
