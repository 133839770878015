import LinearGradient from "../../../../../../common/components/LinearGradient/LinearGradient";
import styles from "./BlogHeaderImage.module.css";

const BlogHeaderImage = ({ src, alt }) => (
  <div className={styles.imageContainer}>
    <img src={src} alt={alt} />
    <LinearGradient />
  </div>
);

export default BlogHeaderImage;
