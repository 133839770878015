import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

const CustomStepper = ({ activeStep, steps, handleStepClick }) => {
  return (
    <Box sx={{ width: "100%", marginBottom: 2 }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={() => handleStepClick(index)}
              sx={{
                "&.MuiStepButton-root": {
                  color: "var(--clr-purple)", // Default color
                },
                "&.Mui-active": {
                  color: "purple", // Active step color
                },
                "& .MuiStepIcon-root.MuiStepIcon-active": {
                  color: "purple", // Active step icon color
                },
                "& .MuiStepIcon-root.MuiStepIcon-completed": {
                  color: "purple", // Completed step icon color
                },
              }}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
