import { MenuItem } from "react-pro-sidebar";
import Typography from "@mui/material/Typography";
import { Link, Route } from "react-router-dom";
import Dashboard from "../../../dashboard/Dashboard";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  return (
    <Link to={to}>
      <MenuItem
        active={selected === title}
        style={{
          color: "var(--clr-pink)",
          fontWeight: "bold",
        }}
        onClick={title === "Log Out" ? onClick : () => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Link>
  );
};

export default Item;
