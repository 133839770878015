import "./customizedImageGallery.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";

const renderSlide = (item) => {
  return (
    <div className="custom-slide">
      <img src={item.original} alt={item.originalAlt} />
      <p className="react-gallery-title">{item.title}</p>
    </div>
  );
};

const CustomLeftArrow = (props) => {
  const handleClick = () => {
    props.onClick();
  };
  return (
    <button className="custom-arrow custom-left-arrow" onClick={handleClick}>
      <div className="arrow-container">
        <KeyboardArrowLeftIcon style={{ color: "white" }} fontSize="large" />
      </div>
    </button>
  );
};

const CustomRightArrow = (props) => {
  return (
    <button className="custom-arrow custom-right-arrow" onClick={props.onClick}>
      <div className="arrow-container">
        <KeyboardArrowRightIcon style={{ color: "white" }} fontSize="large" />
      </div>
    </button>
  );
};

const CustomizedImageGallery = ({ handleClose, images, handleBack }) => {
  const formatImages = images.map((image) => {
    return {
      original: image.path,
      thumbnail: image.path,
      title: image.name,
    };
  });
  return (
    <>
      <div className={"imageGalleryCloseBackContainer"}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            color: "white",
            zIndex: "200",
            // position: "absolute",
            // top: "20px",
            // right: "20px",
            cursor: "pointer",
          }}
        />
        <button className={"BackGalleryButton"} onClick={handleBack}>
          Back to galleries
        </button>
      </div>
      <ImageGallery
        items={formatImages}
        renderItem={renderSlide}
        showFullscreenButton={false}
        showPlayButton={false}
        renderLeftNav={(onClick) => <CustomLeftArrow onClick={onClick} />}
        renderRightNav={(onClick) => <CustomRightArrow onClick={onClick} />}
      />
    </>
  );
};

export default CustomizedImageGallery;
