import { Carousel } from "react-responsive-carousel";
import SlideContent from "./SlideContent/SlideContent";
import "./customizedCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function CustomizedCarousel() {
  return (
    <div className={"customized-carousel-container"}>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showThumbs={false}
      >
        <SlideContent
          title="What our clients say..."
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa dolore iusto cumque molestias accusamus, aliquam delectus labore adipisci tenetur. Quos facere quis optio unde ipsum officiis fugiat distinctio soluta officia. At accusamus laboriosam dicta. Fuga deserunt, quam eius facere inventore placeat animi maxime, quidem tenetur sint ex recusandae ad laborum!"
          name="John Doe"
          subtitle="CEO of Company"
        />
        <SlideContent
          title="What our clients say..."
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa dolore iusto cumque molestias accusamus, aliquam delectus labore adipisci tenetur. Quos facere quis optio unde ipsum officiis fugiat distinctio soluta officia. At accusamus laboriosam dicta. Fuga deserunt, quam eius facere inventore placeat animi maxime, quidem tenetur sint ex recusandae ad laborum!"
          name="Petar Petrovic"
          subtitle="CEO of Company"
        />
        <SlideContent
          title="What our clients say..."
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa dolore iusto cumque molestias accusamus, aliquam delectus labore adipisci tenetur. Quos facere quis optio unde ipsum officiis fugiat distinctio soluta officia. At accusamus laboriosam dicta. Fuga deserunt, quam eius facere inventore placeat animi maxime, quidem tenetur sint ex recusandae ad laborum!"
          name="Marko Markovic"
          subtitle="CEO of Company"
        />
      </Carousel>
    </div>
  );
}
