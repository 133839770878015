import ListItemsSection from "./sections/ListitemsSection/ListItemsSection";
import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import Footer from "../../../../common/components/Footer/Footer";
import Hr from "../../../../common/components/Hr/Hr";
import { useEffect, useState } from "react";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import ToggleCard from "./ToggleCard/ToggleCard";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ListPage.module.css";
import LeafletMap from "../../../../common/components/LeafletMap/LeafletMap";
import { fetchEventTypes } from "../../../../redux/slices/eventTypeSlice";
import { fetchWorkspaces } from "../../../../redux/workspace/workspaceSearch";
import { saveParams } from "../../../../redux/workspace/updateSearchParamsWorkspace";
import useScrollToTop from "../../../../hooks/useScrollToTop";

const ListPageCoworking = () => {
  useScrollToTop();

  const dispatch = useDispatch();

  //const coworking = useSelector((state) => state.coworkingSearchResults.venues);
  // const loading = useSelector((state) => state.venueSearchResults.loading);
  // const error = useSelector((state) => state.venueSearchResults.error);

  const events = useSelector((state) => state.eventTypes.eventTypes);

  const [location, setLocation] = useState(
    useSelector((state) => state.workspace.searchParameters?.location)
  );
  const [numOfPersons, setNumOfPersons] = useState(
    useSelector((state) => state.workspace.searchParameters?.attendees)
  );
  const [search, setSearch] = useState(false);

  useEffect(() => {
    const params = {
      location: location,
      numOfPersons: numOfPersons,
    };
    dispatch(saveParams(params));
    dispatch(fetchWorkspaces(params));
    setSearch(false);
  }, [dispatch, search]);

  const workspaces = useSelector((state) => state.workspace.searchResults);

  return (
    <>
      <Hr />
      <PageWrapper variant={"list"}>
        <ToggleCard
          background={false}
          page={"list"}
          location={location}
          setLocation={setLocation}
          numOfPersons={numOfPersons}
          setNumOfPersons={setNumOfPersons}
          search={search}
          setSearch={setSearch}
        />
        {workspaces.length !== 0 ? (
          <div className={styles.listMapContainer}>
            <ListItemsSection items={workspaces} />
            <div className={"list-page"}>
              <LeafletMap isGray={false} />
            </div>
          </div>
        ) : (
          <div>No venues for these search filters </div>
        )}
      </PageWrapper>
      <PopularLocations />
      <Footer />
    </>
  );
};

export default ListPageCoworking;
