import React from "react";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import MUISwitch from "../../../../MUIComponents/MUISwitch";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import styles from "./BookingTypeRow.module.css";
import { useSelector, useDispatch } from "react-redux";

const BookingTypeRow = ({ type }) => {
  const dispatch = useDispatch();

  const bookOnlineText = [
    "Book Online",
    "(1-40 people)",
    "Book now and pay via credit card.",
  ];
  const getQuotesText = [
    "Get Quotes",
    "(Unlimited People / Bedrooms)",
    "Shortlist venues and get quotes.",
  ];
  const iconStyle = { fontSize: "1rem", color: "var(--clr-gray-800)" };

  const isChecked = type === "Book Online" || type === "Get Quotes";

  const handleSwitchChange = () => {
    if (type === "Book Online") {
      //  dispatch(setBookingType("online"));
    } else if (type === "Get Quotes") {
      //dispatch(setBookingType("quote"));
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.subContainer}>
          {type === "Book Online" && <CreditScoreOutlinedIcon sx={iconStyle} />}{" "}
          {type === "Get Quotes" && <ShoppingCartOutlinedIcon sx={iconStyle} />}{" "}
          <span className={styles.mainSpan}>
            {type === "Book Online" && bookOnlineText[0]}{" "}
            {type === "Get Quotes" && getQuotesText[0]}{" "}
          </span>
          <span>
            {type === "Book Online" && bookOnlineText[1]}{" "}
            {type === "Get Quotes" && getQuotesText[1]}{" "}
          </span>
        </div>
        <span>
          {type === "Book Online" && bookOnlineText[2]}{" "}
          {type === "Get Quotes" && getQuotesText[2]}{" "}
        </span>
      </div>
      <div>
        <MUISwitch checked={isChecked} onChange={handleSwitchChange} />
      </div>
    </div>
  );
};

export default BookingTypeRow;
