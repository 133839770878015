import ButtonGroup from "./ButtonGroup/ButtonGroup";
import VenuesTab from "../VenuesTab/VenuesTab";
import styles from "./FinalSearchTab.module.css";
import { useSelector } from "react-redux";
import UnitTab from "../UnitTab/UnitTab";
const FinalSearchTab = ({ display, isList }) => {
  const searchType = useSelector((state) => state.selected.selected);

  return (
    <div className={styles.searchTabContainer}>
      <ButtonGroup display={display} isList={isList} />
      {searchType === "Venues" && <VenuesTab isList={isList} />}
      {searchType === "Workspace" && (
        <UnitTab unitType="Workspace" isList={isList} />
      )}
      {searchType === "Meeting" && (
        <UnitTab unitType="Meeting" isList={isList} />
      )}
      {searchType === "Stay" && <UnitTab unitType="Stay" isList={isList} />}
    </div>
  );
};

export default FinalSearchTab;
