import styles from "./ShortlistedVenueCard.module.css";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ShortlistedVenueCardText from "../ShortlistedVenueCardText/ShortlistedVenueCardText";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useDispatch, useSelector } from "react-redux";
import { removeVenueFromShortlist } from "../../../../redux/slices/shortlistSlice";
const ShortlistedVenueCard = ({ venue }) => {
  const dispatch = useDispatch();
  const iconStyle = {
    color: "var(--clr-gray-280)",
    cursor: "pointer",
  };
  const handleRemove = () => {
    dispatch(removeVenueFromShortlist(venue));
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {venue ? (
          <img
            src={venue.featured_image}
            alt="venue"
            className={styles.cardImage}
          />
        ) : (
          <div className={styles.emptyCardContainer}>
            {" "}
            <ControlPointOutlinedIcon style={iconStyle} />{" "}
          </div>
        )}

        <ShortlistedVenueCardText venue={venue} handleRemove={handleRemove} />
      </div>
      {venue && (
        <div>
          <RemoveCircleOutlineOutlinedIcon
            style={iconStyle}
            onClick={handleRemove}
          />
        </div>
      )}
    </div>
  );
};

export default ShortlistedVenueCard;
