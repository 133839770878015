import Header from "../../common/components/Header/Header";
import PopularLocations from "../../common/components/PopularLocations/PopularLocations";
import Footer from "../../common/components/Footer/Footer";
import Blog from "../../features/blog/common/components/BlogListView/BlogListView";
import Help from "../../common/components/Help/Help";
import StayFormText from "./StayFormText/StayFormText";
import ConciergeEnquiryForm from "./ConciergeEnquiryForm/ConciergeEnquiryForm";
import CustomizedCarousel from "../../common/components/CustomizedCarousel/CustomizedCarousel";
import { useEffect } from "react";
import PageWrapper from "../../common/components/PageWrapper/PageWrapper";
const StayForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const title = "Book your venue and organise your events with our support";
  const str1 =
    "From sourcing the perfect venue, to running the event on the day, our in-house concierge team can help you plan and deliver the ideal event experience";
  const str2 = "Why use our services:";
  const textArr = [
    { text1: "Free", text2: " We offer a free venue finding service" },
    {
      text1: "Time saving:",
      text2:
        "We can save you time by recommending the most suitable venues for your events",
    },
    {
      text1: "Expertise:",
      text2:
        "Concierge is run by an experienced team od events managers, venue finders and marketers with direct relationship with venues and suppliers",
    },
  ];
  const str3 =
    "If you have a venue finding or event management enquiry, please tell us, as much about it as possible in the enquiry form below and one of the team member will contact you.";
  return (
    <>
      <PageWrapper variant={"stayForm"}>
        <Header />
        <StayFormText
          title={title}
          text1={str1}
          text2={str2}
          textArr={textArr}
          text3={str3}
          color={""}
        />
        <ConciergeEnquiryForm />
        <CustomizedCarousel />
        <Help />
        <Blog />
      </PageWrapper>
      <PopularLocations />
      <Footer />
    </>
  );
};

export default StayForm;
