import styles from "./ShortlistCard.module.css";

const ShortlistCard = ({ venue }) => {
  return (
    <div className={styles.shortlistedCard}>
      <img
        src={venue.featured_image}
        className={styles.image}
        alt={"shortlisted"}
      />

      <div>{venue.name}</div>
    </div>
  );
};

export default ShortlistCard;
