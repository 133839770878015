import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  searchParameters: localStorage.getItem("searchParameters")
    ? JSON.parse(localStorage.getItem("searchParameters"))
    : {
        location: "",
        startDate: new Date(),
        endDate: new Date(),
        attendees: 0,
        checkBookingOnline: false,
        checkGetQuotes: false,
      },
  searchResults: [],
  loading: false,
  error: null,
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: initialStateValue,
  reducers: {
    updateSearchParameters: (state, action) => {
      state.searchParameters = action.payload;
    },
    startSearch: (state) => {
      state.loading = true;
      state.error = null;
    },
    saveSearchResults: (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    },
    searchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { updateSearchParameters, startSearch, saveSearchResults, searchError } = workspaceSlice.actions;
export default workspaceSlice.reducer;
