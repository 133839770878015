import Wrapper from "../Wrapper";
import { miceAxios } from "../../../axios/axios";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import InquiryCard from "./InquiryCard/InquiryCard";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import styles from "./Inquiry.module.css";
const Inquiry = () => {
  const user = jwt_decode(localStorage.getItem("userToken"));
  const [inquiries, setInquiries] = useState([]);
  const [gridView, setGridView] = useState(false);

  const onViewClick = () => {
    setGridView(!gridView);
  };

  useEffect(() => {
    console.log("test");
    const fetchData = async () => {
      try {
        const response = await miceAxios.post("/search-by-user", {
          user_id: Number(user.user_id),
        });
        if (response && response.data) {
          //console.log(response.data);
          setInquiries(response.data);
        }
      } catch (error) {
        console.log("ERROR INQUIRY: ", error);
      }
    };

    fetchData();
  }, []);
  const desc =
    "On Inquiry page you can find all of your active and archived inquires between you and our agents. For any questions regarding your account and your bookings feel free to contact us at mice@montenegromice.me";
  return (
    <Wrapper description={desc} title={"Inquiry"}>
      {gridView ? (
        <GridViewIcon
          sx={{
            color: "var(--clr-pink)",
            cursor: "pointer",
          }}
          onClick={onViewClick}
        />
      ) : (
        <ViewListIcon
          sx={{
            color: "var(--clr-pink)",
            cursor: "pointer",
          }}
          onClick={onViewClick}
        />
      )}
      <div className={gridView ? styles.grid : styles.list}>
        {inquiries.map((inquiry) => (
          <InquiryCard key={inquiry.id} inquiry={inquiry} isGrid={gridView} />
        ))}
      </div>
    </Wrapper>
  );
};

export default Inquiry;
