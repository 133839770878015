import { createSelector } from "@reduxjs/toolkit";

const selectBlogsState = (state) => state.blogs;

export const selectAllBlogs = createSelector(
  [selectBlogsState],
  (blogsState) => blogsState.blogs
);

export const selectBlogsLoading = createSelector(
  [selectBlogsState],
  (blogsState) => blogsState.loading
);

export const selectBlogsError = createSelector(
  [selectBlogsState],
  (blogsState) => blogsState.error
);

/*factory selector*/
export const selectBlogById = createSelector(
  [
    selectAllBlogs,
    (state) => state.blogs.loading,
    (state) => state.blogs.error,
  ],
  (blogs, loading, error) => (id) => {
    return {
      blog: blogs.find((blog) => Number(blog.id) === Number(id)),
      loading,
      error,
    };
  }
);
