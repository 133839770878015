import styles from "./StickyNavbar.module.css";
import React, { useEffect, useState } from "react";

import CollectionsIcon from "@mui/icons-material/Collections";
import InfoIcon from "@mui/icons-material/Info";
import RoomIcon from "@mui/icons-material/Room";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GroupsIcon from "@mui/icons-material/Groups";

const iconStyle = {
  color: "var(--clr-purple)",
};

const StickyNavbar = ({
  mapSectionRef,
  overviewSectionRef,
  mediaGalleryRef,
  conferenceRoomsRef,
  guestRoomsRef,
  meetingRoomsRef,
}) => {
  const handleScrollToSection = (sectionRef) => {
    //sectionRef.current.scrollIntoView({ behavior: "smooth" });
    const sectionTop = sectionRef.current.getBoundingClientRect().top;
    const currentScrollPos = window.scrollY;
    const newScrollPos = currentScrollPos + sectionTop - 90;
    window.scrollTo({ top: newScrollPos, behavior: "smooth" });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 550);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={styles.stickyNavbar}>
      <div onClick={() => handleScrollToSection(overviewSectionRef)}>
        {isMobile ? <InfoIcon sx={iconStyle} /> : "Overview"}
      </div>
      <div onClick={() => handleScrollToSection(mediaGalleryRef)}>
        {isMobile ? <CollectionsIcon sx={iconStyle} /> : "Media Gallery"}
      </div>
      {conferenceRoomsRef && (
        <div onClick={() => handleScrollToSection(conferenceRoomsRef)}>
          {isMobile ? <MeetingRoomIcon sx={iconStyle} /> : "Conference Rooms"}
        </div>
      )}
      {meetingRoomsRef && (
        <div onClick={() => handleScrollToSection(meetingRoomsRef)}>
          {isMobile ? <GroupsIcon sx={iconStyle} /> : "Meeting Rooms"}
        </div>
      )}
      {guestRoomsRef && (
        <div onClick={() => handleScrollToSection(guestRoomsRef)}>
          {isMobile ? <RoomIcon sx={iconStyle} /> : "Guest Rooms"}
        </div>
      )}
      {/*<div>Meeting Rooms</div>*/}
      <div onClick={() => handleScrollToSection(mapSectionRef)}>
        {" "}
        {isMobile ? <MoreHorizIcon sx={iconStyle} /> : "More"}
      </div>
    </div>
  );
};

export default StickyNavbar;
