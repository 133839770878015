import ListItemsSection from "./sections/ListitemsSection/ListItemsSection";
import PopularLocations from "../../../../common/components/PopularLocations/PopularLocations";
import Footer from "../../../../common/components/Footer/Footer";
import Hr from "../../../../common/components/Hr/Hr";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../common/components/PageWrapper/PageWrapper";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ListPage.module.css";
import LeafletMap from "../../../../common/components/LeafletMap/LeafletMap";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { fetchVenues } from "../../../../redux/venues/venuesSearch";
import EventForm from "../../../../pages/EventForm/EventForm";
import FinalSearchTab from "../../components/FinalSearchTab/FinalSearchTab";
import CircularIndeterminate from "../../../../common/components/MUIComponents/MUIProgress";

const ListPage = () => {
  useScrollToTop();
  const isVenues = true;
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [venueId, setVenueId] = useState();
  const venues = useSelector((state) => state.venues.searchResults);

  const filteredVenues = venues.filter((venue) => venue.is_public);
  const type = useSelector((state) => state.selected.selected);

  useEffect(() => {
    let str;
    let url;
    if (type === "Venues") {
      str = "venues";
      url = "search-venues";
    } else {
      str = type;
      url = "search-by-unit-type";
    }
    const storedSearchParamsString = localStorage.getItem(`${str}SearchParams`);

    dispatch(fetchVenues(JSON.parse(storedSearchParamsString), url, str));

    setSearch(false);
  }, [dispatch, search]);

  const markers = Array.isArray(venues)
    ? filteredVenues
        .filter((venue) => venue.altitude && venue.longitude)
        .map((venue) => ({
          geocode: [parseFloat(venue.altitude), parseFloat(venue.longitude)],
          popUp: venue.name,
        }))
    : [];

  const handleOpen = (id) => {
    setOpen(!open);
    setVenueId(id);
  };

  const venue = Array.isArray(filteredVenues)
    ? filteredVenues.filter((venue) => venue.id === venueId)
    : [];
  const events = venue[0]?.events?.map((event) => event.title);
  const loading = useSelector((state) => state.venues.loading);

  if (loading) return <CircularIndeterminate />;

  return (
    <>
      <Hr />
      <PageWrapper variant={"list"}>
        <FinalSearchTab isList={true} />
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            {isVenues && filteredVenues.length !== 0 && (
              <div className={styles.listMapContainer}>
                <ListItemsSection
                  items={filteredVenues}
                  handleOpen={handleOpen}
                  activeTab={"Venues"}
                />
                <div className={"list-page"} style={{ flex: "3" }}>
                  <LeafletMap isGray={false} markers={markers} />
                </div>
              </div>
            )}
            {isVenues && filteredVenues.length === 0 && (
              <div>No venues for these search filters</div>
            )}
          </>
        )}
      </PageWrapper>

      <PopularLocations />
      <Footer />
      <EventForm
        open={open}
        handleOpen={handleOpen}
        venueId={venueId}
        events={events}
      />
    </>
  );
};

export default ListPage;
