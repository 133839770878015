import { Link } from "react-router-dom";
import styles from "./FullMiceLogo.module.css";

export default function FullMiceLogo() {
  return (
    <Link to="/" style={{ textDecoration: "none", color: "black" }}>
      <div className={styles.logoContainer}>
        <div className={styles.montenegro}>MONTENEGRO</div>
        <div className={styles.miceLetters}>
          <span className={styles.miceSpan}>M</span>{" "}
          <div className={`${styles.circle} ${styles.yellow}`}></div>
          <span className={styles.miceSpan}>I</span>{" "}
          <div className={`${styles.circle} ${styles.pink}`}></div>
          <span className={styles.miceSpan}>C</span>{" "}
          <div className={`${styles.circle} ${styles.purple}`}></div>
          <span className={styles.miceSpan}>E</span>
        </div>
      </div>
    </Link>
  );
}
