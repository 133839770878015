import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import styles from "../VenuesTab/Tab.module.css";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import inputStyle from "../../../../common/styles/inputStyle";
import { updateUnitsSearchParameters } from "../../../../redux/units/units";
import { fetchUnits } from "../../../../redux/units/unitsSearch";
import { fetchVenues } from "../../../../redux/venues/venuesSearch";

const UnitTab = ({ unitType, isList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.cities.cities);
  const citiesOptions = useMemo(
    () =>
      cities.map((city) => ({
        label: city.name,
        value: city.id,
      })),
    [cities]
  );

  const [selectedCity, setSelectedCity] = useState([]);
  const [numPersons, setNumPersons] = useState(0);
  const [numBeds, setNumBeds] = useState(unitType === "Stay" ? 0 : undefined);

  useEffect(() => {
    if (isList) {
      const storedSearchParamsString = localStorage.getItem(
        `${unitType}SearchParams`
      );
      if (storedSearchParamsString) {
        const storedSearchParams = JSON.parse(storedSearchParamsString);

        // Set City
        const storedCityId = storedSearchParams?.city;

        // if (storedCityId) {
        //   const matchingCity = citiesOptions.find(
        //     (cityOption) => Number(cityOption.value) === Number(storedCityId)
        //   );
        //
        //   if (matchingCity) setSelectedCity(matchingCity);
        // }
        if (storedCityId) {
          // const matchingCity = citiesOptions.find(
          //   (cityOption) => Number(cityOption.value) === Number(storedCityID)
          // );
          // console.log("MATCHING CITY", matchingCity);
          // if (matchingCity) setSelectedCity(matchingCity);
          const matchingCities = citiesOptions.filter((cityOption) =>
            storedCityId.includes(cityOption.value)
          );
          setSelectedCity(matchingCities);
        }

        // Set Attendees
        if (storedSearchParams.total_number_of_people)
          setNumPersons(Number(storedSearchParams.total_number_of_people));
        if (storedSearchParams.total_guest_rooms) {
          setNumBeds(Number(storedSearchParams.total_guest_rooms));
        }
      }
    }
  }, [isList]);

  const handleUnitSearch = () => {
    const mappedCities = selectedCity.map((city) => city.value);
    let searchParams = {
      city: selectedCity ? mappedCities : [],
    };

    switch (unitType) {
      case "Meeting":
        searchParams = {
          ...searchParams,
          unit_types: "Meeting room",
          total_number_of_people: Number(numPersons),
        };
        break;
      case "Workspace":
        searchParams = {
          ...searchParams,
          unit_types: "Conference room",
          total_number_of_people: Number(numPersons),
        };
        break;
      case "Stay":
        searchParams = {
          ...searchParams,
          unit_types: "Guest room",
          total_guest_rooms: numBeds,
        };
        break;
      default:
    }

    localStorage.setItem(
      `${unitType}SearchParams`,
      JSON.stringify(searchParams)
    );

    dispatch(updateUnitsSearchParameters(searchParams));

    dispatch(fetchVenues(searchParams, "search-by-unit-type", unitType));
    if (!isList) navigate("/search");
  };
  const handleResetFilters = () => {
    setSelectedCity([]);
    setNumPersons(0);
    setNumBeds(0);

    localStorage.removeItem(`${unitType}SearchParams`);
  };

  return (
    <div className={styles.container}>
      <Autocomplete
        multiple
        limitTags={1}
        sx={inputStyle}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={citiesOptions}
        value={selectedCity}
        getOptionLabel={(option) => option.label}
        onChange={(_, value) => setSelectedCity(value)}
        renderInput={(params) => <TextField {...params} label="Location" />}
      />

      {unitType !== "Stay" && (
        <TextField
          id={`${unitType}-number-of-persons`}
          label="Number of Persons"
          type="number"
          sx={inputStyle}
          value={numPersons}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: "0" }}
          onChange={(e) => setNumPersons(e.target.value)}
        />
      )}
      {unitType === "Stay" && (
        <TextField
          id={`${unitType}-number-of-guest-rooms`}
          label="Number of Guest Rooms"
          type="number"
          sx={inputStyle}
          value={numBeds}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: "0" }}
          onChange={(e) => setNumBeds(e.target.value)}
        />
      )}

      <button
        className={`${styles.btn} ${styles.reverse}`}
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>
      <button className={styles.btn} onClick={handleUnitSearch}>
        Find {unitType}
      </button>
    </div>
  );
};

export default UnitTab;
