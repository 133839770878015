import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

const DataProtectionCheckbox = () => {
  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          "&.Mui-focused": { color: "var(--clr-purple)" },
        }}
      >
        Data Protection*
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox required />}
          sx={{ color: "var(--clr-gray-280)" }}
          label="A good partnership also includes the mutual promise to use the personal data of common customers in a responsible way."
        />
      </FormGroup>
    </FormControl>
  );
};

export default DataProtectionCheckbox;
