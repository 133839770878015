const style = {
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--clr-purple)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--clr-purple)",
    fontFamily: "'Montserrat', sans-serif",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "'Montserrat', sans-serif",
    color: "var(--clr-gray-500)",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0.5rem 0 0.5rem 0",
    minWidth: 200,
    maxWidth: 400,
    margin: "0",
    fontFamily: "'Montserrat', sans-serif",
  },
};

export default style;
