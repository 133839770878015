import styles from "./ButtonGroup.module.css";
import { ReactComponent as DoorIcon } from "../../../../../common/assets/svg-mice/door.svg";
import { ReactComponent as HomeIcon } from "../../../../../common/assets/svg-mice/home.svg";
import { ReactComponent as PersonIcon } from "../../../../../common/assets/svg-mice/user.svg";
import { ReactComponent as ActiveDoorIcon } from "../../../../../common/assets/svg-mice/white-door.svg";
import { ReactComponent as ActiveHomeIcon } from "../../../../../common/assets/svg-mice/white-home.svg";
import { ReactComponent as ActivePersonIcon } from "../../../../../common/assets/svg-mice/white-user.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "../../../../../redux/selected";
import { useLocation, useNavigate } from "react-router-dom";

const buttons = [
  { icon: <DoorIcon />, activeIcon: <ActiveDoorIcon />, name: "Venues" },
  { icon: <DoorIcon />, activeIcon: <ActiveDoorIcon />, name: "Workspace" },
  { icon: <PersonIcon />, activeIcon: <ActivePersonIcon />, name: "Meeting" },
  { icon: <HomeIcon />, activeIcon: <ActiveHomeIcon />, name: "Stay" },
];

const ButtonGroup = ({ display, isList }) => {
  const selected = useSelector((state) => state.selected.selected);
  const [isActive, setIsActive] = useState(() => {
    if (selected === "Venues") return 0;
    if (selected === "Workspace") return 1;
    if (selected === "Meeting") return 2;
    if (selected === "Stay") return 3;
  });
  const dispatch = useDispatch();
  const handleTabClick = (index, name) => {
    setIsActive(index);
    dispatch(setSelected(name));
  };

  return (
    <div className={styles.buttonGroup}>
      <table className={styles.buttonGroupTable}>
        <tbody>
          <tr className={styles.tableRow}>
            {buttons.map((button, index) => {
              const classNames = [styles.btn];
              if (isList) classNames.push(styles.list);
              if (display === "mobile" && !isList)
                classNames.push(styles.mobile);
              if (index === 0 && !isList) classNames.push(styles.first);
              if (index === buttons.length - 1 && !isList)
                classNames.push(styles.last);
              if (index === isActive) {
                classNames.push(styles.active);
              }

              return (
                <td
                  key={index}
                  className={classNames.join(" ")}
                  onClick={() => handleTabClick(index, button.name)}
                >
                  {(display === "mobile" && index === isActive) ||
                  (isList && index === isActive)
                    ? button.activeIcon
                    : button.icon}{" "}
                  {button.name}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ButtonGroup;
