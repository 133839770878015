import styles from "./ShortlistedVenueCardText.module.css";
import { Link } from "react-router-dom";

const ShortlistedVenueCardText = ({ venue, handleRemove }) => {
  return (
    <div className={styles.container}>
      {venue ? (
        <>
          <div className={styles.title}>{venue.name}</div>
          <div className={styles.buttonsContainer}>
            <Link to={`/venues/preview/${venue.id}`}>
              <div className={styles.viewButton}>View</div>
            </Link>
            <div className={styles.removeButton} onClick={handleRemove}>
              Remove
            </div>
          </div>
        </>
      ) : (
        <div className={styles.removeButton}>Shortlist Venue</div>
      )}
    </div>
  );
};

export default ShortlistedVenueCardText;
