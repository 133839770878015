import styles from "./Item.module.css";

const Item = () => {
  const arr = ["Splendid", "Hilton", "Splendid"];
  return (
    <div className={styles.item}>
      {arr.map((item, index) => {
        return <div key={index}>{item}</div>;
      })}
    </div>
  );
};

export default Item;
