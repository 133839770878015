import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const SalutationRadioGroup = ({ gender, handleGenderChange }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          color: "var(--clr-purple)",
          "&.Mui-focused": { color: "var(--clr-purple)" },
        }}
      >
        Salutation*
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="gender"
        value={gender}
        onChange={handleGenderChange}
        required={true}
        sx={{
          "& .Mui-checked": {
            color: "var(--clr-purple)",
          },
        }}
      >
        <FormControlLabel value="Mr." control={<Radio />} label="Mr." />
        <FormControlLabel value="Mrs." control={<Radio />} label="Mrs." />
      </RadioGroup>
    </FormControl>
  );
};
export default SalutationRadioGroup;
