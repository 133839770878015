import styles from "./WhenDropdown.module.css";
import MUIDateRangePicker from "../../MUIComponents/MUIDateRangePicker/MUIDateRangePicker";
import Button from "../../Button/Button";

const WhenDropdown = ({ onClose }) => {
  return (
    <div className={styles.whenDropdownContainer}>
      <MUIDateRangePicker isColumn={true} />
      <div className={styles.buttonsContainer}>
        <div onClick={onClose}>
          <Button variant={"inverse"}>Close </Button>
        </div>
        <Button isInverse={false}> Apply</Button>
      </div>
    </div>
  );
};

export default WhenDropdown;
