import React from "react";
import styles from "./PageWrapper.module.css";

const PageWrapper = ({ variant, children }) => {
  return (
    <div className={`${styles.wrapper} ${styles[variant]}`}>{children}</div>
  );
};

export default PageWrapper;
